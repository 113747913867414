import useSWR from "swr";
import axios from "axios";
import useEncodeCollectionURL from "./useEncodeCollectionURL";

const fetcher = ([url, _collectionId]) => {
  let reqBody = {
    // workspaceId: workspaceId,
    _collectionId: _collectionId,
  };

  return axios.post(url, reqBody).then((res) => res.data);
};

function useCollectionById(isAuth, collectionPath, options) {
  const { decodeCollectionURLId } = useEncodeCollectionURL();
  // console.log({ collectionPath });
  const _collectionId = decodeCollectionURLId(collectionPath);

  const { data, error, isLoading, mutate } = useSWR(
    isAuth && _collectionId ? [`/collections/getbyid`, _collectionId] : null,
    fetcher,
    options ? options : null
  );

  return {
    collectionData: data,
    isLoadingCollection: isLoading, //!error && !data,
    isCollectionError: error,
    mutateCollection: mutate,
  };
}

export default useCollectionById;
