/* eslint-disable no-undef */
/* eslint-disable no-inner-declarations */
/*global chrome*/
import React, { useState, useEffect } from "react";
// import Loadable from 'react-loadable';
import axios from "axios";
import update from "immutability-helper";

import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { alpha } from "@mui/material";

import CssBaseline from "@mui/material/CssBaseline";

import NewTab from "./App.js";

import useGlobal from "./GlobalState/Store/Store";

import setAuthToken from "./Utils/setAuthToken.js";
import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";
import DetectClientInfo from "./Utils/DetectClientInfo.js";

import useDarkMode from "./Utils/theme/useDarkMode.js";

import { SWRConfig } from "swr";

import useWindowFocused from "./Utils/useWindowFocused.js";

//FOR browser extension APIs
const browser = (function () {
  return window.msBrowser || window.browser || window.chrome;
})();

function Root(props) {
  const [globalState, globalActions] = useGlobal();
  const [cookies, setCookie, removeCookie] = useCookies(["partizion_token", "partizion_refreshToken"]);

  //gets user's preference
  // const lightMode = useColorScheme('light');
  const { isDarkMode, modePreference, enable, disable, system } = useDarkMode("light");

  //const { palette, theme } = createTheme();
  const darkeningShade = `#252942`;
  const lighteningShade = `#CED3D8`;
  const openGreen = `#27CA40`;

  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          isDarkMode,
          ...(!isDarkMode
            ? {
                // palette values for light mode
                mode: "light",
                primary: {
                  main: "#3D5AFE",
                  dark: "#384FD3", //#3E58EB'
                },
                secondary: {
                  main: "#f50057",
                },
                neutral: {
                  main: "#6E6E6E",
                },
                openGreen: {
                  main: `#27CA40`,
                },
                // tertiary: palette.augmentColor({
                //   color: {
                //     main: "#808080"
                //   }
                // }),
                // myCustomColor: palette.augmentColor({
                //   color: {
                //     main: "#00c676"
                //   }
                // }),
                snackbar: {
                  background: "#181818",
                  divider: "#383838",
                  primary: "#FFF",
                  secondary: "#888",
                },
                collection: {
                  default: "#F9F9F9",
                  hover: "#EFEFEF",
                  bgLight: "#F5F5F5",
                  bgDark: "#E9E9E9",
                  selected: "rgba(61, 90, 254, 0.10)",
                },
                success: {
                  main: "#00c853",
                },
                error: {
                  main: "#f44336",
                },
                info: {
                  main: "#00c853",
                },
                warning: {
                  main: "#00c853",
                },
                background: {
                  default: "#F8F8F8",
                  paper: "#FFF",
                  sidebar: "#EDEDED",
                },
                text: {
                  secondary: "#6E6E6E",
                  tertiary: "#C4C4C4",
                  light: "#D8D8D8",
                },
                button: {
                  plain: "#808080",
                },
                divider: "#E0E0E0",
                shadows: {
                  small: "0px 2px 8px 0px rgba(0,0,0,0.05)",
                  medium: "0px 2px 8px 0px rgba(0,0,0,0.10)",
                  large: `0px 2px 8px 0px rgba(0,0,0,0.10), 0 8px 24px rgb(1, 4, 9, 0.2)`,
                },
              }
            : {
                // palette values for dark mode
                mode: "dark",
                primary: {
                  main: "#3D5AFE",
                  dark: "#384FD3", //#3E58EB'
                },
                secondary: {
                  main: "#f50057",
                },
                neutral: {
                  main: "#B8B8B8",
                },
                // tertiary: palette.augmentColor({
                //   color: {
                //     main: "#BBBBBB"
                //   }
                // }),
                // myCustomColor: palette.augmentColor({
                //   color: {
                //     main: "#A26DF7"
                //   }
                // }),
                collection: {
                  default: "#1F1F1F",
                  hover: "#181818", //'#2A2A2A',
                  bgLight: "#181818", //'#2A2A2A',
                  bgDark: "#141414", //'#383838',
                  selected: "rgba(61, 90, 254, 0.10)",
                },
                success: {
                  main: "#00c853",
                },
                error: {
                  main: "#f44336",
                },
                info: {
                  main: "#00c853",
                },
                warning: {
                  main: "#00c853",
                },
                background: {
                  default: "#202020",
                  paper: "#080808",
                  sidebar: "#181818",
                },
                snackbar: {
                  background: "#484848",
                  divider: "#585858",
                  primary: "#FFF",
                  secondary: "#B8B8B8",
                },
                text: {
                  secondary: "#B8B8B8",
                  tertiary: "#686868",
                  light: "#3E3E3E",
                },
                button: {
                  plain: "#A8A8A8",
                },
                divider: "#424242",
                shadows: {
                  small: "0px 2px 8px 0px rgba(0,0,0,0.15)",
                  medium: "0px 3px 12px 0px rgba(0,0,0,0.25)",
                  // large: '0px 4px 2px rgba(0, 0, 0, 0.5), 0px 4px 12px rgba(0, 0, 0, 0.95)',
                  large: `0px 2px 8px 0px rgba(0,0,0,0.10), 0 8px 24px rgb(1, 4, 9)`,
                },
              }),
        },
        status: {
          danger: "orange",
        },
        typography: {
          h4: {
            fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
            fontWeight: 700,
            fontSize: 38,
          },
          fontFamily: '"Dm Sans", "Helvetica", "Arial", sans-serif',
          fontSize: 14,
          fontWeightLight: 400,
          fontWeightRegular: 500,
          fontWeightMedium: 700,
          fontWeightBold: 900,
          button: {
            textTransform: "none",
          },
          fontFamilyMonospaced: {
            fontFamily: '"Dm Sans"',
          },
        },
        components: {
          isDarkMode,
          ...(!isDarkMode
            ? {
                MuiButton: {
                  defaultProps: {
                    disableElevation: true,
                  },
                  styleOverrides: {
                    root: ({ ownerState }) => ({
                      border: `1.5px solid transparent`, //to make them the size size as outlined and contained buttons
                      ...(ownerState.variant === "contained" && {
                        "&:disabled": {
                          border: `1.5px solid ${alpha(darkeningShade, 0.1)} !important`,
                          boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)} !important`,
                          cursor: "not-allowed",
                          opacity: 0.75,
                          pointerEvents: "none !important",
                        },
                        backgroundColor: "#3D5AFE",
                        fontFamily: '"Dm Sans", "Helvetica", "Arial", sans-serif',
                        fontWeight: 600,
                        color: "#FFF",
                        minHeight: 0,
                        borderRadius: 4,
                        border: `1.5px solid ${alpha(darkeningShade, 0.15)}`,
                        boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.5)}, 0px 1px 0px ${theme.palette.primary.main}, inset 0px 2px 0px ${alpha(
                          "#FFF",
                          0.03
                        )}`,
                        "&:hover": {
                          backgroundColor: "#384FD3",
                          boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.5)}, 0px 1px 0px ${theme.palette.primary.main}`,
                        },
                        "&:active": {
                          boxShadow: `none`,
                        },

                        //icon styles
                        ".MuiButton-startIcon": {
                          minWidth: 2,
                          marginRight: 4,
                        },
                        //icon styles
                        ".MuiButton-endIcon": {
                          minWidth: 2,
                          marginLeft: 4,
                        },
                      }),
                      ...(ownerState.variant === "outlined" && {
                        "&:disabled": {
                          border: `1.5px solid ${alpha(darkeningShade, 0.1)} !important`,
                          boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)} !important`,
                          cursor: "not-allowed",
                          opacity: 0.75,
                          pointerEvents: "none !important",
                        },
                        border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                        boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)}`,
                        backgroundColor: theme.palette.background.paper,
                        // color: '#181818',
                        color: theme.palette.text.secondary,
                        minHeight: 0,
                        transitionProperty: "all",
                        "&:hover": {
                          color: theme.palette.text.primary,
                          border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                          boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)}`,
                          backgroundColor: "#F0F0F0",
                        },
                        "&:active": {
                          color: theme.palette.text.primary,
                          backgroundColor: "#F0F0F0",
                          boxShadow: `none`,
                          // color: theme.palette.text.primary,
                        },

                        //icon styles
                        ".MuiButton-startIcon": {
                          // color: '#6E6E6E',
                          minWidth: 2,
                          marginRight: 4,
                        },
                        //icon styles
                        ".MuiButton-endIcon": {
                          marginLeft: 4,
                        },
                      }),
                      ...(ownerState.variant === "soft" &&
                        ownerState.color === "primary" && {
                          "&:disabled": {
                            // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)} !important`,
                            // boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)} !important`,
                            cursor: "not-allowed",
                            opacity: 0.75,
                            pointerEvents: "none !important",
                          },
                          // border: `5px solid ${alpha(theme.palette.primary.main, 0)}`,
                          // boxShadow: `0px 1px 0px ${alpha(theme.palette.primary.main, 0.1)}`,
                          backgroundColor: `${alpha(theme.palette.primary.main, 0.05)}`,
                          // color: '#181818',
                          color: theme.palette.primary.main,
                          minHeight: 0,
                          // transitionProperty: "all",
                          "&:hover": {
                            color: theme.palette.primary.main,
                            // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                            // boxShadow: `0px 0px 0px 3px ${alpha(theme.palette.primary.main, 0.05)}`,
                            backgroundColor: `${alpha(theme.palette.primary.main, 0.1)}`,
                          },
                          "&:active": {
                            color: theme.palette.primary.main,
                            backgroundColor: "#F0F0F0",
                            boxShadow: `none`,
                            // color: theme.palette.text.primary,
                          },

                          //icon styles
                          ".MuiButton-startIcon": {
                            // color: '#6E6E6E',
                            minWidth: 2,
                            marginRight: 4,
                          },
                          //icon styles
                          ".MuiButton-endIcon": {
                            marginLeft: 4,
                          },
                        }),
                      ...(ownerState.variant === "soft" &&
                        ownerState.color === "green" && {
                          "&:disabled": {
                            // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)} !important`,
                            // boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)} !important`,
                            cursor: "not-allowed",
                            opacity: 0.75,
                            pointerEvents: "none !important",
                          },
                          // border: `5px solid ${alpha(theme.palette.primary.main, 0)}`,
                          // boxShadow: `0px 1px 0px ${alpha(theme.palette.primary.main, 0.1)}`,
                          backgroundColor: `${alpha(openGreen, 0.1)}`,
                          // color: '#181818',
                          color: openGreen,
                          minHeight: 0,
                          transitionProperty: "all",
                          "&:hover": {
                            // color: theme.palette.text.primary,
                            // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                            // boxShadow: `0px 0px 0px 3px ${alpha(theme.palette.primary.main, 0.05)}`,
                            backgroundColor: `${alpha(openGreen, 0.2)}`,
                          },
                          "&:active": {
                            color: theme.palette.text.primary,
                            backgroundColor: "#F0F0F0",
                            boxShadow: `none`,
                            // color: theme.palette.text.primary,
                          },

                          //icon styles
                          ".MuiButton-startIcon": {
                            // color: '#6E6E6E',
                            minWidth: 2,
                            marginRight: 4,
                          },
                          //icon styles
                          ".MuiButton-endIcon": {
                            marginLeft: 4,
                          },
                        }),
                      ...(ownerState.variant === "currentlyOpen" && {
                        "&:disabled": {
                          // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)} !important`,
                          // boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)} !important`,
                          cursor: "not-allowed",
                          opacity: 0.75,
                          pointerEvents: "none !important",
                        },
                        backdropFilter: "blur(0.5px)",
                        backgroundImage: `linear-gradient(${alpha(openGreen, 0.2)}, ${alpha(openGreen, 0.2)}), 
                        linear-gradient(${alpha("#FFF", 0.75)}, ${alpha("#FFF", 0.75)})`,
                        borderRadius: 0,
                        borderBottomRightRadius: 4, //`0px, 0px, 3px, 0px`,
                        paddingTop: 2,
                        paddingBottom: 2,
                        // border: `1px solid ${theme.palette.divider}`,
                        border: `1px solid ${alpha(openGreen, 0.95)}`,
                        fontSize: "8px !important",
                        boxShadow: theme.palette.shadows.medium,

                        color: theme.palette.text.primary, //openGreen,

                        minHeight: 0,
                        transitionProperty: "all",
                        "&:hover": {
                          // backgroundColor: `${alpha(openGreen, 0.1)}`,
                          backgroundImage: `linear-gradient(${alpha(openGreen, 0.1)}, ${alpha(openGreen, 0.1)}), 
                        linear-gradient(${alpha("#FFF", 0.95)}, ${alpha("#FFF", 0.95)})`,
                          color: openGreen,
                        },
                        "&:active": {
                          color: theme.palette.text.primary,
                          backgroundColor: "#F0F0F0",
                          boxShadow: `none`,
                        },

                        //icon styles
                        ".MuiButton-startIcon": {
                          // color: '#6E6E6E',
                          minWidth: 2,
                          marginRight: 4,
                        },
                        //icon styles
                        ".MuiButton-endIcon": {
                          marginLeft: 4,
                        },
                      }),
                      ...(ownerState.variant === "soft" &&
                        ownerState.color === "neutral" && {
                          "&:disabled": {
                            // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)} !important`,
                            // boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)} !important`,
                            cursor: "not-allowed",
                            opacity: 0.75,
                            pointerEvents: "none !important",
                          },
                          // border: `5px solid ${alpha(theme.palette.primary.main, 0)}`,
                          // boxShadow: `0px 1px 0px ${alpha(theme.palette.primary.main, 0.1)}`,
                          backgroundColor: `${alpha(theme.palette.text.secondary, 0.05)}`,
                          // color: '#181818',
                          color: theme.palette.text.secondary,
                          minHeight: 0,
                          transitionProperty: "all",
                          "&:hover": {
                            color: theme.palette.text.primary,
                            // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                            // boxShadow: `0px 0px 0px 3px ${alpha(theme.palette.primary.main, 0.05)}`,
                            backgroundColor: `${alpha(theme.palette.text.secondary, 0.1)}`,
                          },
                          "&:active": {
                            color: theme.palette.text.primary,
                            backgroundColor: "#F0F0F0",
                            boxShadow: `none`,
                            // color: theme.palette.text.primary,
                          },

                          //icon styles
                          ".MuiButton-startIcon": {
                            // color: '#6E6E6E',
                            minWidth: 2,
                            marginRight: 4,
                          },
                          //icon styles
                          ".MuiButton-endIcon": {
                            marginLeft: 4,
                          },
                        }),
                      ...(ownerState.variant === "autoupdateenabled" && {
                        "&:disabled": {
                          border: `1.5px solid ${alpha(darkeningShade, 0.1)} !important`,
                          boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)} !important`,
                          cursor: "not-allowed",
                          opacity: `0.75 !important`,
                          pointerEvents: "none !important",
                          color: `${theme.palette.text.tertiary} !important`,
                          backgroundColor: `${theme.palette.background.paper} !important`,
                        },
                        zIndex: `99 !important`,
                        border: `1.5px solid ${alpha(theme.palette.primary.main, 0.25)} !important`,
                        // border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                        color: `${theme.palette.primary.main} !important`,
                        backgroundColor: `${alpha(theme.palette.primary.main, 0.15)} !important`,
                        "&:hover": {
                          border: `1.5px solid ${alpha(theme.palette.primary.main, 0.25)} !important`,
                          // border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                          backgroundColor: `${alpha(theme.palette.primary.main, 0.25)} !important`,
                        },
                        "&:active": {
                          boxShadow: `none`,
                        },

                        //icon styles
                        ".MuiButton-startIcon": {
                          minWidth: 2,
                          marginRight: 4,
                        },
                        //icon styles
                        ".MuiButton-endIcon": {
                          minWidth: 2,
                          marginLeft: 4,
                        },
                      }),
                      ...(ownerState.size === "small" && {
                        fontSize: 12,
                        paddingBottom: 3,
                        paddingTop: 3,
                        paddingLeft: 8,
                        paddingRight: 8,
                      }),
                      ...(ownerState.size === "medium" && {
                        fontSize: 14,
                        paddingBottom: 3,
                        paddingTop: 3,
                        paddingLeft: 14,
                        paddingRight: 14,
                      }),

                      whiteSpace: "nowrap",
                      minWidth: "max-content",
                    }),
                  },
                },
                MuiIconButton: {
                  styleOverrides: {
                    root: ({ ownerState }) => ({
                      borderRadius: 4,
                      padding: 6,
                      ...(ownerState.variant === "outlined" && {
                        border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                        boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)}`,
                        backgroundColor: theme.palette.background.paper,
                        color: "#6E6E6E",
                        minHeight: 0,
                        transitionProperty: "all",
                        borderRadius: 4,

                        "&:hover": {
                          border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                          boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)}`,
                          backgroundColor: "#F0F0F0", //'#D2D2D2',
                        },
                        "&:active": {
                          backgroundColor: "#F0F0F0", //'#D2D2D2',
                          boxShadow: `none`,
                          // color: theme.palette.text.primary,
                        },
                        ".MuiSvgIcon-root": {
                          fontSize: 20,
                        },
                      }),
                      ...(ownerState.size === "small" && {
                        padding: 4.5,
                        ".MuiSvgIcon-root": {
                          fontSize: 18,
                        },
                      }),
                      ...(ownerState.size === "medium" && {
                        fontSize: 14,
                        padding: 5.25,
                      }),
                      whiteSpace: "nowrap",
                      minWidth: "max-content",
                    }),
                  },
                },
                MuiButtonGroup: {
                  defaultProps: {
                    disableElevation: true,
                    disableRipple: true,
                  },
                  styleOverrides: {
                    root: ({ ownerState }) => ({
                      flexShrink: 0,
                      boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)}`,
                      transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                      "&:active": {
                        boxShadow: "none",
                      },
                      ...(ownerState.variant === "outlined" &&
                        ownerState.color !== "primary" && {
                          ".MuiButton-root": {
                            borderRadius: 4,
                            paddingBottom: 3,
                            paddingTop: 3,
                            paddingLeft: 8,
                            paddingRight: 8,
                            minHeight: 0,
                            minWidth: 0,
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: "none",
                            border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                            // boxShadow: theme.palette.shadows.medium,
                            "&:hover": {
                              // color: theme.palette.text.primary,
                              backgroundColor: "#F0F0F0",
                              border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              zIndex: 99,
                            },
                            "&:active": {
                              backgroundColor: "#F0F0F0",
                              border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              zIndex: 99,
                              // color: theme.palette.text.primary,
                            },
                            ":not(:last-of-type)": {
                              border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              borderRight: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              "&:hover": {
                                border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              },
                              // zIndex: 99,
                              // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTop: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                            ":last-of-type": {
                              // borderRight: `0px solid ${theme.palette.divider}`,
                              // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                          },
                          ".MuiIconButton-root": {
                            borderRadius: 4,
                            minHeight: 0,
                            minWidth: 0,
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: "none",
                            border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                            // boxShadow: theme.palette.shadows.medium,
                            "&:hover": {
                              // color: theme.palette.text.primary,
                              backgroundColor: "#F0F0F0",
                              border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              zIndex: 99,
                            },
                            "&:active": {
                              backgroundColor: "#F0F0F0",
                              border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              zIndex: 99,
                              // color: theme.palette.text.primary,
                            },
                            ":not(:last-of-type)": {
                              border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              borderRight: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              "&:hover": {
                                border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              },
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              // zIndex: 99,
                              // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTop: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                            ":last-of-type": {
                              marginLeft: -1,
                              border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                            ...(ownerState.size === "small" && {
                              padding: 4.5,
                              ".MuiSvgIcon-root": {
                                fontSize: 18,
                              },
                            }),
                            ...(ownerState.size === "medium" && {
                              fontSize: 14,
                              padding: 5.25,
                              ".MuiSvgIcon-root": {
                                fontSize: 20,
                              },
                            }),
                          },

                          //   '.MuiSvgIcon-root': {

                          //     '&:disabled':{
                          //       color: theme.palette.text.tertiary,
                          //     },
                          //     color: theme.palette.text.secondary,
                          // },
                        }),
                      ...(ownerState.variant === "outlined" &&
                        ownerState.color === "primary" && {
                          ".MuiButton-root": {
                            borderRadius: 4,
                            paddingBottom: 4,
                            paddingTop: 4,
                            paddingLeft: 8,
                            paddingRight: 8,
                            minHeight: 0,
                            minWidth: 0,
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: "none",
                            color: theme.palette.primary.main,
                            border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                            // boxShadow: theme.palette.shadows.medium,
                            "&:hover": {
                              // color: theme.palette.text.primary,
                              backgroundColor: alpha(theme.palette.primary.main, 0.1),
                              border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              zIndex: 99,
                            },
                            "&:active": {
                              backgroundColor: alpha(theme.palette.primary.main, 0.1),
                              border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              zIndex: 99,
                              // color: theme.palette.text.primary,
                            },
                            ":not(:last-of-type)": {
                              border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              borderRight: `1.5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                              "&:hover": {
                                border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              },
                              // zIndex: 99,
                              // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTop: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                            ":last-of-type": {
                              // borderRight: `0px solid ${theme.palette.divider}`,
                              // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              border: `1.5px solid ${alpha(darkeningShade, 0.1)}`,
                              "&:hover": {
                                border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              },
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                          },
                          ".MuiIconButton-root": {
                            borderRadius: 4,
                            minHeight: 0,
                            minWidth: 0,
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: "none",
                            border: `1.5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                            // boxShadow: theme.palette.shadows.medium,
                            "&:hover": {
                              // color: theme.palette.text.primary,
                              backgroundColor: "#F0F0F0",
                              border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              zIndex: 99,
                            },
                            "&:active": {
                              backgroundColor: "#F0F0F0",
                              border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              zIndex: 99,
                              // color: theme.palette.text.primary,
                            },
                            ":not(:last-of-type)": {
                              border: `1.5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                              borderRight: `1.5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                              "&:hover": {
                                border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              },
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              // zIndex: 99,
                              // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTop: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                            ":last-of-type": {
                              marginLeft: -1,
                              border: `1.5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                          },
                        }),
                    }),
                  },
                },
                MuiButtonBase: {
                  defaultProps: {
                    disableRipple: true, // No more ripple, on the whole application 💣!
                    disableElevation: true,
                  },
                  styleOverrides: {
                    root: {
                      whiteSpace: "nowrap",
                      minWidth: "max-content",
                    },
                  },
                },
                "MuiGrid-container": {
                  styleOverrides: {
                    // Name of the rule
                    border: "3px solid",
                  },
                },
                MuiListItemIcon: {
                  styleOverrides: {
                    root: {
                      minWidth: 32,
                    },
                  },
                },
                MuiTooltip: {
                  styleOverrides: {
                    tooltip: {
                      backgroundColor: "#242424",
                      boxShadow:
                        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                      color: "rgba(255, 255, 255, 0.95)",
                      fontSize: 12,
                    },
                  },
                },
                MuiBreadcrumbs: {
                  styleOverrides: {
                    root: {
                      minWidth: 0,
                    },
                    ol: {
                      display: "flex",
                      flexWrap: "noWrap",
                    },
                    li: {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "noWrap",
                    },
                  },
                },
                MuiSwitch: {
                  styleOverrides: {
                    root: ({ ownerState }) => ({
                      ...(ownerState.size === "small" && {
                        width: 28,
                        height: 16,
                        padding: 0,
                        display: "flex",
                        "&.disabled": {
                          opacity: 0.1,
                        },
                        "&:active": {
                          "& .MuiSwitch-thumb": {
                            width: 15,
                          },
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            transform: "translateX(9px)",
                          },
                        },
                        "& .MuiSwitch-switchBase": {
                          padding: 2,
                          color: "#FFF",
                          "&.Mui-checked": {
                            transform: "translateX(12px)",
                            color: "#fff",
                            // backgroundColor: 'inherit',
                            "& + .MuiSwitch-track": {
                              backgroundColor: "#3d5AFE",
                              opacity: 1,
                            },
                            "&.Mui-disabled": {
                              opacity: 0.5,
                              color: "#FFF",
                            },
                          },
                          "&.Mui-disabled": {
                            color: theme.palette.divider,
                            "& + .MuiSwitch-track": {
                              // opacity: 1,
                              backgroundColor: theme.palette.divider,

                              // color: '#3D5AFE',
                              opacity: 0.5,
                            },
                          },
                          "& + .MuiSwitch-track": {
                            backgroundColor: theme.palette.action.disabled,
                          },
                        },
                        "& .MuiSwitch-thumb": {
                          boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
                          width: 12,
                          height: 12,
                          borderRadius: 6,
                          transition: theme.transitions.create(["width"], {
                            duration: 200,
                          }),
                        },
                        "& .MuiSwitch-track": {
                          borderRadius: 16 / 2,
                          opacity: 1,
                          backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
                          boxSizing: "border-box",
                        },
                      }),
                      ...(ownerState.size === "medium" && {
                        width: 42,
                        height: 24,
                        borderRadius: 28,
                        padding: 0,
                        display: "flex",
                        "&:active": {
                          "& .MuiSwitch-thumb": {
                            width: 18,
                          },
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            transform: "translateX(18px)",
                          },
                        },
                        "& .MuiSwitch-switchBase": {
                          padding: 4,
                          color: "#FFF",
                          "&.Mui-checked": {
                            transform: "translateX(18px)",
                            color: "#fff",
                            // backgroundColor: 'inherit',
                            "& + .MuiSwitch-track": {
                              // opacity: 1,
                              backgroundColor: "#3d5AFE",

                              // color: '#3D5AFE',
                              opacity: 1,
                            },
                            "&.Mui-disabled": {
                              opacity: 0.5,
                              color: "#FFF",
                            },
                          },
                          "&.Mui-disabled": {
                            color: theme.palette.divider,
                            "& + .MuiSwitch-track": {
                              // opacity: 1,
                              backgroundColor: theme.palette.divider,

                              // color: '#3D5AFE',
                              opacity: 0.5,
                            },
                          },
                          "& + .MuiSwitch-track": {
                            backgroundColor: theme.palette.action.disabled,
                          },
                        },
                        "& .MuiSwitch-thumb": {
                          boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
                          width: 16,
                          height: 16,
                          borderRadius: 32,
                          transition: theme.transitions.create(["width"], {
                            duration: 200,
                          }),
                        },
                        "& .MuiSwitch-track": {
                          borderRadius: 16 / 2,
                          opacity: 1,
                          // backgroundColor:
                          //   theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
                          boxSizing: "border-box",
                        },
                      }),
                    }),
                  },
                },
              }
            : {
                MuiButton: {
                  defaultProps: {
                    disableElevation: true,
                  },
                  styleOverrides: {
                    root: ({ ownerState }) => ({
                      border: `1.5px solid transparent`, //to make them the size size as outlined and contained buttons
                      ...(ownerState.variant === "contained" && {
                        "&:disabled": {
                          border: `1.5px solid ${alpha(lighteningShade, 0.25)} !important`,
                          boxShadow: `0px 1px 0px ${alpha(lighteningShade, 0.2)} !important`,
                          cursor: "not-allowed",
                          opacity: 0.75,
                          pointerEvents: "none !important",
                        },
                        backgroundColor: "#3D5AFE",
                        fontFamily: '"Dm Sans", "Helvetica", "Arial", sans-serif',
                        fontWeight: 600,
                        color: "#FFF",
                        minHeight: 0,
                        borderRadius: 4,
                        border: `1.5px solid ${alpha(lighteningShade, 0.15)}`,
                        boxShadow: `0px 1px 0px ${alpha(lighteningShade, 0.25)}, 0px 1px 0px ${theme.palette.primary.main}, inset 0px 2px 0px ${alpha(
                          "#FFF",
                          0.03
                        )}`,
                        "&:hover": {
                          backgroundColor: "#384FD3",
                          boxShadow: `0px 1px 0px ${alpha(lighteningShade, 0.25)}, 0px 1px 0px ${theme.palette.primary.main}`,
                        },
                        "&:active": {
                          boxShadow: `none`,
                        },

                        //icon styles
                        ".MuiButton-startIcon": {
                          minWidth: 2,
                          marginRight: 4,
                        },
                        //icon styles
                        ".MuiButton-endIcon": {
                          minWidth: 2,
                          marginLeft: 4,
                        },
                      }),
                      ...(ownerState.variant === "outlined" && {
                        "&:disabled": {
                          border: `1.5px solid ${alpha(lighteningShade, 0.25)} !important`,
                          boxShadow: `0px 1px 0px ${alpha(lighteningShade, 0.2)} !important`,
                          cursor: "not-allowed",
                          opacity: 0.75,
                          pointerEvents: "none !important",
                        },
                        border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                        boxShadow: `0px 1px 0px ${alpha(lighteningShade, 0.2)}`,
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.secondary,
                        minHeight: 0,
                        transitionProperty: "all",
                        "&:hover": {
                          color: theme.palette.text.primary,
                          border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                          boxShadow: `0px 1px 0px ${alpha(lighteningShade, 0.2)}`,
                          backgroundColor: "#393939",
                        },
                        "&:active": {
                          color: theme.palette.text.primary,
                          backgroundColor: "#393939",
                          boxShadow: `none`,
                          // color: theme.palette.text.primary,
                        },

                        //icon styles
                        ".MuiButton-startIcon": {
                          // color: "#B8B8B8",
                          minWidth: 2,
                          marginRight: 4,
                        },
                        //icon styles
                        ".MuiButton-endIcon": {
                          marginLeft: 4,
                        },
                      }),
                      ...(ownerState.variant === "soft" &&
                        ownerState.color === "primary" && {
                          "&:disabled": {
                            // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)} !important`,
                            // boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)} !important`,
                            cursor: "not-allowed",
                            opacity: 0.75,
                            pointerEvents: "none !important",
                          },
                          // border: `5px solid ${alpha(theme.palette.primary.main, 0)}`,
                          // boxShadow: `0px 1px 0px ${alpha(theme.palette.primary.main, 0.1)}`,
                          backgroundColor: `${alpha(theme.palette.primary.main, 0.1)}`,
                          // color: '#181818',
                          color: theme.palette.primary.main,
                          minHeight: 0,
                          // transitionProperty: "all",
                          "&:hover": {
                            color: theme.palette.primary.main,
                            // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                            // boxShadow: `0px 0px 0px 3px ${alpha(theme.palette.primary.main, 0.05)}`,
                            backgroundColor: `${alpha(theme.palette.primary.main, 0.2)}`,
                          },
                          "&:active": {
                            color: theme.palette.primary.main,
                            backgroundColor: "#F0F0F0",
                            boxShadow: `none`,
                            // color: theme.palette.text.primary,
                          },

                          //icon styles
                          ".MuiButton-startIcon": {
                            // color: '#6E6E6E',
                            minWidth: 2,
                            marginRight: 4,
                          },
                          //icon styles
                          ".MuiButton-endIcon": {
                            marginLeft: 4,
                          },
                        }),
                      ...(ownerState.variant === "soft" &&
                        ownerState.color === "green" && {
                          "&:disabled": {
                            // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)} !important`,
                            // boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)} !important`,
                            cursor: "not-allowed",
                            opacity: 0.75,
                            pointerEvents: "none !important",
                          },
                          // border: `5px solid ${alpha(theme.palette.primary.main, 0)}`,
                          // boxShadow: `0px 1px 0px ${alpha(theme.palette.primary.main, 0.1)}`,
                          backgroundColor: `${alpha(openGreen, 0.1)}`,
                          // color: '#181818',
                          color: openGreen,
                          minHeight: 0,
                          transitionProperty: "all",
                          "&:hover": {
                            // color: theme.palette.text.primary,
                            // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                            // boxShadow: `0px 0px 0px 3px ${alpha(theme.palette.primary.main, 0.05)}`,
                            backgroundColor: `${alpha(openGreen, 0.2)}`,
                          },
                          "&:active": {
                            color: theme.palette.text.primary,
                            backgroundColor: "#F0F0F0",
                            boxShadow: `none`,
                            // color: theme.palette.text.primary,
                          },

                          //icon styles
                          ".MuiButton-startIcon": {
                            // color: '#6E6E6E',
                            minWidth: 2,
                            marginRight: 4,
                          },
                          //icon styles
                          ".MuiButton-endIcon": {
                            marginLeft: 4,
                          },
                        }),
                      ...(ownerState.variant === "soft" &&
                        ownerState.color === "neutral" && {
                          "&:disabled": {
                            // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)} !important`,
                            // boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)} !important`,
                            cursor: "not-allowed",
                            opacity: 0.75,
                            pointerEvents: "none !important",
                          },
                          // border: `5px solid ${alpha(theme.palette.primary.main, 0)}`,
                          // boxShadow: `0px 1px 0px ${alpha(theme.palette.primary.main, 0.1)}`,
                          backgroundColor: `${alpha(theme.palette.text.secondary, 0.05)}`,
                          // color: '#181818',
                          color: theme.palette.text.secondary,
                          minHeight: 0,
                          transitionProperty: "all",
                          "&:hover": {
                            color: theme.palette.text.primary,
                            // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                            // boxShadow: `0px 0px 0px 3px ${alpha(theme.palette.primary.main, 0.05)}`,
                            backgroundColor: `${alpha(theme.palette.text.secondary, 0.1)}`,
                          },
                          "&:active": {
                            color: theme.palette.text.primary,
                            backgroundColor: "#F0F0F0",
                            boxShadow: `none`,
                            // color: theme.palette.text.primary,
                          },

                          //icon styles
                          ".MuiButton-startIcon": {
                            // color: '#6E6E6E',
                            minWidth: 2,
                            marginRight: 4,
                          },
                          //icon styles
                          ".MuiButton-endIcon": {
                            marginLeft: 4,
                          },
                        }),
                      ...(ownerState.variant === "currentlyOpen" && {
                        "&:disabled": {
                          // border: `5px solid ${alpha(theme.palette.primary.main, 0.1)} !important`,
                          // boxShadow: `0px 1px 0px ${alpha(darkeningShade, 0.2)} !important`,
                          cursor: "not-allowed",
                          opacity: 0.75,
                          pointerEvents: "none !important",
                        },
                        backdropFilter: "blur(0.5px)",
                        backgroundImage: `linear-gradient(${alpha(openGreen, 0.35)}, ${alpha(openGreen, 0.35)}), 
                          linear-gradient(${alpha("#000", 0.75)}, ${alpha("#000", 0.75)})`,
                        borderRadius: 0,
                        borderBottomRightRadius: 4, //`0px, 0px, 3px, 0px`,
                        paddingTop: 2,
                        paddingBottom: 2,
                        // border: `1px solid ${theme.palette.divider}`,
                        border: `1px solid ${alpha(openGreen, 0.95)}`,
                        fontSize: "8px !important",
                        boxShadow: theme.palette.shadows.medium,

                        color: theme.palette.text.primary, //openGreen,

                        minHeight: 0,
                        transitionProperty: "all",
                        "&:hover": {
                          // backgroundColor: `${alpha(openGreen, 0.1)}`,
                          backgroundImage: `linear-gradient(${alpha(openGreen, 0.25)}, ${alpha(openGreen, 0.25)}), 
                            linear-gradient(${alpha("#000", 0.95)}, ${alpha("#000", 0.95)})`,
                          backdropFilter: "blur(1px)",
                          color: openGreen,
                        },
                        "&:active": {
                          color: theme.palette.text.primary,
                          backgroundColor: "#F0F0F0",
                          boxShadow: `none`,
                        },

                        //icon styles
                        ".MuiButton-startIcon": {
                          // color: '#6E6E6E',
                          minWidth: 2,
                          marginRight: 4,
                        },
                        //icon styles
                        ".MuiButton-endIcon": {
                          marginLeft: 4,
                        },
                      }),
                      ...(ownerState.variant === "autoupdateenabled" && {
                        "&:disabled": {
                          border: `1.5px solid ${alpha(lighteningShade, 0.25)} !important`,
                          boxShadow: `0px 1px 0px ${alpha(lighteningShade, 0.2)} !important`,
                          cursor: "not-allowed",
                          opacity: `0.75 !important`,
                          pointerEvents: "none !important",
                          color: `${theme.palette.text.tertiary} !important`,
                          backgroundColor: `${theme.palette.background.paper} !important`,
                        },
                        zIndex: `99 !important`,
                        color: `${theme.palette.primary.main} !important`,
                        backgroundColor: `${alpha(theme.palette.primary.main, 0.15)} !important`,
                        border: `1.5px solid ${alpha(theme.palette.primary.main, 0.4)} !important`,
                        "&:hover": {
                          backgroundColor: `${alpha(theme.palette.primary.main, 0.25)} !important`,
                          border: `1.5px solid ${alpha(theme.palette.primary.main, 0.6)} !important`,
                        },
                        "&:active": {
                          boxShadow: `none`,
                        },

                        //icon styles
                        ".MuiButton-startIcon": {
                          minWidth: 2,
                          marginRight: 4,
                        },
                        //icon styles
                        ".MuiButton-endIcon": {
                          minWidth: 2,
                          marginLeft: 4,
                        },
                      }),
                      ...(ownerState.size === "small" && {
                        fontSize: 12,
                        paddingBottom: 3,
                        paddingTop: 3,
                        paddingLeft: 8,
                        paddingRight: 8,
                      }),
                      ...(ownerState.size === "medium" && {
                        fontSize: 14,
                        paddingBottom: 3,
                        paddingTop: 3,
                        paddingLeft: 14,
                        paddingRight: 14,
                      }),

                      whiteSpace: "nowrap",
                      minWidth: "max-content",
                    }),
                  },
                },
                MuiIconButton: {
                  styleOverrides: {
                    root: ({ ownerState }) => ({
                      borderRadius: 4,
                      padding: 6,
                      ...(ownerState.variant === "outlined" && {
                        border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                        boxShadow: `0px 1px 0px ${alpha(lighteningShade, 0.2)}`,
                        backgroundColor: theme.palette.background.paper,
                        color: "#B8B8B8",
                        minHeight: 0,
                        transitionProperty: "all",
                        borderRadius: 4,

                        "&:hover": {
                          border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                          boxShadow: `0px 1px 0px ${alpha(lighteningShade, 0.2)}`,
                          backgroundColor: "#393939",
                        },
                        "&:active": {
                          backgroundColor: "#393939",
                          boxShadow: `none`,
                          // color: theme.palette.text.primary,
                        },
                        ".MuiSvgIcon-root": {
                          fontSize: 20,
                        },
                      }),
                      ...(ownerState.size === "small" && {
                        padding: 4.5,
                        ".MuiSvgIcon-root": {
                          fontSize: 18,
                        },
                      }),
                      ...(ownerState.size === "medium" && {
                        fontSize: 14,
                        padding: 5.25,
                      }),
                      whiteSpace: "nowrap",
                      minWidth: "max-content",
                    }),
                  },
                },
                MuiButtonGroup: {
                  defaultProps: {
                    disableElevation: true,
                    disableRipple: true,
                  },
                  styleOverrides: {
                    root: ({ ownerState }) => ({
                      flexShrink: 0,
                      boxShadow: `0px 1px 0px ${alpha(lighteningShade, 0.2)}`,
                      transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                      "&:active": {
                        boxShadow: "none",
                      },
                      ...(ownerState.variant === "outlined" &&
                        ownerState.color !== "primary" && {
                          ".MuiButton-root": {
                            borderRadius: 4,
                            paddingBottom: 3,
                            paddingTop: 3,
                            paddingLeft: 8,
                            paddingRight: 8,
                            minHeight: 0,
                            minWidth: 0,
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: "none",
                            border: `1.5px solid ${alpha(lighteningShade, 0.1)}`,
                            // boxShadow: theme.palette.shadows.medium,
                            "&:hover": {
                              // color: theme.palette.text.primary,
                              backgroundColor: "#393939",
                              border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              zIndex: 99,
                            },
                            "&:active": {
                              backgroundColor: "#393939",
                              border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              zIndex: 99,
                              // color: theme.palette.text.primary,
                            },
                            ":not(:last-of-type)": {
                              border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              borderRight: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              "&:hover": {
                                border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              },
                              // zIndex: 99,
                              // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTop: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                            ":last-of-type": {
                              // borderRight: `0px solid ${theme.palette.divider}`,
                              // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                          },
                          ".MuiIconButton-root": {
                            borderRadius: 4,
                            minHeight: 0,
                            minWidth: 0,
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: "none",
                            border: `1.5px solid ${alpha(lighteningShade, 0.1)}`,
                            // boxShadow: theme.palette.shadows.medium,
                            "&:hover": {
                              // color: theme.palette.text.primary,
                              backgroundColor: "#393939",
                              border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              zIndex: 99,
                            },
                            "&:active": {
                              backgroundColor: "#393939",
                              border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              zIndex: 99,
                              // color: theme.palette.text.primary,
                            },
                            ":not(:last-of-type)": {
                              border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              borderRight: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              "&:hover": {
                                border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              },
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              // zIndex: 99,
                              // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTop: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                            ":last-of-type": {
                              marginLeft: -1,
                              border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                            ...(ownerState.size === "small" && {
                              padding: 4.5,
                              ".MuiSvgIcon-root": {
                                fontSize: 18,
                              },
                            }),
                            ...(ownerState.size === "medium" && {
                              fontSize: 14,
                              padding: 5.25,
                              ".MuiSvgIcon-root": {
                                fontSize: 20,
                              },
                            }),
                          },

                          //   '.MuiSvgIcon-root': {

                          //     '&:disabled':{
                          //       color: theme.palette.text.tertiary,
                          //     },
                          //     color: theme.palette.text.secondary,
                          // },
                        }),
                      ...(ownerState.variant === "outlined" &&
                        ownerState.color === "primary" && {
                          ".MuiButton-root": {
                            borderRadius: 4,
                            paddingBottom: 4,
                            paddingTop: 4,
                            paddingLeft: 8,
                            paddingRight: 8,
                            minHeight: 0,
                            minWidth: 0,
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: "none",
                            color: theme.palette.primary.main,
                            border: `1.5px solid ${alpha(lighteningShade, 0.1)}`,
                            // boxShadow: theme.palette.shadows.medium,
                            "&:hover": {
                              // color: theme.palette.text.primary,
                              backgroundColor: alpha(theme.palette.primary.main, 0.1),
                              border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              zIndex: 99,
                            },
                            "&:active": {
                              backgroundColor: alpha(theme.palette.primary.main, 0.1),
                              border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              zIndex: 99,
                              // color: theme.palette.text.primary,
                            },
                            ":not(:last-of-type)": {
                              border: `1.5px solid ${alpha(lighteningShade, 0.1)}`,
                              borderRight: `1.5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                              "&:hover": {
                                border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              },
                              // zIndex: 99,
                              // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTop: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                            ":last-of-type": {
                              // borderRight: `0px solid ${theme.palette.divider}`,
                              // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              border: `1.5px solid ${alpha(lighteningShade, 0.1)}`,
                              "&:hover": {
                                border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              },
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                          },
                          ".MuiIconButton-root": {
                            borderRadius: 4,
                            minHeight: 0,
                            minWidth: 0,
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: "none",
                            border: `1.5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                            // boxShadow: theme.palette.shadows.medium,
                            "&:hover": {
                              // color: theme.palette.text.primary,
                              backgroundColor: "#F0F0F0",
                              border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              zIndex: 99,
                            },
                            "&:active": {
                              backgroundColor: "#F0F0F0",
                              border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              zIndex: 99,
                              // color: theme.palette.text.primary,
                            },
                            ":not(:last-of-type)": {
                              border: `1.5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                              borderRight: `1.5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                              "&:hover": {
                                border: `1.5px solid ${alpha(theme.palette.primary.main, 1)}`,
                              },
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              // zIndex: 99,
                              // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTop: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                            ":last-of-type": {
                              marginLeft: -1,
                              border: `1.5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              // borderTopRightRadius: 0,
                              // borderBottomRightRadius: 0,
                            },
                          },
                        }),
                    }),
                  },
                  // defaultProps: {
                  //   disableElevation: true,
                  //   disableRipple: true,
                  // },
                  // styleOverrides: {
                  //   root: ({ ownerState }) => ({
                  //     flexShrink: 0,
                  //     boxShadow: `0px 1px 0px ${alpha(lighteningShade, 0.2)}`,
                  //     transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                  //     "&:active": {
                  //       boxShadow: "none",
                  //     },
                  //     ...(ownerState.variant === "outlined" && {
                  //       ".MuiButton-outlined": {
                  //         borderRadius: 4,
                  //         paddingBottom: 3,
                  //         paddingTop: 3,
                  //         paddingLeft: 8,
                  //         paddingRight: 8,
                  //         minHeight: 0,
                  //         minWidth: 0,
                  //         backgroundColor: theme.palette.background.paper,
                  //         boxShadow: "none",
                  //         border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //         // boxShadow: theme.palette.shadows.medium,
                  //         "&:hover": {
                  //           // color: theme.palette.text.primary,
                  //           backgroundColor: "#393939",
                  //           border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           zIndex: 99,
                  //         },
                  //         "&:active": {
                  //           backgroundColor: "#393939",
                  //           border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           zIndex: 99,
                  //           // color: theme.palette.text.primary,
                  //         },
                  //         ":not(:last-of-type)": {
                  //           border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           borderRight: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           "&:hover": {
                  //             border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           },
                  //           // zIndex: 99,
                  //           // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           // borderTop: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           // borderTopRightRadius: 0,
                  //           // borderBottomRightRadius: 0,
                  //         },
                  //         ":last-of-type": {
                  //           // borderRight: `0px solid ${theme.palette.divider}`,
                  //           // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           // borderTopRightRadius: 0,
                  //           // borderBottomRightRadius: 0,
                  //         },
                  //       },
                  //       ".MuiIconButton-root": {
                  //         borderRadius: 4,
                  //         minHeight: 0,
                  //         minWidth: 0,
                  //         backgroundColor: theme.palette.background.paper,
                  //         boxShadow: "none",
                  //         border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //         // boxShadow: theme.palette.shadows.medium,
                  //         "&:hover": {
                  //           // color: theme.palette.text.primary,
                  // backgroundColor: "#393939",
                  // border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           zIndex: 99,
                  //         },
                  //         "&:active": {
                  //           backgroundColor: "#393939",
                  //           border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           zIndex: 99,
                  //           // color: theme.palette.text.primary,
                  //         },
                  //         ":not(:last-of-type)": {
                  //           border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           borderRight: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           "&:hover": {
                  //             border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           },
                  //           borderTopRightRadius: 0,
                  //           borderBottomRightRadius: 0,
                  //           // zIndex: 99,
                  //           // borderLeft: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           // borderBottom: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           // borderTop: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           // borderTopRightRadius: 0,
                  //           // borderBottomRightRadius: 0,
                  //         },
                  //         ":last-of-type": {
                  //           marginLeft: -1,
                  //           border: `1.5px solid ${alpha(lighteningShade, 0.25)}`,
                  //           borderTopLeftRadius: 0,
                  //           borderBottomLeftRadius: 0,
                  //           // borderTopRightRadius: 0,
                  //           // borderBottomRightRadius: 0,
                  //         },
                  //         ...(ownerState.size === "small" && {
                  //           padding: 4.5,
                  //           ".MuiSvgIcon-root": {
                  //             fontSize: 18,
                  //           },
                  //         }),
                  //         ...(ownerState.size === "medium" && {
                  //           fontSize: 14,
                  //           padding: 5.25,
                  //           ".MuiSvgIcon-root": {
                  //             fontSize: 20,
                  //           },
                  //         }),
                  //       },
                  //       ".MuiSvgIcon-root": {
                  //         color: theme.palette.text.secondary,
                  //       },
                  //     }),
                  //   }),
                  // },
                },
                MuiButtonBase: {
                  defaultProps: {
                    disableRipple: true, // No more ripple, on the whole application 💣!
                    disableElevation: true,
                  },
                  styleOverrides: {
                    root: {
                      whiteSpace: "nowrap",
                      minWidth: "max-content",
                    },
                  },
                },
                "MuiGrid-container": {
                  styleOverrides: {
                    // Name of the rule
                    border: "3px solid",
                  },
                },
                MuiListItemIcon: {
                  styleOverrides: {
                    root: {
                      minWidth: 32,
                    },
                  },
                },
                MuiTooltip: {
                  styleOverrides: {
                    tooltip: {
                      backgroundColor: "#484848",
                      boxShadow:
                        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                      color: "rgba(255, 255, 255, 0.95)",
                      fontSize: 12,
                    },
                  },
                },
                MuiSwitch: {
                  styleOverrides: {
                    root: ({ ownerState }) => ({
                      ...(ownerState.size === "small" && {
                        width: 28,
                        height: 16,
                        padding: 0,
                        display: "flex",
                        "&.disabled": {
                          opacity: 0.1,
                        },
                        "&:active": {
                          "& .MuiSwitch-thumb": {
                            width: 15,
                          },
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            transform: "translateX(9px)",
                          },
                        },
                        "& .MuiSwitch-switchBase": {
                          padding: 2,
                          color: "#FFF",
                          "&.Mui-checked": {
                            transform: "translateX(12px)",
                            color: "#fff",
                            // backgroundColor: 'inherit',
                            "& + .MuiSwitch-track": {
                              backgroundColor: "#3d5AFE",
                              opacity: 1,
                            },
                            "&.Mui-disabled": {
                              opacity: 0.5,
                              color: "#FFF",
                            },
                          },
                          "&.Mui-disabled": {
                            color: theme.palette.divider,
                            "& + .MuiSwitch-track": {
                              // opacity: 1,
                              backgroundColor: theme.palette.divider,

                              // color: '#3D5AFE',
                              opacity: 0.5,
                            },
                          },
                          "& + .MuiSwitch-track": {
                            backgroundColor: theme.palette.action.selected,
                          },
                        },
                        "& .MuiSwitch-thumb": {
                          boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
                          width: 12,
                          height: 12,
                          borderRadius: 6,
                          transition: theme.transitions.create(["width"], {
                            duration: 200,
                          }),
                        },
                        "& .MuiSwitch-track": {
                          borderRadius: 16 / 2,
                          opacity: 1,
                          backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
                          boxSizing: "border-box",
                        },
                      }),
                      ...(ownerState.size === "medium" && {
                        width: 42,
                        height: 24,
                        borderRadius: 28,
                        padding: 0,
                        display: "flex",
                        "&:active": {
                          "& .MuiSwitch-thumb": {
                            width: 18,
                          },
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            transform: "translateX(18px)",
                          },
                        },
                        "& .MuiSwitch-switchBase": {
                          padding: 4,
                          color: "#FFF",
                          "&.Mui-checked": {
                            transform: "translateX(18px)",
                            color: "#fff",
                            // backgroundColor: 'inherit',
                            "& + .MuiSwitch-track": {
                              // opacity: 1,
                              backgroundColor: "#3d5AFE",

                              // color: '#3D5AFE',
                              opacity: 1,
                            },
                            "&.Mui-disabled": {
                              opacity: 0.5,
                              color: "#FFF",
                            },
                          },
                          "&.Mui-disabled": {
                            color: theme.palette.divider,
                            "& + .MuiSwitch-track": {
                              // opacity: 1,
                              backgroundColor: theme.palette.divider,

                              // color: '#3D5AFE',
                              opacity: 0.5,
                            },
                          },
                          "& + .MuiSwitch-track": {
                            backgroundColor: theme.palette.action.selected,
                          },
                        },
                        "& .MuiSwitch-thumb": {
                          boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
                          width: 16,
                          height: 16,
                          borderRadius: 32,
                          transition: theme.transitions.create(["width"], {
                            duration: 200,
                          }),
                        },
                        "& .MuiSwitch-track": {
                          borderRadius: 16 / 2,
                          opacity: 1,
                          // backgroundColor:
                          //   theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
                          boxSizing: "border-box",
                        },
                      }),
                    }),
                  },
                },
              }),
          MuiCssBaseline: {
            styleOverrides: (theme) => ({
              body: {
                // overflow: "hidden",
                scrollbarColor: "#7BFD15", //"#2b2b2b",
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: isDarkMode ? "#383838" : "#F0F0F0", //"transparent", //"#7BFD15", //
                  // borderLeft: `1px solid #E0E0E0`,
                  //borderRight: `10px solid #E0E0E0`,
                  //position: 'absolute',
                  width: 12,
                  height: 12,
                  scrollbarGutter: "stable both-edges",
                },
                "#tabpreview &::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: "transparent", // isDarkMode ? "#383838" : "#F0F0F0", //"transparent", //"#7BFD15", //
                  // borderLeft: `1px solid #E0E0E0`,
                  //borderRight: `10px solid #E0E0E0`,
                  //position: 'absolute',
                  width: 12,
                  height: 12,
                  scrollbarGutter: "stable both-edges",
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  borderRadius: 8,
                  backgroundColor: isDarkMode ? "#808080" : "#BEBEBE", //"#6b6b6b",
                  position: "relative",
                  borderRight: "2px solid rgba(0, 0, 0, 0)",
                  borderLeft: "2.5px solid rgba(0, 0, 0, 0)",
                  borderTop: "2.5px solid rgba(0, 0, 0, 0)",
                  borderBottom: "2px solid rgba(0, 0, 0, 0)",
                  backgroundClip: "padding-box",
                  minHeight: 24,
                },
                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                  backgroundColor: isDarkMode ? "#A1A1A1" : "#959595",
                },
                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                  backgroundColor: isDarkMode ? "#A1A1A1" : "#959595",
                },
                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: isDarkMode ? "#A1A1A1" : "#959595",
                },
                "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                  // backgroundColor: "transparent",
                  backgroundColor: isDarkMode ? "#383838" : "#F0F0F0",
                },
              },
            }),
          },
        },
      }),

    [isDarkMode]
  );

  //register jwt token refresher
  let savedRefreshToken = cookies && cookies.partizion_refreshToken ? cookies.partizion_refreshToken.valueOf() : undefined;

  const appLocation = props.appLocation;
  const [gettingStarted, setGettingStarted] = useState(props.gettingStarted); //props.gettingStarted
  //for device checks
  const { browserName, majorVersion, OSName } = DetectClientInfo();
  const [currentDevice, setCurrentDevice] = useState({
    os: OSName,
    browser: browserName,
  });
  const [deviceLimit, setDeviceLimit] = useState(false);

  var isPopup = appLocation === "true" || window.location.href.indexOf("/search-app") > -1 ? true : false;

  //reset getting started on auth
  useEffect(() => {
    //console.log('AUTH: ' + globalState.isAuth)
    if (globalState.isAuth) {
      setGettingStarted(false);
    }
  }, [globalState.isAuth]);

  //Get Client info
  useEffect(() => {
    globalActions.setAppLocation(appLocation);
    globalActions.setAppIsDev(props.isDev);

    //console.log("is dev: " + props.isDev + " " +globalState.isDev)
    //if in production get the extension version
    if (globalState.isDev === true) {
      // console.log(
      //   "app version: " + appVersion + '\n' +
      //   "browser: " + browserName + '\n' +
      //   "browser Version: " + majorVersion + '\n' +
      //   "OS: " + OSName + '\n'
      // )
    }
  }, []);

  // const testUserObj = {
  //   browser: "Chrome",
  //   email: "matthewbf8@testmail.com",
  //   createdOn: "2020-04-23T00:10:41.670Z",
  //   lastLoggedIn: "2020-04-23T00:10:41.670Z",
  //   name: "Subby guy",
  //   betaUser: false,
  //   installStatus: true,
  //   os: "MacOS",
  //   trialEnd: "2023-03-12T04:30:07.590+00:00",
  //   // stripeSubscription: {
  //   //   plan: "The pro plan",
  //   //   status: "active",
  //   //   latestInvoice: {
  //   //     paymentMethod: {
  //   //       id: "pm_1Gat8TIEHHiIgNaSjMCkRml1",
  //   //       card: {
  //   //         brand: "visa",
  //   //         country: "US",
  //   //         last4: "4242",
  //   //       },
  //   //     },
  //   //     payment_intent: "succeeded",
  //   //   },
  //   // },
  //   registeredDevice: {
  //     os: "MacOS",
  //     browser: "Opera",
  //   },
  // };

  var token;
  var refresh = cookies.partizion_refreshToken;

  const LogoutUser = () => {
    setAuthToken();
    removeCookie("partizion_token", { path: "/" });
    removeCookie("partizion_refreshToken", { path: "/" });
    globalActions.setUser({});

    localStorage.clear();
    globalActions.setIsRefreshing(false);
    globalActions.LogInOut(false);
    globalActions.validatePrivateRoutePrivilege(false);
  };
  //Check if user loged in
  useEffect(() => {
    //console.log("useEffect called: Root | Check for cookies", cookies)
    //console.log("Cookies changed", cookies)

    var expiresIn;
    var refreshExpires;

    var decoded;
    var decodedRefresh;
    //Check for token to keep user logged in
    if (cookies.partizion_token) {
      // Set auth token header auth
      //console.log("token exists")
      //check subscription state
      const partizion_token = cookies.partizion_token;
      const partizion_refreshToken = cookies.partizion_refreshToken;
      //Also set for global use
      token = cookies.partizion_token;
      refresh = cookies.partizion_refreshToken;

      if (partizion_token) {
        //console.log("token is in cookies " + partizion_token)
        decoded = jwt_decode(partizion_token);
        //console.log("Decoded token in cookies", decoded)
        setAuthToken(partizion_token);
        globalActions.LogInOut(true);
        globalActions.validatePrivateRoutePrivilege(true);
        //globalActions.setUser(decoded)
      }
      if (partizion_refreshToken) {
        decodedRefresh = jwt_decode(partizion_refreshToken);
        //console.log(partizion_refreshToken)
      }
      axios
        .get("/users/")
        .then((res) => {
          //console.log("Got user info")
          //console.log(res.data)
          // Decode token and get user info and exp
          // Check for expired token
          const currentTime = Date.now() / 1000; // to get in milliseconds
          if (partizion_token && partizion_refreshToken) {
            expiresIn = (decoded.exp - currentTime) / 60; //minutes
            refreshExpires = (decodedRefresh.exp - currentTime) / (60 * 60 * 24); //days
            //console.log("token is valid for " + expiresIn + " mins" + '\n' + "refresh is valid for " + refreshExpires + " days")
          }
          if (decodedRefresh && decodedRefresh.exp < currentTime) {
            //change to refresh token??
            // Logout user
            //console.log("token is valid for " + expiresIn + " mins" + '\n' + "refresh is valid for " + refreshExpires + " days")
            //console.log("Refresh token has expired - use logout called")
            globalActions.setUser({});
            globalActions.LogInOut(false);
            globalActions.validatePrivateRoutePrivilege(false);

            setAuthToken();
            removeCookie("partizion_token", { path: "/" });
            removeCookie("partizion_refreshToken", { path: "/" });
            localStorage.clear();
            // var logThisInfo = {
            //   user: globalState.user,
            //   activeWorkspace: globalState.activeWorkspaceIndex,
            //   sessionExpiresIn: refreshExpires + " days",
            //   version: appVersion,
            // }
            // console.log(logThisInfo)
            //console.log(cookies.token + " cookie removed")
            // Redirect to login
            //window.location.href = "./login";
          } else {
            //uuid = res.data.user.uuid
            globalActions.setUser(res.data.user);

            // FullStory.identify(res.data.user._id, {
            //   displayName: res.data.user.name,
            //   email: res.data.user.email,
            // });

            // globalActions.setUser(testUserObj);

            // let trialingUser = res.data.user
            // trialingUser.createdOn = new Date()
            // trialingUser.trialEnd = new Date(new Date().setDate(new Date().getDate()+2))
            // globalActions.setUser(trialingUser)

            if (res.data.user.uuid) {
              //console.log(res.data.user.uuid)
              setLocalUUID(res.data.user.uuid);
              //checkLocalUUId(res.data.user.uuid) //check if UUID exists in local state
              //checkOnboarding(res.data.user)//display onboarding to pertinent users
            }
            // if(res.data.user.trialEnd){
            //   CheckTrialEnd(res.data.user)
            // } else {
            //   console.log("no trial to check")
            // }
          }
        })
        .catch((err) => {
          console.log("Error getting user subscription", err);
          //console.log(err)
          setAuthToken();
          removeCookie("partizion_token", { path: "/" });
          removeCookie("partizion_refreshToken", { path: "/" });
          globalActions.LogInOut(false);
          //console.log(err)
        });
    } else if (cookies.partizion_refreshToken) {
      //console.log("NO TOKEN TRYING REFRESH")
      //there is no token for some reason but there is a refreshtoken -> get a new token
      axios
        .post("/users/refreshtoken", {
          partizion_refreshToken: cookies.partizion_refreshToken,
        })
        .then((response) => {
          //console.log("successfully refreshed token")
          if (response.status === 200) {
            const { partizion_token } = response.data;
            let isProd = process.env.NODE_ENV !== "development" ? true : false;
            setAuthToken(partizion_token);
            //console.log(cookieMaxAge)
            setCookie("partizion_token", partizion_token, {
              path: "/",
              sameSite: "lax",
              maxAge: cookieMaxAge,
              secure: isProd,
            });
            if (partizion_token) {
              // Set current user
              const decoded = jwt_decode(partizion_token);
              //console.log(decoded)
              //globalActions.setUser(decoded)
              globalActions.LogInOut(true);
              globalActions.validatePrivateRoutePrivilege(true);
            }
            //set the header and retry original request
            //console.log(token)
            //console.log(response.data.token)
            //console.log(cookies.token)
            //console.log(axios.defaults.headers.common["authorization"])
            axios.defaults.headers.common["authorization"] = response.data.partizion_token;
            globalActions.setIsRefreshing(false);
          } else {
            //console.log(response.status)
          }
        })
        .catch((error) => {
          //console.log("Tried getting a new token with RefreshToken but the refresh is expired - Logout")
          console.log(error);
          //logout user
          setAuthToken();
          removeCookie("partizion_token", { path: "/" });
          removeCookie("partizion_refreshToken", { path: "/" });

          localStorage.clear();
          globalActions.setIsRefreshing(false);
          globalActions.LogInOut(false);
          globalActions.validatePrivateRoutePrivilege(false);
        });
    } else {
      //console.log("token is valid for " + expiresIn + " mins" + '\n' + "refresh is valid for " + refreshExpires + " days")
      //console.log("tokens do not exist in cookies - logout called")
      globalActions.setUser({});
      globalActions.LogInOut(false);
      globalActions.validatePrivateRoutePrivilege(false);
      setAuthToken();
      removeCookie("partizion_token", { path: "/" });
      removeCookie("partizion_refreshToken", { path: "/" });
    }
    //init the token tokenInterceptor
  }, [cookies.partizion_token]);

  const cookieMaxAge = 60 * 60 * 24 * 60; // 60 days in seconds

  //refresh the token on fail calls
  const tokenInterceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      //console.log(cookies)
      // Reject promise if usual error
      //console.log("axios refresh interceptor fired")
      if (!error.response || (error.response && error.response.status !== 440 && error.response.status !== 401)) {
        //console.log("req was not a jwt expiry failure")
        //setIsRefreshing(false)
        globalActions.setIsRefreshing(false);
        return Promise.reject(error);
      } else if (error.response && error.response.status === 440) {
        //console.log("REQ NEEDS FREFRESH TOKEN to be freshed")
        globalActions.setIsRefreshing(true);
        // in testing it worked without this -> removeCookie('partizion_token', { path: '/' }) //remove the expired cookie while refreshing
        /*
         * When response code is 440, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 440 response
         */

        //allCookies = document.cookie;

        const documentRefresh =
          document.cookie && document.cookie.split("; ") && document.cookie.split("; ").find((row) => row.startsWith("partizion_refreshToken="))
            ? document.cookie
                .split("; ")
                .find((row) => row.startsWith("partizion_refreshToken="))
                .split("=")[1]
            : undefined;

        let REFRESHTOKEN = cookies.partizion_refreshToken ? cookies.partizion_refreshToken : documentRefresh ? documentRefresh : savedRefreshToken;
        // console.log(REFRESHTOKEN)
        // console.log(cookies.partizion_refreshToken)
        //console.log({savedRefreshToken})
        //console.log(refresh)
        //console.log({cookies})
        //console.log(partizion_refreshToken)
        //cookies.partizion_refreshToken ? console.log("refresh in cookies") : console.log("refresh NOT in cookies")
        //console.log("client refreshing token with: " + REFRESHTOKEN.substring(REFRESHTOKEN.length - 5)) //REFRESHTOKEN.substring(REFRESHTOKEN.length - 5)

        axios.interceptors.response.eject(tokenInterceptor);
        return axios
          .post("/users/refreshtoken", {
            partizion_refreshToken: REFRESHTOKEN,
          })
          .then((response) => {
            //console.log("successfully refreshed token")
            if (response.status === 200) {
              const { partizion_token } = response.data;
              let isProd = process.env.NODE_ENV !== "development" ? true : false;
              setAuthToken(partizion_token);
              //console.log(cookieMaxAge)
              setCookie("partizion_token", partizion_token, {
                path: "/",
                sameSite: "lax",
                maxAge: cookieMaxAge,
                secure: isProd,
              });
              if (partizion_token) {
                // Set current user
                const decoded = jwt_decode(partizion_token);
                //console.log(decoded)
                //globalActions.setUser(decoded)
                globalActions.LogInOut(true);
                globalActions.validatePrivateRoutePrivilege(true);

                axios.defaults.headers.common["authorization"] = partizion_token; //response.data.partizion_token;
                error.response.config.headers["authorization"] = partizion_token; //response.data.partizion_token;
              }
              //set the header and retry original request
              //console.log(token)
              //console.log(response.data.token)
              //console.log(cookies.token)
              //console.log(axios.defaults.headers.common["authorization"])

              return axios(error.response.config);
              //return Promise.resolve(error.response.config)
            } else {
              console.log(response.status);
            }
          })
          .catch((error) => {
            //console.log("Interceptor found that refresh token is invalid, user should be logged out")
            console.log(error);
            console.log(error.response);
            //logout user
            if (error.response && error.response.status && error.response.status == 401) {
              LogoutUser();
            } else {
              return Promise.reject(error);
            }
          })
          .finally(tokenInterceptor);
      } else if (error.response && error.response.status === 401) {
        LogoutUser();
      } else {
        console.log("Error was not a 440");
        console.log(error.response);
      }
    }
  );

  const [localUUID, setLocalUUID] = useState(null);
  const checkLocalUUID = () => {
    //console.log("Check local uuid ")
    if (globalState.extensionConnection.isConnected && localUUID) {
      globalState.extensionConnection.port.postMessage({
        type: "checkUUID",
        uuid: localUUID,
      });
    }
  };
  useEffect(() => {
    checkLocalUUID();
  }, [globalState.extensionConnection.isConnected]);

  //TRIALS
  useEffect(() => {
    CheckTrialEnd(globalState.user);
  }, [globalState.user]);

  const [trialEnded, setTrialEnded] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [betaUser, setBetaUser] = useState(false);
  let trialEndInString = "";

  //TRIAL FUNCTIONS
  const TimeDiff = (end, round) => {
    var today = new Date();
    var trialEndDate = new Date(end);
    var Difference_In_Time = trialEndDate.getTime() - today.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    var DaysRounded = Math.round(Difference_In_Days);
    //console.log(DaysRounded)
    if (round === false) {
      return Difference_In_Days;
    } else {
      return DaysRounded;
    }
  };
  function english_ordinal_suffix(dt) {
    return (
      dt.getDate() +
      (dt.getDate() % 10 == 1 && dt.getDate() != 11
        ? "st"
        : dt.getDate() % 10 == 2 && dt.getDate() != 12
          ? "nd"
          : dt.getDate() % 10 == 3 && dt.getDate() != 13
            ? "rd"
            : "th")
    );
  }
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  function CheckTrialEnd(user) {
    //console.log("Running check user trial...", {user})
    var subscription =
      user.stripeSubscription &&
      user.stripeSubscription.status &&
      user.stripeSubscription.status !== "cancelled" &&
      user.stripeSubscription.status !== "canceled" &&
      user.stripeSubscription.status !== "unpaid" &&
      user.stripeSubscription.status !== "incomplete_expired" &&
      user.stripeSubscription.status !== "incomplete" &&
      user.stripeSubscription.status !== "inactive" &&
      user.stripeSubscription.status !== ""
        ? true
        : false;

    let cutOffDate = new Date("July 30, 2020 07:00:00"); //MATCH WITH SERVER
    let signedUpOn;
    if (user.date) {
      signedUpOn = new Date(user.date);
      if (signedUpOn <= cutOffDate) {
        //console.log(user.email + " is beta user")
      }
    }

    let betaUserTest = signedUpOn && signedUpOn <= cutOffDate ? true : false;
    setBetaUser(betaUserTest);

    if (user.trialEnd) {
      //GET trial end date info
      var userTrialEndDate = new Date(user.trialEnd);
      var monthName = monthNames[userTrialEndDate.getMonth()];
      var dateEnglish = english_ordinal_suffix(userTrialEndDate);
      var endDateString = monthName + " " + dateEnglish;
      endDateString = endDateString.toString();
      var trialEndsDays = TimeDiff(globalState.user.trialEnd, false);
      var trialEndsDaysRounded = Math.round(trialEndsDays);
      //console.log(endDateString)
      var today = new Date(); //for checking the the trial has ended

      //console.log(userTrialEndDate)
      //console.log("Today: " +today)
      //console.log(today > userTrialEndDate)

      //construct string
      trialEndInString =
        trialEndsDays && trialEndsDays > 2
          ? "Your free trial ends in " + trialEndsDaysRounded + " days."
          : trialEndsDays && trialEndsDays >= 1
            ? "Your trial ends in a couple of days."
            : "This is the last day of your free 14 day trial.";

      //display trial end page if ended and NOT subscribed or beta user
      //console.log({userTrialEndDate})
      var trialEndedProxy = today > userTrialEndDate;
      //console.log(endDateString)
      //console.log("users trial ended")
      setTrialEnded(today > userTrialEndDate);
      setSubscription(subscription);
      //Then update global state for use everywhere else in the app
      //console.log("setting user trial object: ", {subscription, betaUserTest, trialEndedProxy, endDateString})
      globalActions.setUserTrial({
        betaUser: betaUserTest,
        trialing: true,
        subscription: subscription,
        trialEndsDays: trialEndsDays,
        endDateString: endDateString,
        trialEndInString: trialEndInString,
        trialEnded: today > userTrialEndDate ? true : false,
      });
    } else {
      //console.log("user is not set yet or does not have trialEnd")
      //Then update global state for use everywhere else in the app
      //console.log("No trial. setting to: ", {subscription, betaUserTest, trialEnded, endDateString})
      globalActions.setUserTrial({
        betaUser: betaUserTest,
        trialing: false,
        subscription: subscription,
        trialEndsDays: null,
        endDateString: "",
        trialEndInString: "",
        trialEnded: null,
      });
      setSubscription(subscription);
    }
  }

  //EXTENSION CONNECTION
  const [extensionConnected, setExtensionConnected] = useState(false);
  const focusedWindow = useWindowFocused();
  const pathname = window.location.pathname;
  const [extensionPort, setExtensionPort] = useState(null);
  //for local use
  const [connectionID, setConnectionId] = useState(null);

  useEffect(() => {
    // console.log({ extensionConnected, focusedWindow, extensionPort });
    // eslint-disable-next-line no-undef
    var extensionId = process.env.NODE_ENV == "development" ? "ikgeijcbdncjkalmdjokjjdpogfhmack" : "ldimfpkkjopddckaglpeakpaepclcljn"; //PROD = "ldimfpkkjopddckaglpeakpaepclcljn" //old local bjainiepjdonhemlfgnjemgkpoplllkj

    //use intermediate state of isReconnecting === true
    if (!extensionConnected) {
      console.log("⏳ Reconnecting to Extension");
      // const statusReconnecting = () => {
      //   globalActions.setExtensionConnection({
      //     isConnected: false,
      //     isReconnecting: true,
      //     port: null,
      //   });
      // };

      // if (globalState.extensionConnection.isReconnecting === null) {
      //   //delay showing reconnecting on first load, isReconnecting starts as null -> true -> false
      //   setTimeout(statusReconnecting, 2000);
      // } else {
      //   statusReconnecting();
      // }
      globalActions.setExtensionConnection({
        isConnected: false,
        isReconnecting: true,
        port: null,
      });
    }

    //setup timers
    let attemptID = setInterval(attemptConnection, 1000);
    setConnectionId(attemptID);
    function clearAttemptsTimer() {
      //console.log("Timedout: Clearing attempt inverval " + attemptID)
      clearInterval(attemptID);
      //set extension to offline
      globalActions.setExtensionConnection({
        isConnected: false,
        isReconnecting: false,
        port: null,
      });
    }
    let timeout = setTimeout(clearAttemptsTimer, 20000); //tries for 20 seconds

    const uuidPattern = "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";
    const uuidPattern2 = "[a-f0-9]{32}";
    const combinedPattern = new RegExp(
      `^\/collection\/([a-z0-9-]+)-${uuidPattern}$|^\/collection\/${uuidPattern}$|^\/collection\/([a-z0-9-]+)-${uuidPattern2}$|^\/collection\/${uuidPattern2}$`
    );

    const isCollectionPage = !!pathname && pathname !== "/" ? combinedPattern.test(pathname) : false;
    //attempt to connect
    function attemptConnection() {
      if (!globalState.extensionConnection.isConnected) {
        if (focusedWindow || isCollectionPage) {
          let appName = isPopup ? "Partizion Command App" : "Partizion Web App";
          //console.log("attempt to connect to extension with " + appName + " " + extensionId)
          var port = browser && browser.runtime ? browser.runtime.connect(extensionId, { name: appName }) : null;
          if (port) {
            port.onMessage.addListener(function extensionMessageListener(msg, sendingPort) {
              //console.log("Message recieved from " + sendingPort.name, {msg});
              if (msg.connected === true) {
                console.log(`✅ Connected to Extension`);
                setExtensionConnected(true);
                setExtensionPort(port);
                globalActions.setExtensionConnection({
                  isConnected: true,
                  port: port,
                  windowId: msg.windowId,
                  tabId: msg.tabId,
                });
                //connected... mount disconnect listener
                port.onDisconnect.addListener(function extensionConnectionListener(disconnectedPort) {
                  console.log(`❌ Disconnected from Extension`, {
                    disconnectedPort,
                  });
                  setExtensionConnected(false);
                  setExtensionPort(null);
                  globalActions.setExtensionConnection({
                    isConnected: false,
                    isReconnecting: false,
                    port: null,
                  });

                  disconnectedPort.onMessage.removeListener(extensionMessageListener);

                  //start trying to connect again
                  //setTimeout(setRetryInt(prevInt => prevInt +1), 500)
                  //this is done with the useeffect dependency [extensionconnection]
                });

                //CONNECTED
                clearInterval(attemptID);
                clearTimeout(timeout);

                return true;
              } else {
                handleResponseMessages(msg, sendingPort);
              }
            });
          } else {
            console.log("failed to connect to web extension");
            setExtensionConnected(false);
            setExtensionPort(null);
            clearInterval(attemptID);
            clearTimeout(timeout);
            return false;
          }
        } else {
          console.log("window is not focused, removing connection attempts");
          clearInterval(attemptID);
          clearTimeout(timeout);
        }
      } else {
        //console.log("extension already connected")
        clearInterval(attemptID);
        clearTimeout(timeout);
      }
    }

    return () => {
      //cleanup
      clearInterval(attemptID);
      clearTimeout(timeout);
    };
  }, [focusedWindow, extensionConnected, pathname]);

  //HANDLE EXTENSION COMMUNICATION
  const handleResponseMessages = (msg, sendingPort) => {
    // console.log({ msg });
    if (msg.type === "initializeBrowserState") {
      //console.log("INITIAL STATE")
      //console.log(msg.state)
      // console.log("UPDATED STORAGE FROM EXTENSION: ", msg.state.storage);
      globalActions.setBrowserWindows(msg.state.windows);
      globalActions.setBrowserTabs(msg.state.tabs);
      globalActions.setBrowserStorage(msg.state.storage);
      globalActions.setBrowserCommands(msg.state.commands);
      globalActions.setExtensionCurrentCollections(msg.state.storage.partizion_collection_sync);
    }
    if (msg.type === "updateCurrentTabs") {
      const currState = globalState.browserWindows;
      const newState = update(currState, { $set: msg.state });

      if (newState !== currState && globalState.tabsMenuOpen) {
        //console.log("updating cur windows")
        //console.log({newState})
        globalActions.setBrowserWindows(msg.state);
      } else {
        //console.log("NOT updating, state is the same")
      }
    }
    if (msg.type === "updateBrowserWindows") {
      //console.log("Updating browser windows")
      //console.log(msg.state)
      // let newWindowState = {...globalState.browserState, windows: msg.state}
      // console.log({newWindowState})
      globalActions.setBrowserWindows(msg.state);
    }
    if (msg.type === "updateBrowserTabs") {
      //console.log("Updating browser tabs")
      //console.log(msg.state)
      // let newTabsState = {...globalState.browserState, tabs: msg.state}
      // console.log({newTabsState})
      globalActions.setBrowserTabs(msg.state);
    }

    // if(msg.type === "updateCurWindowTabs"){
    //   //this is called when a collection is opened or replaced to speed up the updating process
    //   console.log(msg.newState)
    //   globalActions.setBrowserWindows(msg.newState)
    // }

    if (msg.type === "updateBrowserStorage") {
      //console.log("Updating browser storage")
      //console.log(msg.storage)
      // let newStorageState = {...globalState.browserState, storage: msg.state}
      // console.log({newStorageState})
      globalActions.setBrowserStorage(msg.storage);
    }
    if (msg.type === "updateCurrentCollections") {
      console.log("Syncing with extensions current collections", msg.state);
      globalActions.setExtensionCurrentCollections(msg.state);
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <SWRConfig
          value={{
            // fetcher: (url) => fetch(url).then(res => res.json()),
            revalidateOnFocus: true, //props.isDev ? false : true
          }}
        >
          <NewTab deviceLimit={deviceLimit} gettingStarted={gettingStarted} />
        </SWRConfig>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Root;
