/* eslint-disable no-redeclare */
/* eslint-disable react/forbid-component-props */
import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { useDrag, useDrop } from "react-dnd";
import ItemTypes from "../DND/ItemTypes";
import { getEmptyImage } from "react-dnd-html5-backend";

import axios from "axios";
import CollectionTab from "./tabs/CollectionTab.js";
import CollectionMenu from "../Menus/CollectionMenu.js";

import { paramCase } from "change-case";
import useGlobal from "../../GlobalState/Store/Store.js";

import useHoverGroup from "../../Utils/useHoverGroup";

import TabsGrid from "./tabs/TabsGrid.js";

import AlertDialog from "../Dialogs/AlertDialog.js";

import CollectionPropertyDiv from "./Properties/CollectionPropertyDiv.js";

import { mutate } from "swr";
import update from "immutability-helper";

import toReadableDate from "../../Utils/toReadableDate";
import { useTheme, styled } from "@mui/material/styles";

import { Button, IconButton, Typography, Tooltip, Grid, Box, Accordion, AccordionDetails, ButtonGroup, CircularProgress } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Repeat from "@mui/icons-material/Repeat";

import DeleteCollectionDialogue from "../Dialogs/DeleteCollectionDialog.js";
import Tab from "@mui/icons-material/Tab";

import "./CollectionCard.css";

//FOR browser extension APIs

import EmptyCollection from "../../Assets/emptymailbox.png";
import HotkeyChip from "../Hotkey/HotkeyChip";
import { useSnackbar } from "notistack";
import PlainSnackBar from "../Notistack/PlainSnackBar";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import BoltRoundedIcon from "@mui/icons-material/BoltRounded";

import { useNavigate } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import CurrentlyOpenBadge from "./CurrentlyOpenBadge";
import { isCollectionCurrentlyOpen } from "../../Utils/isCollectionCurrentlyOpen";
import useEncodeCollectionURL from "../../Hooks/useEncodeCollectionURL";

const InfoIconButton = styled(IconButton)(({ theme }) => ({
  // backgroundColor: theme.palette.text.tertiary,
  color: theme.palette.text.secondary,
  padding: 3,
  minHeight: 0,
  fontSize: 12,
  fontWeight: 500,
  marginLeft: 24,

  "&:hover": {
    color: "rgba(255,255,255, 0.6)",
    // color: theme.palette.text.primary,
    backgroundColor: "rgba(255,255,255, 0.15)",
  },
  "&:active": {
    color: "rgba(255,255,255, 0.6)",
    backgroundColor: "rgba(255,255,255, 0.15)",
    // color: theme.palette.text.primary,
  },
}));

const CollectionComponent = ({
  id,
  index,
  moveCollectionCard,
  resetCollectionOrder,

  collection,
  activeWorkspace,
  handleOpenCollectionDialog,

  collectionsOrder,
  dragActive,
  setDragActive,
  handleClickSelectWorkspace,

  cardSizePreference,
  cardSizePercentage,
  cardMinWidth,
  tabPreview,
  previewMax,
  maxTabsInList,
  cardTitlePosition,
  viewType,
  showTabCount,
  showLastUpdated,
  showCreatedOn,
  showCustomProperties,
  tabsLayout,

  openAddTabsDialog,
  clearSelectedTabs,
  handleAddSnackbarToStack,

  kanbanGroup,
  moveCollectionCardInKanban,
  kanbanCollectionOrder,
  setShowDropInColumn,
  setShowCreateCollectionWithDrag,

  expandedCollectionIds,
  toggleCollectionExpanded,
  handleToggleAutoUpdateTabs,
}) => {
  const [globalState, globalActions] = useGlobal();
  const theme = useTheme();
  const navigate = useNavigate();

  const ref = useRef(null); //main collection ref, mostly used for dropping
  const dragRef = useRef(null); // the actual part on the collection that you want to use for dragging

  const name = collection != null && collection != undefined ? collection.name : null; //StringCut(collection.name, stringCutConstant)
  // const LinkToCollection = globalState.activeWorkspace != undefined ? `/${paramCase(globalState.activeWorkspace._id)}/${collection.urlId}` : "/";
  const { getLinkToCollection } = useEncodeCollectionURL();
  const LinkToCollection = getLinkToCollection(collection?._collectionId, collection?.name);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);

  const cardMinHeight = cardSizePreference == "large" ? 150 : cardSizePreference == "medium" ? 125 : 100;

  const { enqueueSnackbar } = useSnackbar();

  //AUTO-UPDATE TABS --------
  const { collectionCurrentlyOpen, collectionInSync } = isCollectionCurrentlyOpen(collection?._id);

  //CLICKING COLLECTION
  const [selected, setSelected] = useState();
  function overrideSelected(boolean) {
    //console.log("Overriding selected " + boolean)
    setSelected(boolean);
  }

  const handleCollectionClick = (event) => {
    //console.log(event.target)
    //console.log(event.target.tagName)
    //console.log(event.target.tagName == "SPAN")
    // var shiftKey = event.shiftKey;
    var commandKey = event.ctrlKey || event.metaKey ? true : false;
    var optionKey = event.optionKey || event.altKey ? true : false;
    //console.log({event})
    if (commandKey && collection) {
      window.open(LinkToCollection);
      return;
    }
    if (optionKey) {
      //setSelected(!selected)
      navigate(LinkToCollection);
    }
    // if(optionKey){
    // //console.log("Collection was clicked with command key", LinkToCollection)

    // }
    else if (event.target == "A" || event.target == "SPAN") {
      return;
    }
    // else if(false){
    //   //handle clicking on a full view collection
    //   toggleCollectionExpanded(event, collection._id, !collectionExpanded)
    // }
    else {
      handleOpenCollectionDialog(collection);
    }
  };

  //FOR EDITING NAME
  const [oldOrder, setOldOrder] = useState([]);
  const [dragOrder, setDragOrder] = useState([]);
  const updateDragOrderState = useCallback(
    (dragIndex, hoverIndex) => {
      //console.log("we want to move " + dragIndex + " to " + hoverIndex)
      const dragCollection = dragOrder[dragIndex];
      //console.log("move collection: " + collections[dragIndex].name)
      setDragOrder(
        update(dragOrder, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCollection],
          ],
        })
      );
    },
    [dragOrder]
  );

  //on load set old collections for comparison
  function resetOldOrder() {
    let collectionCardMounted = true;
    if (collectionCardMounted) {
      //console.log("1")
      var old = [];
      collectionsOrder.map((c) => {
        old.push({ name: c.name, _id: c._id });
      });
      if (collectionCardMounted) setOldOrder(old);
    }
  }

  useEffect(() => {
    let collectionCardMounted = true;
    if (collectionCardMounted) {
      resetOldOrder();
      // setDragOrder(collectionsOrder)
    }
    // cancel subscription to useEffect
    return () => {
      collectionCardMounted = false;
    };
  }, []);

  function SortTabs(a, b) {
    //console.log("TAB A" + a.url + '\n' + a.indexPosition)
    //console.log("TAB B" + b.url + '\n' + b.indexPosition)

    if (a?.pinned && !b?.pinned) {
      return -1; //move a ahead of b
    } else if (!a?.pinned && b?.pinned) {
      return 1; //move b ahead of a
    } else if (a?.pinned && b?.pinned) {
      //they are both pinned which means we just want to order by indexPosiiton first - not last edited (could have moved a pinned tab back)
      if (a?.indexPosition < b?.indexPosition) {
        return -1;
      } else if (a?.indexPosition > b?.indexPosition) {
        return 1;
      } else {
        //console.log("index a and b are equal or DNE")
        if (a?.indexUpdatedOn < b?.indexUpdatedOn) {
          //console.log("index a was updated before b")
          return 1;
        } else if (a?.indexUpdatedOn > b?.indexUpdatedOn) {
          //console.log("index a was updated after b")
          return -1;
        } else {
          //console.log("index does not exist")
          if (a?.createdOn < b?.createdOn) {
            //console.log("a was created before b")
            return -1;
          } else if (a?.createdOn > b?.createdOn) {
            //console.log("a was created after b")
            return 1;
          } else {
            //console.log("dont change pos")
            return 0;
          }
        }
      }
    } else {
      //NEITHER TAB IS PINNED
      if (a?.indexPosition < b?.indexPosition) {
        //console.log("index a is less than b")
        return -1; //a before b
      } else if (a?.indexPosition > b?.indexPosition) {
        //console.log("index a is greater than b")
        return 1;
      } else {
        //console.log("index a and b are equal or DNE")
        if (a?.indexUpdatedOn < b?.indexUpdatedOn) {
          //console.log("index a was updated before b")
          return 1;
        } else if (a?.indexUpdatedOn > b?.indexUpdatedOn) {
          //console.log("index a was updated after b")
          return -1;
        } else {
          //console.log("index does not exist")
          if (a?.createdOn < b?.createdOn) {
            //console.log("a was created before b")
            return -1;
          } else if (a?.createdOn > b?.createdOn) {
            //console.log("a was created after b")
            return 1;
          } else {
            //console.log("dont change pos")
            return 0;
          }
        }
      }
    }
  }

  const displayTabs = useMemo(() => (collection && !!collection?.tabs ? collection?.tabs?.sort(SortTabs) : []), [collection.tabs]);

  function arraysEqual(a, b) {
    // if (a === b) return true;
    // if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.
    var idsEqual;
    for (var i = 0; i < a.length; ++i) {
      ////console.log(a[i]._id + " ? " + b[i]._id)
      if (a[i]._id !== b[i]._id) {
        ////console.log('Different order')
        idsEqual = false;
        return false;
      } else if (i == a.length - 1 && a[i]._id === b[i]._id) {
        //last item
        idsEqual = true;
        return true;
      }
    }
    //return idsEqual
  }

  //REORDERINNG WHEN THERE ARE SORTS
  const [reorderAlert, setReorderAlert] = React.useState(false);
  const [desiredMoveState, setDesiredMoveState] = useState(null);
  const alertReorderState = {
    confirmText: "Remove sorts",
    cancelText: "Cancel",
  };
  const handleOpenReorderAlert = () => {
    setReorderAlert(true);
  };
  const handleCloseReorderAlert = () => {
    //console.log("CANCEL reorder")
    resetCollectionOrder();
    setReorderAlert(false);
  };
  const ConfirmMoveCollections = () => {
    //console.log("SORT REMOVE confirmed")
    //console.log(desiredMoveState.dragIndex, desiredMoveState.properIndex, viewType == "Grid", desiredMoveState.dragIndex, desiredMoveState.properIndex)
    if ((viewType == "Grid" && !!desiredMoveState) || (viewType == "List" && !!desiredMoveState) || (viewType == "Full" && !!desiredMoveState)) {
      MoveCollections(true, desiredMoveState.dragIndex, desiredMoveState.properIndex);
      setReorderAlert(false);
    }
    // else if(viewType == "List" && !!desiredMoveState){
    //   MoveCollections(true, desiredMoveState.dragIndex, desiredMoveState.properIndex)
    //   setReorderAlert(false);
    // }
    else if (viewType == "Kanban" && !!desiredMoveState) {
      moveCollectionCardInKanban(
        true,
        desiredMoveState.itemCollection,
        desiredMoveState.kanbanGroup,
        desiredMoveState.itemKanbanGroupId,
        desiredMoveState.dragIndex,
        desiredMoveState.properDestIndex
      );
      setReorderAlert(false);
    }
    //else console.log("DID NOT CONFRIM MOVE", viewType, desiredMoveState)

    setDesiredMoveState(null);
  };

  function AlertMoveCollections(dragIndex, properIndex) {
    //alert move collections has to keep the desired move state (dragIndex, properIndex)
    setDesiredMoveState({ dragIndex, properIndex });

    //var newOrder = []
    // collectionsOrder.map(c => {
    //   newOrder.push({name: c.name, _id: c._id})
    // })
    if (arraysEqual(dragOrder, oldOrder)) {
      //console.log("no change in order")
      setDesiredMoveState(null);
    } else {
      //console.log("SORT in place, alert to remove sorts")
      handleOpenReorderAlert();
    }
  }
  function AlertMoveKanbanCollections(itemCollection, kanbanGroup, itemKanbanGroupId, dragIndex, properDestIndex) {
    setDesiredMoveState({
      itemCollection,
      kanbanGroup,
      itemKanbanGroupId,
      dragIndex,
      properDestIndex,
    });
    handleOpenReorderAlert();
  }

  function MoveCollections(removeSorts, dragIndex, hoverIndex) {
    //console.log("REORDER COLLECTIONS")
    // if(dragIndex && hoverIndex)
    // else updateCollectionOrder(dragOrder)
    moveCollectionCard(dragIndex, hoverIndex);

    const dragCollection = collectionsOrder[dragIndex];

    let updatedCollectionOrder = update(collectionsOrder, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCollection],
      ],
    });

    // console.log(console.log(update(collectionsOrder, {
    //   $splice: [
    //     [dragIndex, 1],
    //     [hoverIndex, 0, dragCollection],
    //   ],
    // })))
    //LogNewOrder(dragIndex, hoverIndex)

    //console.log({updatedCollectionOrder})
    setDesiredMoveState(null);
    // var newOrder = []
    // collectionsOrder.map(c => {
    //   newOrder.push({name: c.name, _id: c._id})
    // })

    //console.log(oldOrder)
    //console.log(arraysEqual(newOrder, oldOrder))
    // if(arraysEqual(updatedCollectionOrder, oldOrder)){
    //   console.log("no change in order")
    // } else {

    // }
    //console.log("call reorder API")
    var data = {
      newOrder: updatedCollectionOrder,
    };
    if (removeSorts) {
      data.viewId = globalState.activeView._id;
      data.removeSorts = true;
    }

    axios
      .post("/collections/reorder", data)
      .then((res) => {
        //console.log(res.data.message)
        if (res.data.view) {
          globalActions.setActiveView(res.data.view);
        }
        //update the name in local state immediately
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
      })
      .then(() => {
        //ReloadWorkspaces()
        //globalActions.setWorkSpaces(workspaceOrder)

        //console.log("Should call reorder")
        //resetOldOrder()
        //instead of callign reset, just do it manually, more reliable:
        setOldOrder(updatedCollectionOrder);
      })
      .catch((err) => {
        //console.log("Could not update workspace name")
        console.log(err);
      });
  }

  function MoveTabsToCollection(movedTabs, oldCollectionId) {
    //console.log("dragging tabs to collection to add. shift key: " + shiftKey)
    //console.log(collection._id)
    // console.log(tabs)
    //console.log(movedTabs)
    const tabIds = movedTabs.map((t) => t._id);

    //increment all the tabs indexPosiiton to make sure they are put at the end of the list
    const lastIndexPosition =
      collection && collection.tabs && collection.tabs[collection.tabs.length - 1] && collection.tabs[collection.tabs.length - 1].indexPosition
        ? collection.tabs[collection.tabs.length - 1].indexPosition
        : 0;
    const now = new Date();
    if (lastIndexPosition > 0)
      movedTabs.forEach((mt, i) => {
        mt.indexPosition = i + lastIndexPosition + 1;
        mt.indexUpdatedOn = now;
      });

    if (oldCollectionId === collection._id) return; //dropped on same collection
    //apply change locally immediately
    mutate(
      [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
      async (collectionsData) => {
        const addedCollectionIndex =
          collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id === collection._id) : -1;
        const removedCollectionIndex =
          collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id === oldCollectionId) : -1;

        if (addedCollectionIndex > -1 && removedCollectionIndex > -1) {
          const updatedCollections = update(collectionsData.collections, {
            [removedCollectionIndex]: {
              tabs: {
                $set: collectionsData.collections[removedCollectionIndex].tabs.filter((t) => tabIds.indexOf(t._id) == -1),
              },
            },
            [addedCollectionIndex]: { tabs: { $push: movedTabs } },
          });
          return { ...collectionsData, collections: updatedCollections };
        } else {
          return collectionsData;
        }
      },
      false
    );

    const data = {
      newCollectionId: collection._id,
      oldCollectionId: oldCollectionId,
      tabs: movedTabs,
    };

    axios
      .post("/collections/movetabs", data)
      .then((res) => {
        //update the name in local state immediately
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        if (res && res.data && res.data.duplicates && res.data.duplicates.length > 0) {
          var message = "Duplicates were not saved";
          enqueueSnackbar(message, {
            content: (key, message) => <PlainSnackBar id={key} message={message} />,
          });
        }
      })
      .catch((err) => {
        //console.log("add tab error")
        console.log(err);
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
      });
  }

  function AddTabsToCollection(tabs, type, item) {
    //console.log("dragging tabs to collection to add. shift key: " + shiftKey)
    //console.log(collection._id)
    // console.log(tabs)
    var addTabs;
    if (tabs.length && tabs.length > 0) {
      addTabs = tabs;
    } else {
      //console.log("not addding any tabs...")
      //console.log(tabs)
    }
    // console.log("Trying to add " + addTabs.length + " tabs to collection " + collection._id)
    // console.log(shiftKeyActive)
    // setReq({
    //   loading: true,
    //   isError: false,
    //   error: null
    // })

    //don't call api if the tabs are the same
    const uniqueTabs = (tabToAdd) => {
      let notUnique = collection.tabs.findIndex((existingTab) => existingTab.url === tabToAdd.url);
      //console.log(tabToAdd.url + " is unique: " + notUnique)
      let notNewTab = tabToAdd.url !== "chrome://newtab/" ? true : false; //&& !tabToAdd.url.includes(prodExtensionUrl) && !tabToAdd.url.includes(devExtensionUrl)

      if (notUnique === -1 && notNewTab) {
        return true;
      } else {
        return false;
      }
    };

    var methodString = "Dragged a " + type + " into collection";
    //console.log({addTabs})
    if (addTabs.some(uniqueTabs)) {
      //At least one of the tabs to add is unique
      const data = {
        collectionId: collection._id,
        updateType: "add",
        newTabs: addTabs,
        method: methodString,
      };

      axios
        .post("/collections/updatetabs", data)
        .then((res) => {
          //update the name in local state immediately
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);

          if (res.status === 200) {
            //console.log("Saving tab was a success", addTabs, item)
            // globalActions.setGlobalSnackbarOpen(true)
            // globalActions.setGlobalSnackbarState({
            //   status: "success",
            //   message: "🙌 tab saved to collection ",
            //   closeTime: 3000
            // })

            //after saving the tab close it
            //CLOSE TABS OPTION
            if (type == "curwindow" || type == "curtab") {
              if (addTabs.length === 1) {
                //console.log("close that single tab")
                //browser.tabs.remove(addTabs[0].id)
                let closeTabs = [];
                closeTabs.push(addTabs[0].id);

                let message = `✅ Saved tab`;
                enqueueSnackbar(message, {
                  content: (key, message) => <PlainSnackBar id={key} message={message} showCloseTabsButton tabsToClose={closeTabs} />,
                });
              } else if (item.windows && item.windows.length === 1) {
                //reset window
                //console.log("Close all except new tab")
                //item.tabs.filter(function(tab) {
                var inActiveTabs = addTabs.filter(function (tab) {
                  return tab.active === false;
                });
                let closeTabs = inActiveTabs.map((tab) => tab.id);
                //console.log(closetabs)
                //browser.tabs.remove(closetabs)
                let message = `✅ Saved tabs`;
                if (type !== "pasttab" && type !== "pastsession") {
                  enqueueSnackbar(message, {
                    content: (key, message) => <PlainSnackBar id={key} message={message} showCloseTabsButton tabsToClose={closeTabs} />,
                  });
                }
              } else if (item.windows && item.windows.length > 1) {
                //console.log("close that window")
                //browser.windows.remove(item.window.id)
                let message = `✅ Saved ${addTabs.length} tabs`;
                enqueueSnackbar(message, {
                  content: (key, message) => <PlainSnackBar id={key} message={message} />,
                });
              } else if (item.selectedTabs && item.selectedTabs.length >= 1) {
                //user manually selected tabs to save
                var inActiveTabs = addTabs.filter(function (tab) {
                  return tab.active === false;
                });
                let closeTabs = inActiveTabs.map((tab) => tab.id);
                //console.log(closetabs)
                //browser.tabs.remove(closetabs)
                let message = `✅ Saved tabs`;
                if (type !== "pasttab" && type !== "pastsession") {
                  enqueueSnackbar(message, {
                    content: (key, message) => <PlainSnackBar id={key} message={message} showCloseTabsButton tabsToClose={closeTabs} />,
                  });
                }
              }
            } else {
              //dragged in session tabs
              let message = `✅ Added tabs`;
              enqueueSnackbar(message, {
                content: (key, message) => <PlainSnackBar id={key} message={message} />,
              });
            }

            clearSelectedTabs();
          }
        })
        .catch((err) => {
          //console.log("add tab error")
          console.log(err);
        });
    } else {
      var message = "🛑 Cannot save the Partizion Dashboard or duplicates";
      enqueueSnackbar(message, {
        content: (key, message) => <PlainSnackBar id={key} message={message} />,
      });
    }
  }

  //EXPANDING COLLECTION
  const collectionExpanded = expandedCollectionIds && expandedCollectionIds.indexOf(collection._id) > -1 ? true : false;

  let lastEditedBase = collection.lastEdited ? new Date(collection.lastEdited) : new Date(collection.createdOn);
  let lastUpdatedString = toReadableDate(lastEditedBase);

  let createdOnBase = collection.lastEdited ? new Date(collection.createdOn) : new Date();
  let createdOnString = toReadableDate(createdOnBase);

  //for control drag preview lines
  let boundingRect = ref && ref.current ? ref.current.getBoundingClientRect() : null;
  let previewProps = {
    viewType: viewType,
    showTabCount: showTabCount,
    showCreatedOn: showCreatedOn,
    showLastUpdated: showLastUpdated,
    tabPreview: tabPreview,
    previewMax: previewMax,
    tabsLayout: tabsLayout,
    cardTitlePosition: cardTitlePosition,
    maxTabsInList: maxTabsInList,
    createdOnString: createdOnString,
    lastUpdatedString: lastUpdatedString,
    showCustomProperties: showCustomProperties,
    displayTabs: displayTabs,
    cardMinWidth: cardMinWidth,
    cardSizePercentage: cardSizePercentage,
    cardMinHeight: cardMinHeight,
    boundingRect: boundingRect,
    collectionExpanded: collectionExpanded,
  };
  const [dragMousePosition, setdragMousePosition] = useState(null);

  const [{ isOver, currentlyDraggedItem }, drop] = useDrop({
    accept: [
      ItemTypes.COLLECTIONCARD,
      ItemTypes.CURTAB,
      ItemTypes.CURWINDOW,
      ItemTypes.PASTSESSION,
      ItemTypes.PASTTAB,
      ItemTypes.COLLECTIONTAB,
      ItemTypes.TABCARD,
    ],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      //canDrop: monitor && collectionCardMounted ? monitor.canDrop() : false,
      currentlyDraggedItem: monitor.getItem(),
      //dragMousePosition: monitor.getClientOffset(),
    }),
    hover(item, monitor) {
      //console.log(item)
      if (!ref.current) {
        return;
      }
      setShowCreateCollectionWithDrag(null);
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex && !item.kanbanGroup) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      // const right = dragIndex < hoverIndex && hoverClientX > hoverMiddleX
      // const left = dragIndex > hoverIndex && hoverClientX < hoverMiddleX
      //
      const down = dragIndex < hoverIndex; //&& hoverClientY < hoverMiddleY
      const up = dragIndex > hoverIndex; //&& hoverClientY > hoverMiddleY

      const right = hoverClientX > hoverMiddleX;
      const left = hoverClientX < hoverMiddleX;
      const below = hoverClientY > hoverMiddleY;
      const above = hoverClientY < hoverMiddleY;

      let properIndex =
        item.type === "collectioncard" && viewType == "Grid"
          ? right && hoverClientX < hoverMiddleX
            ? hoverIndex - 1
            : left && hoverClientX > hoverMiddleX
              ? hoverIndex + 1
              : hoverIndex
          : up && hoverIndex + 1 != dragIndex
            ? hoverIndex + 1
            : down && hoverIndex - 1 != dragIndex
              ? hoverIndex - 1
              : hoverIndex;

      //up && hoverIndex + 1 != dragIndex ? hoverIndex + 1 : down && hoverIndex - 1 != dragIndex ? hoverIndex - 1 : hoverIndex
      if (item.type === "collectioncard") {
        if (
          !dragMousePosition ||
          (!!dragMousePosition && dragMousePosition.left != left) ||
          (!!dragMousePosition && dragMousePosition.right != right) ||
          (!!dragMousePosition && dragMousePosition.below != below) ||
          (!!dragMousePosition && dragMousePosition.above != above)
        ) {
          //console.log({left, right, above, below})
          if (viewType == "Kanban") {
            setdragMousePosition({
              left: left,
              right: right,
              above: above,
              below: below,
              insertIndex: properIndex,
              insertColumnId: kanbanGroup._id,
            });
            setShowDropInColumn(null);
          } else {
            setdragMousePosition({
              left: left,
              right: right,
              above: above,
              below: below,
              insertIndex: properIndex,
            });
          }
        }
      } else {
        setdragMousePosition(null);
      }

      // Time to actually perform the action
      if (item.type === "collectioncard" && viewType != "Kanban" && dragIndex != hoverIndex && dragOrder[hoverIndex] && dragOrder[hoverIndex]._id != item.id) {
        //only rearrange collections if its another collection being dragged on it
        //moveCollectionCard(dragIndex, hoverIndex)
        //console.log(dragOrder[hoverIndex]._id != item.id, dragOrder[hoverIndex]._id, item.id)
        updateDragOrderState(dragIndex, hoverIndex);
      } else if (item.type === "collectioncard" && viewType == "Kanban" && dragIndex != hoverIndex) {
        //console.log("We want to move " + dragIndex + " to " + hoverIndex + " in column " + kanbanGroup.index)
        // moveCollectionCardInKanban(collection, kanbanGroup, item.kanbanGroup._id) //(collectionId, destColumnId, curColumnIndex, destCollectionIndex, curCollectionIndex)
        //item.kanbanGroup = kanbanGroup
      }
      //console.log(dragIndex, hoverIndex, kanbanGroup._id, item.kanbanGroup._id)
      //item.index = hoverIndex

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
    },
    drop(item, monitor) {
      //console.log(item, "dropped on " + index)
      setdragMousePosition(null);
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset && clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset && clientOffset.x - hoverBoundingRect.left;
      const right = dragIndex < hoverIndex; //&& hoverClientX > hoverMiddleX
      const left = dragIndex > hoverIndex; //&& hoverClientX < hoverMiddleX
      const down = dragIndex >= hoverIndex; //&& hoverClientY < hoverMiddleY
      const up = dragIndex <= hoverIndex; //&& hoverClientY > hoverMiddleY

      if (item.type === "collectioncard" && viewType == "Grid") {
        if (dragIndex === hoverIndex) return;

        let properIndex = right && hoverClientX < hoverMiddleX ? hoverIndex - 1 : left && hoverClientX > hoverMiddleX ? hoverIndex + 1 : hoverIndex;
        if (globalState.activeView && globalState.activeView.sort.length > 0) {
          AlertMoveCollections(dragIndex, properIndex);
        } else {
          //MoveCollections()

          //if RIGHT then the hover index should be hoverIndex -1
          //if LEFT then it should be the hover index
          //edge cases: hoverIndex != 0  hoverIndex != collectionsOrder.length -1

          //console.log({left, right, properIndex})
          //console.log("Moving to proper index " + properIndex)
          MoveCollections(null, dragIndex, properIndex);
          //need to implement both below into above
          //moveCollectionCard(dragIndex, hoverIndex)
        }
      }
      if ((item.type === "collectioncard" && viewType == "List") || (item.type === "collectioncard" && viewType == "Full")) {
        if (dragIndex === hoverIndex) return;
        let properDestIndex =
          up && hoverClientY < hoverMiddleY && hoverIndex != 0 ? hoverIndex - 1 : down && hoverClientY > hoverMiddleY ? hoverIndex + 1 : hoverIndex;
        //console.log({properDestIndex})
        if (globalState.activeView && globalState.activeView.sort.length > 0) {
          AlertMoveCollections(dragIndex, properDestIndex);
        } else {
          //console.log({above, below, properDestIndex})
          MoveCollections(null, dragIndex, properDestIndex);
        }
      } else if (item.type === "collectioncard" && viewType == "Kanban") {
        if (dragIndex === hoverIndex && kanbanGroup && kanbanGroup._id === item.kanbanGroup._id) return;

        let properDestIndex =
          up && hoverClientY < hoverMiddleY && hoverIndex != 0 && kanbanGroup._id === item.kanbanGroup._id
            ? hoverIndex - 1
            : hoverClientY > hoverMiddleY
              ? hoverIndex + 1
              : hoverIndex;

        if (globalState.activeView && globalState.activeView.sort.length > 0) {
          AlertMoveKanbanCollections(item.collection, kanbanGroup, item.kanbanGroup._id, dragIndex, properDestIndex);
        } else {
          // dropping on the index should almost always equal that index, unless the card is supposed to go below that card, not replace it (above)
          //|| index === 0 && hoverClientY > hoverMiddleY
          //console.log("Moving to proper dest index " + properDestIndex)
          //this comes from the collectionList component, and also saves the order much like the above MoveCollectionCard
          moveCollectionCardInKanban(null, item.collection, kanbanGroup, item.kanbanGroup._id, dragIndex, properDestIndex); //(collectionId, destColumnId, curColumnIndex, destCollectionIndex, curCollectionIndex)
        }
      }
      if (item.type === "curtab") {
        var tabsToAdd = [];
        if (item.selectedTabs && item.selectedTabs.length > 0) {
          tabsToAdd = item.selectedTabs;
        } else {
          tabsToAdd.push(item.tab);
        }
        AddTabsToCollection(tabsToAdd, item.type, item);
      }
      if (item.type === "pasttab") {
        //console.log("dragging past tabs")
        var tabsToAdd = [];
        if (item.selectedTabs && item.selectedTabs.length > 0) {
          tabsToAdd = item.selectedTabs;
        } else {
          tabsToAdd.push(item.tab);
        }
        AddTabsToCollection(tabsToAdd, item.type, item);
      }
      if (item.type === "curwindow") {
        AddTabsToCollection(item.tabs, item.type, item);
      }
      if (item.type === "pastsession") {
        //console.log("dragging past tabs")
        AddTabsToCollection(item.tabs, item.type, item);
      }
      if (item.type === "collectiontab" || item.type === "tabcard") {
        //accepts regular tabs (from tabs grid) and from the preview
        MoveTabsToCollection([item.tab], item.collectionId);
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: ItemTypes.COLLECTIONCARD,
      id,
      collection,
      index,
      kanbanGroup,
      previewProps,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    begin() {
      //for autoscrolling
      //addEventListenerForSidebar()
      //console.log("DRAG START ", dragMousePosition)
      // setHover(false)
      setDragActive(id);
    },
    end(item, monitor) {
      //remove autoscrolling
      //removeEventListenerForSidebar()
      //MoveWorkspace(targetIndex, workspace, )
      setDragActive(false);
      setShowDropInColumn(null);
    },
    // canDrag(props) {
    //   return !editTitle
    // },
  });
  //Connect the Custom dragPreview
  useEffect(() => {
    let collectionCardMounted = true;
    if (collectionCardMounted) preview(getEmptyImage(), { captureDraggingState: true });

    return () => {
      //cleanup
      preview(null);
      collectionCardMounted = false;
    };
  }, []);

  const opacity = isDragging ? 0.9 : 1;
  //drag(drop(ref))

  drag(dragRef);
  drop(ref);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const collectionMenuOpen = Boolean(anchorEl);
  const initialState = {
    mouseX: null,
    mouseY: null,
  };
  const [clickPosition, setClickPosition] = useState(initialState);
  const handleClick = (event, trigger) => {
    //console.log("Attempting to open collection menu")
    event.preventDefault();
    event.stopPropagation();
    //console.log({trigger})
    //console.log(event.currentTarget)
    if (trigger && trigger == "rightclick") {
      //console.log("setting click pos", event.clientX, event.clientY)
      setClickPosition({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }
    setAnchorEl(event.currentTarget);
    setSelected(true);
  };
  const handleClose = (event, keepSelected) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    //console.log("Attempting to CLOSE collection menu", keepSelected)
    if (keepSelected === true) {
      //setSelected(true)
      //keep selected
    } else {
      setSelected(false);
    }
    setAnchorEl(null);
    setClickPosition(initialState);
  };

  //MOVING COLLECTION
  const [selectWorkspaceAnchorEl, setSelectWorkspaceAnchorEl] = React.useState(null); //the anchor for the workspace select menu
  let selectWorkspaceOpen = Boolean(selectWorkspaceAnchorEl);
  const handleOpenWorkspaceMenu = (event) => {
    setSelectWorkspaceAnchorEl(anchorEl);
    //handleClose(event, true)
  };

  const handleCloseMoveCollectionMenu = (event) => {
    //console.log("CLOSE the move collection menu")
    // event.preventDefault()
    // event.stopPropagation()
    handleClose(event, true);
    setSelectWorkspaceAnchorEl(null);

    setSelected(false);
    //if(handleCloseDialog) handleCloseDialog()
  };

  const handleMoveCollection = (event, workspace) => {
    //console.log({event, workspace, index})
    const data = {
      collectionId: collection._id,
      workspaceId: workspace._id,
    };

    // update the local data immediately, but disable the revalidation
    mutate(
      [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
      async (collectionsData) => {
        // filter the list, and return it without the moved collection
        if (collectionsData && collectionsData.collections) {
          const filteredCollections = collectionsData.collections.filter((c) => c._id !== collection._id);
          return { ...collectionsData, collections: filteredCollections };
        } else {
          return collectionsData;
        }
      },
      false
    );

    // mutate([`/collections/getbyid`, globalState.activeWorkspace._id, collection && collection.urlId ? collection.urlId : null], async collectionData => {
    //   console.log(collectionData.collection.workspace)
    //   const updatedCollection = update(collectionData, {$set: {...collection, workspace: workspace._id} })
    //   console.log(updatedCollection.workspace)
    //   return {...collectionData, collection: updatedCollection}
    // }, false)

    // send a request to the API to update the source
    axios
      .post("/collections/move", data)
      .then((result) => {
        // trigger a revalidation (refetch) to make sure our local data is correct
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        mutate(`/workspaces`);
        //mutate([`/collections/getbyid`, globalState.activeWorkspace._id, collection && collection.urlId ? collection.urlId : null])
        handleClose(event);
        // handleCloseMoveCollectionMenu(event)

        // if(props.inCollectionView){
        //   history.push(`/${workspace._id}/${collection.urlId}`)
        // }
        if (result && result.data) {
          let message = "Moved Collection";
          enqueueSnackbar(message, {
            content: (key, message) => <PlainSnackBar id={key} link={LinkToCollection} message={message} showLinkToButton />,
          });
        }
      })
      .catch((err) => {
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        // mutate([`/collections/getbyid`, globalState.activeWorkspace._id, collection && collection.urlId ? collection.urlId : null])
        console.log(err);
      });
  };

  const openDelete = () => {
    //onsole.log("Attempting to open collection delete")
    setSelected(true);
    setDeleteAlertOpen(true);
  };
  const closeDelete = () => {
    //console.log("Attempting to CLOSE collection delete")
    setSelected(false);
    setDeleteAlertOpen(false);
  };

  const handleOpenTabs = (event, type) => {
    event.stopPropagation();
    event.preventDefault();
    //console.log(collection)
    const workspace = globalState.workspaces.find((w) => w._id === collection.workspace);
    //console.log(workspace)
    if (globalState.extensionConnection.isConnected) {
      var shiftKey = event.shiftKey ? true : false;
      globalState.extensionConnection.port.postMessage({
        type: "openCollection",
        shiftKey: shiftKey,
        newtabs: collection.tabs,
        openType: type,
        windowId: globalState.extensionConnection.windowId,
        tabId: globalState.extensionConnection.tabId,
        collection: collection,
        workspace: workspace,
      });
    }
  };

  const [saveCloseLoading, setSaveCloseLoading] = useState({
    saveAndClose: false,
    saveOnly: false,
    closeOnly: false,
  });

  const handleSaveAndCloseCollection = async (event, saveCollection = true, closeCollection = true) => {
    event.stopPropagation();
    event.preventDefault();

    // Determine which operation is being performed
    const operationType = saveCollection && closeCollection ? "saveAndClose" : saveCollection ? "saveOnly" : "closeOnly";

    // Set loading state for specific button
    setSaveCloseLoading((prev) => ({
      ...prev,
      [operationType]: true,
    }));

    if (globalState.extensionConnection.isConnected) {
      try {
        await globalState.extensionConnection.port.postMessage({
          type: "saveAndCloseCollection",
          windowId: collectionInSync.windowId,
          saveCollection: saveCollection,
          closeCollection: closeCollection,
        });

        // Add small delay before revalidation for better UX
        await new Promise((resolve) => setTimeout(resolve, 300));

        // Trigger revalidation
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
      } catch (error) {
        console.error("Error saving/closing collection:", error);
      } finally {
        // Reset loading state
        setSaveCloseLoading((prev) => ({
          ...prev,
          [operationType]: false,
        }));
      }
    }
  };

  //HOVER
  const [hoverRef, hover] = useHoverGroup();

  //DRAG PREVIEW LINES

  const collectionOrderInColumn =
    kanbanCollectionOrder &&
    kanbanGroup &&
    kanbanCollectionOrder.find((column) => column && !!column.propertyValueId && column.propertyValueId === kanbanGroup._id)
      ? kanbanCollectionOrder.find((column) => column && !!column.propertyValueId && column.propertyValueId === kanbanGroup._id).order
      : null;
  const prevCardId =
    collectionOrderInColumn && index > 0
      ? collectionOrderInColumn[index - 1]
      : collectionsOrder && index > 0 && collectionsOrder[index - 1]
        ? collectionsOrder[index - 1]._id
        : null; //NEED TO ADJUST FOR KANBAN (order is different)
  const nextCardId =
    collectionOrderInColumn && index < collectionsOrder.length
      ? collectionOrderInColumn[index + 1]
      : collectionsOrder && index < collectionsOrder.length && collectionsOrder[index + 1]
        ? collectionsOrder[index + 1]._id
        : null;
  const isDraggingPrevCard = !!prevCardId && dragActive == prevCardId;
  const isDraggingNextCard = !!nextCardId && dragActive == nextCardId;

  //&& isOver or dragMousePosition.insertIndex === index
  const showPreviewLineOnLeft = !!dragMousePosition && isOver && !isDragging && !isDraggingPrevCard && dragMousePosition.left;
  const showPreviewLineOnRight = !!dragMousePosition && isOver && !isDragging && !isDraggingNextCard && dragMousePosition.right;
  const showPreviewLineBelow =
    viewType == "Kanban"
      ? kanbanGroup && dragMousePosition && dragMousePosition.insertColumnId == kanbanGroup._id
        ? !!dragMousePosition && isOver && !isDragging && !isDraggingNextCard && dragMousePosition.below
        : !!dragMousePosition && isOver && !isDragging && dragMousePosition.below
      : !!dragMousePosition && isOver && !isDragging && !isDraggingNextCard && dragMousePosition.below;
  const showPreviewLineAbove =
    viewType == "Kanban"
      ? kanbanGroup && dragMousePosition && dragMousePosition.insertColumnId == kanbanGroup._id
        ? !!dragMousePosition && isOver && !isDragging && !isDraggingPrevCard && dragMousePosition.above
        : !!dragMousePosition && isOver && !isDragging && dragMousePosition.above
      : !!dragMousePosition && isOver && !isDragging && !isDraggingPrevCard && dragMousePosition.above;

  const dropTabOnSameCollection =
    currentlyDraggedItem && currentlyDraggedItem.collectionId && currentlyDraggedItem.collectionId === collection._id ? true : false;

  function mergeRefs(refs) {
    return (value) => {
      refs.forEach((ref) => {
        if (typeof ref === "function") {
          ref(value);
        } else if (ref != null) {
          ref.current = value;
        }
      });
    };
  }

  //TOOLTIPS
  const [connectionAndNoTabsTooltipOpen, setConnectionAndNoTabsTooltipOpen] = useState(false);
  const handleCloseConnectionAndNoTabsTooltip = () => {
    setConnectionAndNoTabsTooltipOpen(false);
  };
  const handleOpenConnectionAndNoTabsTooltip = () => {
    setConnectionAndNoTabsTooltipOpen(true);
  };

  const [addTabsTooltipOpen, setAddTabsTooltipOpen] = useState(false);
  const handleCloseAddTabsTooltiop = () => {
    setAddTabsTooltipOpen(false);
  };
  const handleOpenAddTabsTooltiop = () => {
    setAddTabsTooltipOpen(true);
  };

  const [moreToolTipOpen, setMoreToolTipOpen] = useState(false);
  const handleCloseMoreTooltiop = () => {
    setMoreToolTipOpen(false);
  };
  const handleOpenMoreTooltiop = () => {
    setMoreToolTipOpen(true);
  };

  const [autoUpdateTooltipOpen, setAutoUpdateToolTipOpen] = useState(false);

  const handleCloseAutoUpdateTooltip = () => {
    setAutoUpdateToolTipOpen(false);
  };

  const handleOpenAutoUpdateTooltip = () => {
    setAutoUpdateToolTipOpen(true);
  };

  //BUTTONS
  const collectionActionButtons = [
    <Tooltip
      aria-label="add"
      classes={{ tooltip: { maxWidth: 213 } }}
      disableFocusListener
      disableInteractive
      enterDelay={1000}
      title={
        !globalState.extensionConnection.isConnected ? (
          ""
        ) : collection.tabs && collection.tabs.length < 1 ? (
          "No tabs in this collection"
        ) : (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              Open tabs in current window{" "}
              <Typography
                style={{
                  fontSize: 10,
                  marginTop: 2,
                  display: "flex",
                  alignItems: "center",
                }}
                variant="caption"
              >
                Hold
                <HotkeyChip dense keys={["SHIFT"]} />
                to open in new window
              </Typography>
            </div>
          </React.Fragment>
        )
      }
    >
      <Button
        aria-label="addtowindow"
        disabled={(collection && collection.tabs && collection.tabs.length < 1) || !globalState.extensionConnection.isConnected}
        onClick={(e) => handleOpenTabs(e, "add")}
        startIcon={<Tab fontSize="inherit" />}
      >
        Open
      </Button>
    </Tooltip>,
    <Tooltip
      aria-label="open"
      classes={{ tooltip: { maxWidth: 213 } }}
      disableFocusListener
      disableInteractive
      title={
        !globalState.extensionConnection.isConnected || dragActive
          ? ""
          : collection.tabs && collection.tabs.length < 1
            ? "No tabs in this collection"
            : "Close current tabs and open these"
      }
    >
      <Button
        aria-label="Switch to"
        disabled={(collection && collection.tabs && collection.tabs.length < 1) || !globalState.extensionConnection.isConnected}
        onClick={(e) => handleOpenTabs(e, "replace")}
        startIcon={<Repeat fontSize="inherit" />}
      >
        Switch to
      </Button>
    </Tooltip>,
  ];

  const PaddingFooter = () => {
    return (
      <div
        style={{
          width: "100%",
          minWidth: "100%",
          minHeight: 4, //just need enough so that the last tab's margin bottom kicks in
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "end",
          // paddingBottom: 12,
          // backgroundColor: "red",
        }}
      />
    );
  };
  const PaddingHeader = () => {
    return (
      <div
        style={{
          width: "100%",
          minWidth: "100%",
          minHeight: 4, //just need enough so that the last tab's margin bottom kicks in
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "end",
          // paddingBottom: 12,
          // backgroundColor: "red",
        }}
      />
    );
  };
  // const CustomScrollBar = React.forwardRef(({ style, ...props }, ref) => {
  //   // an alternative option to assign the ref is
  //   // <div ref={(r) => ref.current = r}>
  //   return (
  //     <div
  //       ref={ref}
  //       style={{
  //         ...style,
  //         border: "5px solid gray",
  //         backgroundColor: "red",
  //         "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
  //           backgroundColor: "transparent",
  //         },
  //       }}
  //       {...props}
  //       id="tabpreview"
  //     />
  //   );
  // });

  if (viewType == "Full") {
    return (
      <Grid item style={{ maxWidth: "100%", width: "100%", position: "relative" }}>
        {showPreviewLineAbove && (
          <div
            style={{
              height: 4,
              marginLeft: 4,
              backgroundColor: "#3D5AFE",
              width: "calc(100% - 8px)",
              position: "absolute",
              left: 0,
              top: -6,
              opacity: 0.25,
            }}
          />
        )}
        <div
          ref={ref}
          style={{
            zIndex: isDragging ? 1 : 0,
            position: "relative",
            scale: isDragging ? 1.03 : 1,
            width: "100%",
            maxWidth: "100%",
            borderRadius: 4,
            height: "100%",
            marginBottom: collectionExpanded ? 16 : 8,
            padding: 0,
          }}
        >
          <div
            style={{
              border: isOver && !dragActive && !dropTabOnSameCollection ? "2px solid rgba(61, 90, 254, 1)" : "2px solid transparent",
              borderRadius: 4,
            }}
          >
            <Accordion
              expanded={collectionExpanded}
              ref={hoverRef}
              style={{
                opacity,
                maxWidth: "100%",
                width: "100%",
                // border: '1px solid rgb(196, 196, 196, 0.45)',
                border: `1px solid ${theme.palette.divider}`,
                boxShadow: theme.palette.shadows.small,
                borderRadius: 3,

                maxHeight: "100%",
                //minHeight: cardMinHeight,
                backgroundColor: theme.palette.collection.default,
                padding: 0,
                margin: 0,
                //transition: 'background-color 0.05s linear 0.05s',
                display: "flex",
                flexDirection: (tabPreview && cardTitlePosition === "bottom") || (tabPreview && cardTitlePosition == null) ? "column-reverse" : "column",
              }}
            >
              <div
                onClick={handleCollectionClick}
                onContextMenu={(e) => handleClick(e, "rightclick")}
                ref={dragRef}
                style={{
                  padding: 0,
                  cursor: "pointer",
                  borderRadius: 3,
                  width: "100%",
                  height: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  position: "relative",
                  backgroundColor: selected
                    ? theme.palette.collection.selected
                    : isDragging || (hover && dragActive === false)
                      ? theme.palette.collection.hover
                      : theme.palette.collection.default,
                }}
              >
                <div
                  //className="CollectionOptionsIcon"
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    zIndex: 9999,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: 6,
                    backgroundColor: hover && dragActive === false ? theme.palette.collection.hover : "transparent",
                  }}
                >
                  <div
                    style={{
                      opacity: hover && dragActive === false ? 1 : 0,
                      position: "relative",
                      boxShadow: theme.palette.shadows.medium,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: 4,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip
                      disableFocusListener
                      disableInteractive
                      enterDelay={1000}
                      onClose={handleCloseConnectionAndNoTabsTooltip}
                      onOpen={handleOpenConnectionAndNoTabsTooltip}
                      open={!collectionMenuOpen && !addTabsTooltipOpen && !moreToolTipOpen && !autoUpdateTooltipOpen && connectionAndNoTabsTooltipOpen}
                      title={
                        globalState.extensionConnection.isConnected
                          ? collection.tabs && collection.tabs.length < 1
                            ? "No tabs in this collection"
                            : ""
                          : "Connect to the Partizion extension to use this feature"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          backgroundColor: theme.palette.background.paper, //"#FFF",
                          borderRadius: 4,
                        }}
                      >
                        <ButtonGroup color="neutral" size="small" variant="outlined">
                          {collectionCurrentlyOpen ? (
                            <>
                              <Button
                                aria-label="saveandclose"
                                disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.saveAndClose}
                                onClick={(e) => handleSaveAndCloseCollection(e, true, true)}
                              >
                                {saveCloseLoading.saveAndClose ? (
                                  <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} />
                                ) : null}
                                Save and Close
                              </Button>
                              <Button
                                aria-label="save"
                                disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.saveOnly}
                                onClick={(e) => handleSaveAndCloseCollection(e, true, false)}
                              >
                                {saveCloseLoading.saveOnly ? (
                                  <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} />
                                ) : null}
                                Save
                              </Button>
                              <Button
                                aria-label="close"
                                disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.closeOnly}
                                onClick={(e) => handleSaveAndCloseCollection(e, false, true)}
                              >
                                {saveCloseLoading.closeOnly ? (
                                  <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} />
                                ) : null}
                                Close
                              </Button>
                            </>
                          ) : (
                            <>
                              <Tooltip
                                aria-label="add"
                                classes={{ tooltip: { maxWidth: 213 } }}
                                disableFocusListener
                                disableInteractive
                                enterDelay={1000}
                                title={
                                  <React.Fragment>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      Open tabs in current window{" "}
                                      <Typography
                                        style={{
                                          fontSize: 10,
                                          marginTop: 2,
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        variant="caption"
                                      >
                                        Hold
                                        <HotkeyChip dense keys={["SHIFT"]} />
                                        to open in new window
                                      </Typography>
                                    </div>
                                  </React.Fragment>
                                }
                              >
                                <Button
                                  aria-label="addtowindow"
                                  disabled={(collection && collection.tabs && collection.tabs.length < 1) || !globalState.extensionConnection.isConnected}
                                  onClick={(e) => handleOpenTabs(e, "add")}
                                  startIcon={<Tab fontSize="inherit" />}
                                >
                                  Open
                                </Button>
                              </Tooltip>
                              <Tooltip
                                aria-label="open"
                                classes={{ tooltip: { maxWidth: 213 } }}
                                disableFocusListener
                                disableInteractive
                                title={
                                  !globalState.extensionConnection.isConnected || dragActive
                                    ? ""
                                    : collection.tabs && collection.tabs.length < 1
                                      ? "No tabs in this collection"
                                      : "Close current tabs and open these"
                                }
                              >
                                <Button
                                  aria-label="Switch to"
                                  disabled={(collection && collection.tabs && collection.tabs.length < 1) || !globalState.extensionConnection.isConnected}
                                  onClick={(e) => handleOpenTabs(e, "replace")}
                                  startIcon={<Repeat fontSize="inherit" />}
                                >
                                  Switch to
                                </Button>
                              </Tooltip>
                            </>
                          )}
                          <Tooltip
                            open={autoUpdateTooltipOpen}
                            onClose={handleCloseAutoUpdateTooltip}
                            onOpen={handleOpenAutoUpdateTooltip}
                            title={
                              <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                                <div>
                                  Auto-update is {collection && collection?.autoUpdateTabs ? "ON" : "OFF"}
                                  .
                                  <br />
                                  <span style={{ color: "rgba(255,255,255, 0.5)" }}>
                                    {collection && collection?.autoUpdateTabs ? "Click to Disable" : "Click to Enable"}.
                                  </span>{" "}
                                  <br />
                                </div>
                                <InfoIconButton href="https://www.partizion.io/guide/auto-updating-collections" size="small" target="_blank">
                                  <HelpOutlineRoundedIcon />
                                </InfoIconButton>
                              </div>
                            }
                          >
                            <Button
                              disabled={!globalState.extensionConnection.isConnected}
                              onClick={(e) => handleToggleAutoUpdateTabs(e, collection)}
                              startIcon={<BoltRoundedIcon style={{ marginRight: -5 }} />}
                              variant={collection && collection?.autoUpdateTabs ? "autoupdateenabled" : "outlined"}
                            />
                          </Tooltip>
                          {!collectionCurrentlyOpen && (
                            <Tooltip
                              disableInteractive
                              enterDelay={500}
                              onClose={handleCloseAddTabsTooltiop}
                              onOpen={handleOpenAddTabsTooltiop}
                              open={addTabsTooltipOpen}
                              title={
                                !globalState.extensionConnection.isConnected || dragActive ? "Connect to the extension to add tabs" : "Add tabs to collection"
                              }
                            >
                              <Button
                                disabled={!globalState.extensionConnection.isConnected || dragActive}
                                onClick={(e) => openAddTabsDialog(e, collection)}
                                startIcon={<AddRoundedIcon style={{ marginRight: -5 }} />}
                              />
                            </Tooltip>
                          )}
                          <Tooltip
                            disableInteractive
                            enterDelay={500}
                            onClose={handleCloseMoreTooltiop}
                            onOpen={handleOpenMoreTooltiop}
                            open={moreToolTipOpen}
                            title="Rename,  duplicate, and more"
                          >
                            <Button onClick={handleClick} startIcon={<MoreHorizRoundedIcon style={{ marginRight: -5 }} />} />
                          </Tooltip>
                        </ButtonGroup>
                      </div>
                    </Tooltip>
                  </div>
                </div>

                <div
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    // borderBottom: tabPreview ? '1px solid rgb(196, 196, 196, 0.45)' : null,
                    borderTop:
                      (collectionExpanded && tabPreview && cardTitlePosition === "bottom") || (collectionExpanded && tabPreview && cardTitlePosition == null)
                        ? `1px solid ${theme.palette.divider}`
                        : null,
                    borderBottom: collectionExpanded && tabPreview && cardTitlePosition === "top" ? `1px solid ${theme.palette.divider}` : null,
                    height: "100%",
                    //minHeight: '100%',
                    maxHeight: "100%",

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    maxWidth: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      width: "inherit",
                      maxWidth: "inherit",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <IconButton
                        disableFocusRipple
                        onClick={(e) => toggleCollectionExpanded(e, collection._id, !collectionExpanded)}
                        size="small"
                        style={{
                          marginRight: 6,
                          borderRadius: 4,
                          padding: 4,
                          cursor: "pointer",
                        }}
                      >
                        <ExpandMoreIcon
                          style={{
                            transform: collectionExpanded ? "rotate(180deg)" : "none",
                            transition: "all 0.2s ease-out",
                          }}
                        />
                      </IconButton>
                      <Typography
                        noWrap
                        style={{
                          fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                          color: theme.palette.text.primary,
                          fontWeight: 600,
                          maxWidth: "95%",
                          marginLeft: 2,
                          marginRight: 6,
                        }}
                        variant="body1"
                      >
                        {name}
                      </Typography>
                      {collectionCurrentlyOpen && <CurrentlyOpenBadge collectionInSync={collectionInSync} />}
                    </div>

                    {showCreatedOn ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          //width: '100%',
                          marginTop: 2,
                        }}
                      >
                        <Typography
                          noWrap
                          style={{
                            color: theme.palette.text.primary,
                            fontSize: 12,
                            width: "100%",
                            textAlign: "left",
                            fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                          }}
                          variant="body2"
                        >
                          <span style={{ color: theme.palette.text.secondary }}>Created</span> {createdOnString}
                        </Typography>
                      </div>
                    ) : null}
                    {showLastUpdated ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          //width: '100%',
                          marginTop: 2,
                        }}
                      >
                        <Typography
                          noWrap
                          style={{
                            color: theme.palette.text.primary,
                            fontSize: 12,
                            width: "100%",
                            textAlign: "left",
                            fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                          }}
                          variant="body2"
                        >
                          <span style={{ color: theme.palette.text.secondary }}>Edited</span> {lastUpdatedString}
                        </Typography>
                      </div>
                    ) : null}
                    {globalState.activeWorkspace &&
                    globalState.activeWorkspace.collectionProperties &&
                    globalState.activeWorkspace.collectionProperties.length > 0
                      ? globalState.activeWorkspace.collectionProperties.map((property, index) => {
                          if (showCustomProperties[property._id] == false) {
                            return null;
                          } else {
                            return <CollectionPropertyDiv collection={collection} key={property._id} property={property} />;
                          }
                        })
                      : null}
                  </div>

                  {showTabCount && collection.tabs ? (
                    <div
                      style={{
                        display: "flex",

                        flexDirection: "row",
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: "16px",
                        marginBottom: 0,
                      }}
                    >
                      <Typography
                        style={{
                          color: theme.palette.text.secondary,
                          fontSize: 12,
                          width: "25%",
                          textAlign: "left",
                          fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                          fontWeight: 500,
                        }}
                        variant="body2"
                      >
                        {collection.tabs.length} tab
                        {collection.tabs.length > 1 ? "s" : collection.tabs.length < 1 ? "s" : null}
                      </Typography>
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  height: 10,
                  backgroundColor: hover ? theme.palette.collection.bgDark : theme.palette.collection.bgLight,
                }}
              />
              <AccordionDetails
                style={{
                  height: "auto",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: selected ? theme.palette.collection.selected : hover ? theme.palette.collection.bgDark : theme.palette.collection.bgLight,
                }}
              >
                <TabsGrid
                  collection={collection}
                  collectionCurrentlyOpen={collectionCurrentlyOpen}
                  handleAddSnackbarToStack={handleAddSnackbarToStack}
                  inAccordian
                  openAddTabsDialog={openAddTabsDialog}
                  tabs={collection.tabs}
                  tabsLayout={tabsLayout}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        {showPreviewLineBelow && (
          <div
            style={{
              height: 4,
              marginLeft: 4,
              backgroundColor: "#3D5AFE",
              width: "calc(100% - 8px)",
              position: "absolute",
              left: 0,
              bottom: 2,
              opacity: 0.25,
            }}
          />
        )}
        {collectionMenuOpen ? (
          <CollectionMenu
            activeWorkspace={activeWorkspace}
            anchor={anchorEl}
            clickPosition={clickPosition}
            collection={collection}
            handleClick={handleClick}
            handleClickSelectWorkspace={handleClickSelectWorkspace}
            handleClose={handleClose}
            handleCloseMoveCollection={handleCloseMoveCollectionMenu}
            handleMoveCollection={handleMoveCollection}
            handleOpenWorkspaceMenu={handleOpenWorkspaceMenu}
            key={selectWorkspaceOpen}
            moveCollectionAnchorEl={selectWorkspaceAnchorEl}
            //for move workpspace
            moveWorkspaceOpen={selectWorkspaceOpen}
            openDelete={openDelete}
            setSelected={overrideSelected}
          />
        ) : null}
        {deleteAlertOpen ? (
          <DeleteCollectionDialogue
            collection={collection}
            handleClose={closeDelete}
            handleOpen={openDelete}
            open={deleteAlertOpen}
            workspace={activeWorkspace}
          />
        ) : null}
        {reorderAlert ? (
          <AlertDialog confirm={ConfirmMoveCollections} handleClose={handleCloseReorderAlert} open={reorderAlert} state={alertReorderState} />
        ) : null}
      </Grid>
    );
  } else if (viewType == "List") {
    return (
      <Grid
        item
        ref={ref}
        style={{
          minWidth: "100%",
          maxWidth: "100%",
          width: "100%",
          padding: 0,
          marginBottom: 0,
          borderRadius: isOver && !dragActive ? 6 : 4,
          height: 45,

          position: "relative",
        }}
      >
        {showPreviewLineAbove && (
          <div
            style={{
              height: 4,
              marginLeft: 4,
              backgroundColor: "#3D5AFE",
              width: "calc(100% - 8px)",
              position: "absolute",
              left: 0,
              top: -4,
              opacity: 0.5,
            }}
          />
        )}
        <div
          onClick={handleCollectionClick}
          onContextMenu={(e) => handleClick(e, "rightclick")}
          ref={mergeRefs([hoverRef, dragRef])}
          style={{
            opacity,
            border: isOver && !dragActive && !dropTabOnSameCollection ? "2px solid rgba(61, 90, 254, 1)" : "2px solid transparent",
            borderRadius: 4,
            cursor: "pointer",
            //height: '100%',
            backgroundColor: selected
              ? theme.palette.collection.selected
              : isDragging || (hover && dragActive === false)
                ? theme.palette.collection.hover
                : theme.palette.collection.default,
            marginBottom: 2,
            height: "100%",
          }}
        >
          <div
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 4,
              paddingBottom: 4,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 6, width: "35%", maxWidth: "35%" }}>
              <Typography
                noWrap
                style={{
                  fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                  color: theme.palette.text.primary,
                  fontWeight: 600,
                  maxWidth: "95%",
                  fontSize: 14,
                }}
                variant="body1"
              >
                {name}
              </Typography>
              {collectionCurrentlyOpen && <CurrentlyOpenBadge collectionInSync={collectionInSync} />}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "65%",
                maxWidth: "65%",
                overflow: "hidden",
                height: "100%",
              }}
            >
              {showCreatedOn ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    //width: '100%',
                    marginTop: 2,
                  }}
                >
                  <Typography
                    noWrap
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: 12,
                      textAlign: "left",
                      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                    }}
                    variant="body2"
                  >
                    <span style={{ color: theme.palette.text.secondary }}>Created</span> {createdOnString}
                  </Typography>
                </div>
              ) : null}
              {showLastUpdated ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    //width: '100%',
                    marginTop: 2,
                  }}
                >
                  <Typography
                    noWrap
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: 12,
                      textAlign: "left",
                      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      marginLeft: 16,
                    }}
                    variant="body2"
                  >
                    <span style={{ color: theme.palette.text.secondary }}>Edited</span> {lastUpdatedString}
                  </Typography>
                </div>
              ) : null}
              {showTabCount && collection.tabs ? (
                <Typography
                  noWrap
                  style={{
                    color: theme.palette.text.secondary,
                    fontSize: 12,
                    textAlign: "left",
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 500,
                    marginLeft: 16,
                    marginBottom: 0,
                    minWidth: 50,
                  }}
                  variant="body2"
                >
                  {collection.tabs.length} tab
                  {collection.tabs.length > 1 ? "s" : collection.tabs.length < 1 ? "s" : null}
                </Typography>
              ) : null}
              {globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0
                ? globalState.activeWorkspace.collectionProperties.map((property, index) => {
                    if (showCustomProperties[property._id] == false) {
                      return null;
                    } else {
                      return <CollectionPropertyDiv collection={collection} inList key={property._id} property={property} style={{ marginLeft: 16 }} />;
                    }
                  })
                : null}
              {hover || collectionMenuOpen ? (
                <div
                  style={{
                    opacity: hover && dragActive === false ? 1 : 0,
                    position: "relative",
                    // boxShadow: theme.palette.shadows.medium,
                    // backgroundColor: theme.palette.background.paper,
                    // borderRadius: 4,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Tooltip
                    disableFocusListener
                    disableInteractive
                    enterDelay={1000}
                    onClose={handleCloseConnectionAndNoTabsTooltip}
                    onOpen={handleOpenConnectionAndNoTabsTooltip}
                    open={!collectionMenuOpen && !addTabsTooltipOpen && !moreToolTipOpen && !autoUpdateTooltipOpen && connectionAndNoTabsTooltipOpen}
                    title={
                      globalState.extensionConnection.isConnected
                        ? collection.tabs && collection.tabs.length < 1
                          ? "No tabs in this collection"
                          : ""
                        : "Connect to the Partizion extension to use this feature"
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        backgroundColor: theme.palette.background.paper, //"#FFF",
                        borderRadius: 4,
                      }}
                    >
                      <ButtonGroup color="neutral" size="small" variant="outlined">
                        {collectionCurrentlyOpen ? (
                          <>
                            <Button
                              aria-label="saveandclose"
                              disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.saveAndClose}
                              onClick={(e) => handleSaveAndCloseCollection(e, true, true)}
                            >
                              {saveCloseLoading.saveAndClose ? (
                                <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} />
                              ) : null}
                              Save and Close
                            </Button>
                            <Button
                              aria-label="save"
                              disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.saveOnly}
                              onClick={(e) => handleSaveAndCloseCollection(e, true, false)}
                            >
                              {saveCloseLoading.saveOnly ? (
                                <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} />
                              ) : null}
                              Save
                            </Button>
                            <Button
                              aria-label="close"
                              disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.closeOnly}
                              onClick={(e) => handleSaveAndCloseCollection(e, false, true)}
                            >
                              {saveCloseLoading.closeOnly ? (
                                <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} />
                              ) : null}
                              Close
                            </Button>
                          </>
                        ) : (
                          <>
                            <Tooltip
                              aria-label="add"
                              classes={{ tooltip: { maxWidth: 213 } }}
                              disableFocusListener
                              disableInteractive
                              enterDelay={1000}
                              title={
                                <React.Fragment>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    Open tabs in current window{" "}
                                    <Typography
                                      style={{
                                        fontSize: 10,
                                        marginTop: 2,
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      variant="caption"
                                    >
                                      Hold
                                      <HotkeyChip dense keys={["SHIFT"]} />
                                      to open in new window
                                    </Typography>
                                  </div>
                                </React.Fragment>
                              }
                            >
                              <Button
                                aria-label="addtowindow"
                                disabled={(collection && collection.tabs && collection.tabs.length < 1) || !globalState.extensionConnection.isConnected}
                                onClick={(e) => handleOpenTabs(e, "add")}
                                startIcon={<Tab fontSize="inherit" />}
                              >
                                Open
                              </Button>
                            </Tooltip>
                            <Tooltip
                              aria-label="open"
                              classes={{ tooltip: { maxWidth: 213 } }}
                              disableFocusListener
                              disableInteractive
                              title={
                                !globalState.extensionConnection.isConnected || dragActive
                                  ? ""
                                  : collection.tabs && collection.tabs.length < 1
                                    ? "No tabs in this collection"
                                    : "Close current tabs and open these"
                              }
                            >
                              <Button
                                aria-label="Switch to"
                                disabled={(collection && collection.tabs && collection.tabs.length < 1) || !globalState.extensionConnection.isConnected}
                                onClick={(e) => handleOpenTabs(e, "replace")}
                                startIcon={<Repeat fontSize="inherit" />}
                              >
                                Switch to
                              </Button>
                            </Tooltip>
                          </>
                        )}

                        <Tooltip
                          open={autoUpdateTooltipOpen}
                          onClose={handleCloseAutoUpdateTooltip}
                          onOpen={handleOpenAutoUpdateTooltip}
                          title={
                            <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                              <div>
                                Auto-update is {collection && collection.autoUpdateTabs ? "ON" : "OFF"}
                                .
                                <br />
                                <span style={{ color: "rgba(255,255,255, 0.5)" }}>
                                  {collection && collection.autoUpdateTabs ? "Click to Disable" : "Click to Enable"}.
                                </span>{" "}
                                <br />
                              </div>
                              <InfoIconButton href="https://www.partizion.io/guide/auto-updating-collections" size="small" target="_blank">
                                <HelpOutlineRoundedIcon />
                              </InfoIconButton>
                            </div>
                          }
                        >
                          <Button
                            disabled={!globalState.extensionConnection.isConnected}
                            onClick={(e) => handleToggleAutoUpdateTabs(e, collection)}
                            startIcon={<BoltRoundedIcon style={{ marginRight: -5 }} />}
                            variant={collection && collection.autoUpdateTabs ? "autoupdateenabled" : "outlined"}
                          />
                        </Tooltip>
                        {!collectionCurrentlyOpen && (
                          <Tooltip
                            disableInteractive
                            enterDelay={500}
                            onClose={handleCloseAddTabsTooltiop}
                            onOpen={handleOpenAddTabsTooltiop}
                            open={addTabsTooltipOpen}
                            title={
                              !globalState.extensionConnection.isConnected || dragActive ? "Connect to the extension to add tabs" : "Add tabs to collection"
                            }
                          >
                            <Button
                              disabled={!globalState.extensionConnection.isConnected || dragActive}
                              onClick={(e) => openAddTabsDialog(e, collection)}
                              startIcon={<AddRoundedIcon style={{ marginRight: -5 }} />}
                            />
                          </Tooltip>
                        )}
                        <Tooltip
                          disableInteractive
                          enterDelay={500}
                          onClose={handleCloseMoreTooltiop}
                          onOpen={handleOpenMoreTooltiop}
                          open={moreToolTipOpen}
                          title="Rename,  duplicate, and more"
                        >
                          <Button onClick={handleClick} startIcon={<MoreHorizRoundedIcon style={{ marginRight: -5 }} />} />
                        </Tooltip>
                      </ButtonGroup>
                    </div>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {showPreviewLineBelow && (
          <div
            style={{
              height: 4,
              marginLeft: 4,
              backgroundColor: "#3D5AFE",
              width: "calc(100% - 8px)",
              position: "absolute",
              left: 0,
              opacity: 0.5,
            }}
          />
        )}
        {collectionMenuOpen ? (
          <CollectionMenu
            activeWorkspace={activeWorkspace}
            anchor={anchorEl}
            clickPosition={clickPosition}
            collection={collection}
            collectionCurrentlyOpen={collectionCurrentlyOpen}
            handleClick={handleClick}
            handleClickSelectWorkspace={handleClickSelectWorkspace}
            handleClose={handleClose}
            handleCloseMoveCollection={handleCloseMoveCollectionMenu}
            handleMoveCollection={handleMoveCollection}
            handleOpenWorkspaceMenu={handleOpenWorkspaceMenu}
            key={selectWorkspaceOpen}
            moveCollectionAnchorEl={selectWorkspaceAnchorEl}
            //for move workpspace
            moveWorkspaceOpen={selectWorkspaceOpen}
            openDelete={openDelete}
            setSelected={overrideSelected}
          />
        ) : null}
        {deleteAlertOpen ? (
          <DeleteCollectionDialogue
            collection={collection}
            handleClose={closeDelete}
            handleOpen={openDelete}
            open={deleteAlertOpen}
            workspace={activeWorkspace}
          />
        ) : null}
        {reorderAlert ? (
          <AlertDialog confirm={ConfirmMoveCollections} handleClose={handleCloseReorderAlert} open={reorderAlert} state={alertReorderState} />
        ) : null}
      </Grid>
    );
  } else if (viewType == "Kanban") {
    return (
      // <Grid item style={{padding: 4, minWidth: cardMinWidth, maxWidth: cardSizePercentage, width: cardSizePercentage, height: '1fr', minHeight: '100%', border: 'solid', flexGrow: 1}}>
      <div style={{ width: "100%", marginBottom: 8, position: "relative" }}>
        {showPreviewLineAbove && (
          <div
            style={{
              height: 4,
              marginLeft: 4,
              backgroundColor: "#3D5AFE",
              width: "calc(100% - 8px)",
              position: "absolute",
              left: 0,
              top: -4,
              opacity: 0.5,
            }}
          />
        )}
        <div
          ref={ref}
          //removed motion
          style={{
            zIndex: isDragging ? 1 : 0,
            position: "relative",
            scale: isDragging ? 1.03 : 1,
            borderRadius: isOver && !dragActive ? 6 : 4,
            border: isOver && !dragActive && !dropTabOnSameCollection ? "2px solid rgba(61, 90, 254, 1)" : "2px solid transparent",
            height: "100%",
            // minHeight: '100%',
            padding: 0,
            overflow: "hidden",
          }}
        >
          <div
            //className={classes.regularHover}
            onClick={handleCollectionClick} //border: '1px solid rgb(196, 196, 196, 0.35)'
            onContextMenu={(e) => handleClick(e, "rightclick")}
            ref={hoverRef}
            style={{
              opacity,
              // border: '1px solid rgb(196, 196, 196, 0.45)',
              border: collectionCurrentlyOpen ? `1px solid #27CA40` : `1px solid ${theme.palette.divider}`,
              boxShadow: theme.palette.shadows.small,
              borderRadius: 4,
              cursor: "pointer",
              height: "100%",
              overflow: "hidden",
              //minHeight: '100%',
              backgroundColor: selected
                ? theme.palette.collection.selected
                : isDragging || (hover && dragActive === false)
                  ? theme.palette.collection.hover
                  : theme.palette.collection.default,
              //transition: 'background-color 0.05s linear 0.05s',
              display: "flex",
              flexDirection: (tabPreview && cardTitlePosition === "bottom") || (tabPreview && cardTitlePosition == null) ? "column" : "column-reverse",
            }}
          >
            <div
              //className="CollectionOptionsIcon"
              style={{
                opacity: hover && dragActive === false ? 1 : 0,
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 9999,
                backgroundColor: theme.palette.background.paper,
                borderRadius: 4,
                // paddingBottom: 1,
                boxShadow: theme.palette.shadows.medium,
              }}
            >
              <ButtonGroup color="neutral" size="small" variant="outlined">
                {!collectionCurrentlyOpen && (
                  <IconButton
                    onClick={(e) => openAddTabsDialog(e, collection)}
                    sx={{ opacity: !globalState.extensionConnection.isConnected || dragActive ? 0.5 : 1 }}
                  >
                    <Tooltip
                      disableInteractive
                      enterDelay={500}
                      title={!globalState.extensionConnection.isConnected || dragActive ? "Connect to the extension to add tabs" : "Add tabs to collection"}
                    >
                      <AddRoundedIcon />
                    </Tooltip>
                  </IconButton>
                )}
                <IconButton onClick={handleClick} style={collectionCurrentlyOpen ? { borderRadius: 4 } : {}}>
                  <Tooltip disableInteractive enterDelay={500} placement="bottom-end" title="Rename, duplicate, and more">
                    <MoreHorizRoundedIcon />
                  </Tooltip>
                </IconButton>
              </ButtonGroup>
            </div>
            {tabPreview ? (
              <div
                style={{
                  height: previewMax ? (previewMax === "max" ? (maxTabsInList >= 1 ? maxTabsInList * 50 + 14 : 75) : parseInt(previewMax) * 50 + 14) : 164,
                  backgroundColor: selected ? theme.palette.collection.selected : hover ? theme.palette.collection.bgDark : theme.palette.collection.bgLight,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {collectionCurrentlyOpen && (
                  <div style={{ position: "absolute", left: 0, top: 0, zIndex: 9999 }}>
                    <CurrentlyOpenBadge collectionInSync={collectionInSync} variant="onTabsPreview" />
                  </div>
                )}
                {displayTabs.length > 0 ? (
                  <div
                    id="tabpreview"
                    style={{
                      paddingTop: 0, //4,
                      paddingBottom: 0,
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  >
                    <Virtuoso
                      components={{
                        Footer: PaddingFooter,
                        Header: PaddingHeader,
                      }}
                      data={displayTabs}
                      itemContent={(index, tab) => (
                        <CollectionTab
                          allTabs={displayTabs}
                          collection={collection}
                          collectionCurrentlyOpen={collectionCurrentlyOpen}
                          collectionInSync={collectionInSync}
                          in="collection"
                          index={index}
                          isCreateCollection={false}
                          key={tab._id}
                          overscane={200}
                          previewMax={previewMax}
                          tab={tab}
                        />
                      )}
                      style={{
                        // border: "1px solid blue",
                        height: previewMax
                          ? previewMax === "max"
                            ? maxTabsInList >= 1
                              ? maxTabsInList * 50 + 14
                              : 75
                            : parseInt(previewMax) * 50 + 14
                          : 164,
                        overflowY: "overlay",
                        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                          backgroundColor: "red", //"transparent", //"#7BFD15", //
                          borderLeft: `1px solid #E0E0E0`,
                        },
                        "*::-webkit-scrollbar": {
                          backgroundColor: "red", //"transparent", //"#7BFD15", //
                          borderLeft: `1px solid #E0E0E0`,
                        },
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={EmptyCollection}
                      style={{
                        width: previewMax && previewMax == 1 ? "5%" : "10%",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                    />
                    <Typography style={{ color: "#BFBFBF" }} variant="body2">
                      {" "}
                      No tabs in this collection{" "}
                    </Typography>
                  </div>
                )}
              </div>
            ) : null}
            <div
              ref={dragRef}
              style={{
                padding: 10,
                borderTop:
                  (tabPreview && cardTitlePosition === "bottom") || (tabPreview && cardTitlePosition == null) ? `1px solid ${theme.palette.divider}` : null,
                borderBottom: tabPreview && cardTitlePosition === "top" ? `1px solid ${theme.palette.divider}` : null,
                height: tabPreview
                  ? `calc(100% - ${
                      previewMax ? (previewMax === "max" ? (maxTabsInList >= 1 ? maxTabsInList * 50 + 14 : 75) : parseInt(previewMax) * 50 + 14) : 164
                    })`
                  : "100%",
                // minHeight: '100%',
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography
                  noWrap
                  style={{
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                    color: theme.palette.text.primary,
                    fontWeight: 600,
                    maxWidth: "98%",
                    marginBottom: 6,
                  }}
                  variant="body1"
                >
                  {name}
                </Typography>
                {showCreatedOn ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      //width: '100%',
                      marginTop: 2,
                    }}
                  >
                    <Typography
                      noWrap
                      style={{
                        color: theme.palette.text.primary,
                        fontSize: 12,
                        width: "100%",
                        textAlign: "left",
                        fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                      }}
                      variant="body2"
                    >
                      <span style={{ color: theme.palette.text.secondary }}>Created</span> {createdOnString}
                    </Typography>
                  </div>
                ) : null}
                {showLastUpdated ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      //width: '100%',
                      marginTop: 2,
                    }}
                  >
                    <Typography
                      noWrap
                      style={{
                        color: theme.palette.text.primary,
                        fontSize: 12,
                        width: "100%",
                        textAlign: "left",
                        fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                      }}
                      variant="body2"
                    >
                      <span style={{ color: theme.palette.text.secondary }}>Edited</span> {lastUpdatedString}
                    </Typography>
                  </div>
                ) : null}
                {globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0
                  ? globalState.activeWorkspace.collectionProperties.map((property, index) => {
                      if (showCustomProperties[property._id] == false) {
                        return null;
                      } else {
                        return <CollectionPropertyDiv collection={collection} key={property._id} property={property} />;
                      }
                    })
                  : null}
              </div>
              <div
                style={{
                  display: "flex",

                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "16px",
                  marginBottom: 0,
                  position: "relative",
                }}
              >
                {showTabCount && collection.tabs ? (
                  <Typography
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                      width: "25%",
                      textAlign: "left",
                      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                      fontWeight: 500,
                    }}
                    variant="body2"
                  >
                    {collection.tabs.length} tab
                    {collection.tabs.length > 1 ? "s" : collection.tabs.length < 1 ? "s" : null}
                  </Typography>
                ) : null}
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flexGrow: 1,
                    position: "absolute",
                    right: 0,
                  }}
                >
                  <div
                    style={{
                      opacity: hover && dragActive === false ? 1 : 0,
                      zIndex: 9999,
                      boxShadow: theme.palette.shadows.medium,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: 4,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip
                      disableFocusListener
                      disableInteractive
                      enterDelay={1000}
                      title={
                        globalState.extensionConnection.isConnected
                          ? collection.tabs && collection.tabs.length < 1
                            ? "No tabs in this collection"
                            : ""
                          : "Connect to the Partizion extension to use this feature"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <ButtonGroup color="neutral" size="small" variant="outlined">
                          {collectionCurrentlyOpen ? (
                            <>
                              <Button
                                aria-label="saveandclose"
                                disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.saveAndClose}
                                onClick={(e) => handleSaveAndCloseCollection(e, true, true)}
                              >
                                {saveCloseLoading.saveAndClose ? (
                                  <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} />
                                ) : null}
                                Save and Close
                              </Button>
                              <Button
                                aria-label="save"
                                disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.saveOnly}
                                onClick={(e) => handleSaveAndCloseCollection(e, true, false)}
                              >
                                {saveCloseLoading.saveOnly ? (
                                  <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} />
                                ) : null}
                                Save
                              </Button>
                              <Button
                                aria-label="close"
                                disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.closeOnly}
                                onClick={(e) => handleSaveAndCloseCollection(e, false, true)}
                              >
                                {saveCloseLoading.closeOnly ? (
                                  <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} />
                                ) : null}
                                Close
                              </Button>
                            </>
                          ) : (
                            collectionActionButtons
                          )}
                          <Tooltip
                            title={
                              <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                                <div>
                                  Auto-update is {collection && collection.autoUpdateTabs ? "ON" : "OFF"}
                                  .
                                  <br />
                                  <span style={{ color: "rgba(255,255,255, 0.5)" }}>
                                    {collection && collection?.autoUpdateTabs ? "Click to Disable" : "Click to Enable"}.
                                  </span>{" "}
                                  <br />
                                </div>
                                <InfoIconButton href="https://www.partizion.io/guide/auto-updating-collections" size="small" target="_blank">
                                  <HelpOutlineRoundedIcon />
                                </InfoIconButton>
                              </div>
                            }
                          >
                            <Button
                              disabled={!globalState.extensionConnection.isConnected}
                              onClick={(e) => handleToggleAutoUpdateTabs(e, collection)}
                              startIcon={<BoltRoundedIcon style={{ marginRight: -5 }} />}
                              variant={collection && collection.autoUpdateTabs ? "autoupdateenabled" : "outlined"}
                            />
                          </Tooltip>
                        </ButtonGroup>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPreviewLineBelow && (
          <div
            style={{
              height: 4,
              marginLeft: 4,
              backgroundColor: "#3D5AFE",
              width: "calc(100% - 8px)",
              position: "absolute",
              left: 0,
              opacity: 0.5,
            }}
          />
        )}

        {collectionMenuOpen ? (
          <CollectionMenu
            activeWorkspace={activeWorkspace}
            anchor={anchorEl}
            clickPosition={clickPosition}
            collection={collection}
            collectionCurrentlyOpen={collectionCurrentlyOpen}
            handleClick={handleClick}
            handleClickSelectWorkspace={handleClickSelectWorkspace}
            handleClose={handleClose}
            handleCloseMoveCollection={handleCloseMoveCollectionMenu}
            handleMoveCollection={handleMoveCollection}
            handleOpenWorkspaceMenu={handleOpenWorkspaceMenu}
            key={selectWorkspaceOpen}
            moveCollectionAnchorEl={selectWorkspaceAnchorEl}
            //for move workpspace
            moveWorkspaceOpen={selectWorkspaceOpen}
            openDelete={openDelete}
            setSelected={overrideSelected}
          />
        ) : null}
        {deleteAlertOpen ? (
          <DeleteCollectionDialogue
            collection={collection}
            handleClose={closeDelete}
            handleOpen={openDelete}
            open={deleteAlertOpen}
            workspace={activeWorkspace}
          />
        ) : null}
        {reorderAlert ? (
          <AlertDialog confirm={ConfirmMoveCollections} handleClose={handleCloseReorderAlert} open={reorderAlert} state={alertReorderState} />
        ) : null}
      </div>
    );
  } else {
    return (
      // <Grid item style={{padding: 4, minWidth: cardMinWidth, maxWidth: cardSizePercentage, width: cardSizePercentage, height: '1fr', minHeight: '100%', border: 'solid', flexGrow: 1}}>
      <>
        <div
          style={{
            padding: 4,
            minWidth: cardMinWidth,
            maxWidth: cardSizePercentage,
            width: cardSizePercentage,
            height: "1fr",
            //minHeight: cardMinHeight,
            flexGrow: 1,
            position: "relative",
            //border: 'solid'
          }}
        >
          {showPreviewLineOnLeft && (
            <div
              style={{
                height: "calc(100% - 16px)",
                marginTop: 4,
                backgroundColor: "#3D5AFE",
                width: 4,
                position: "absolute",
                top: 4,
                left: -2,
                opacity: 0.5,
              }}
            />
          )}
          <div
            ref={ref}
            style={{
              zIndex: isDragging ? 1 : 0,
              position: "relative",
              scale: isDragging ? 1.03 : 1,
              borderRadius: 6,
              border: isOver && !dragActive && !dropTabOnSameCollection ? "2px solid rgba(61, 90, 254, 1)" : "2px solid transparent",
              height: "100%",
              // minHeight: '100%',
              marginBottom: 0,
              padding: 0,
            }}
          >
            <div
              onContextMenu={(e) => handleClick(e, "rightclick")}
              //border: '1px solid rgb(196, 196, 196, 0.35)'
              ref={hoverRef}
              style={{
                opacity,
                // border: '1px solid rgb(196, 196, 196, 0.45)',
                border: collectionCurrentlyOpen ? `1px solid #27CA40` : `1px solid ${theme.palette.divider}`,
                boxShadow: theme.palette.shadows.small,
                borderRadius: 4,
                height: "100%",
                overflow: "hidden",
                backgroundColor: selected
                  ? theme.palette.collection.selected
                  : (hover && dragActive === false) || isDragging
                    ? theme.palette.collection.hover
                    : theme.palette.collection.default,
                //minHeight: '100%',

                //transition: 'background-color 0.05s linear 0.05s',
                display: "flex",
                flexDirection: (tabPreview && cardTitlePosition === "bottom") || (tabPreview && cardTitlePosition == null) ? "column" : "column-reverse",
              }}
            >
              <div
                //className="CollectionOptionsIcon"
                style={{
                  opacity: hover && dragActive === false ? 1 : 0,
                  position: "absolute",
                  top: 10,
                  right: 10,
                  zIndex: 9999,
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 4,
                  // paddingBottom: 1,
                  boxShadow: theme.palette.shadows.medium,
                }}
              >
                <ButtonGroup color="neutral" size="small" variant="outlined">
                  {!collectionCurrentlyOpen && (
                    <IconButton
                      onClick={(e) => openAddTabsDialog(e, collection)}
                      sx={{ opacity: !globalState.extensionConnection.isConnected || dragActive ? 0.5 : 1 }}
                    >
                      <Tooltip
                        disableInteractive
                        enterDelay={500}
                        title={!globalState.extensionConnection.isConnected || dragActive ? "Connect to the extension to add tabs" : "Add tabs to collection"}
                      >
                        <AddRoundedIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                  <IconButton onClick={handleClick} style={collectionCurrentlyOpen ? { borderRadius: 4 } : {}}>
                    <Tooltip disableInteractive enterDelay={500} placement="bottom-end" title="Rename, duplicate, and more">
                      <MoreHorizRoundedIcon />
                    </Tooltip>
                  </IconButton>
                </ButtonGroup>
              </div>
              {tabPreview ? (
                <div
                  style={{
                    height: previewMax ? (previewMax === "max" ? (maxTabsInList >= 1 ? maxTabsInList * 50 + 14 : 75) : parseInt(previewMax) * 50 + 14) : 164,
                    backgroundColor: selected ? theme.palette.collection.selected : hover ? theme.palette.collection.bgDark : theme.palette.collection.bgLight,
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  {collectionCurrentlyOpen && (
                    <div style={{ position: "absolute", left: -1, top: -1, zIndex: 9999 }}>
                      <CurrentlyOpenBadge collectionInSync={collectionInSync} variant="onTabsPreview" />
                    </div>
                  )}
                  {displayTabs.length > 0 ? (
                    <div
                      id="tabpreview"
                      style={{
                        paddingTop: 0, //4,
                        paddingBottom: 0,
                        paddingLeft: 8,
                        paddingRight: 8,
                      }}
                    >
                      <Virtuoso
                        components={{
                          Footer: PaddingFooter,
                          Header: PaddingHeader,
                        }}
                        data={displayTabs}
                        itemContent={(index, tab) => (
                          <CollectionTab
                            allTabs={displayTabs}
                            collection={collection}
                            collectionCurrentlyOpen={collectionCurrentlyOpen}
                            collectionInSync={collectionInSync}
                            in="collection"
                            index={index}
                            isCreateCollection={false}
                            key={tab._id}
                            overscane={200}
                            previewMax={previewMax}
                            tab={tab}
                          />
                        )}
                        style={{
                          // border: "1px solid blue",
                          height: previewMax
                            ? previewMax === "max"
                              ? maxTabsInList >= 1
                                ? maxTabsInList * 50 + 14
                                : 75
                              : parseInt(previewMax) * 50 + 14
                            : 164,
                          overflowY: "overlay",
                          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                            backgroundColor: "red", //"transparent", //"#7BFD15", //
                            borderLeft: `1px solid #E0E0E0`,
                          },
                          "*::-webkit-scrollbar": {
                            backgroundColor: "red", //"transparent", //"#7BFD15", //
                            borderLeft: `1px solid #E0E0E0`,
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={EmptyCollection}
                        style={{
                          width: previewMax && previewMax == 1 ? "5%" : "10%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      />
                      <Typography style={{ color: "#BFBFBF" }} variant="body2">
                        {" "}
                        No tabs in this collection{" "}
                      </Typography>
                    </div>
                  )}
                </div>
              ) : null}
              <div
                onClick={handleCollectionClick}
                ref={dragRef}
                style={{
                  padding: 10,
                  borderTop:
                    (tabPreview && cardTitlePosition === "bottom") || (tabPreview && cardTitlePosition == null) ? `1px solid ${theme.palette.divider}` : null,
                  borderBottom: tabPreview && cardTitlePosition === "top" ? `1px solid ${theme.palette.divider}` : null,
                  //height: tabPreview ? `calc(100% - ${previewMax ? previewMax === "max" ? maxTabsInList >= 1 ? (maxTabsInList * 50) + 12  : 75 : (parseInt(previewMax) * 50) + 12 : 150})` : '100%',
                  //height: '100%',
                  flexGrow: 1,
                  // minHeight: '100%',
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div>
                  <Typography
                    noWrap
                    style={{
                      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                      color: theme.palette.text.primary,
                      fontWeight: 600,
                      maxWidth: "98%",
                      marginBottom: 6,
                    }}
                    variant="body1"
                  >
                    {name}
                  </Typography>
                  {showCreatedOn ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        //width: '100%',
                        marginTop: 2,
                      }}
                    >
                      <Typography
                        noWrap
                        style={{
                          color: theme.palette.text.primary,
                          fontSize: 12,
                          width: "100%",
                          textAlign: "left",
                          fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                          fontWeight: 400,
                        }}
                        variant="body2"
                      >
                        <span style={{ color: theme.palette.text.secondary }}>Created</span> {createdOnString}
                      </Typography>
                    </div>
                  ) : null}
                  {showLastUpdated ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        //width: '100%',
                        marginTop: 2,
                      }}
                    >
                      <Typography
                        noWrap
                        style={{
                          color: theme.palette.text.primary,
                          fontSize: 12,
                          width: "100%",
                          textAlign: "left",
                          fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                          fontWeight: 400,
                        }}
                        variant="body2"
                      >
                        <span style={{ color: theme.palette.text.secondary }}>Edited</span> {lastUpdatedString}
                      </Typography>
                    </div>
                  ) : null}
                  {globalState.activeWorkspace &&
                  globalState.activeWorkspace.collectionProperties &&
                  globalState.activeWorkspace.collectionProperties.length > 0
                    ? globalState.activeWorkspace.collectionProperties.map((property, index) => {
                        if (showCustomProperties[property._id] == false) {
                          return null;
                        } else {
                          return <CollectionPropertyDiv collection={collection} key={property._id} property={property} />;
                        }
                      })
                    : null}
                </div>
                <div
                  style={{
                    display: "flex",

                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "16px",
                    marginBottom: 0,
                    position: "relative",
                  }}
                >
                  {showTabCount && collection.tabs ? (
                    <Typography
                      style={{
                        color: theme.palette.text.secondary,
                        fontSize: 12,
                        width: "25%",
                        textAlign: "left",
                        fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        fontWeight: 500,
                      }}
                      variant="body2"
                    >
                      {collection.tabs.length} tab
                      {collection.tabs.length > 1 ? "s" : collection.tabs.length < 1 ? "s" : null}
                    </Typography>
                  ) : null}
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    <div
                      style={{
                        opacity: hover && dragActive === false ? 1 : 0,
                        zIndex: 9999,
                        // boxShadow: theme.palette.shadows.medium,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: 4,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Tooltip
                        disableFocusListener
                        disableInteractive
                        enterDelay={1000}
                        title={
                          globalState.extensionConnection.isConnected
                            ? collection.tabs && collection.tabs.length < 1
                              ? "No tabs in this collection"
                              : ""
                            : "Connect to the Partizion extension to use this feature"
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <ButtonGroup color="neutral" size="small" variant="outlined">
                            {collectionCurrentlyOpen ? (
                              <>
                                <Button
                                  aria-label="saveandclose"
                                  disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.saveAndClose}
                                  onClick={(e) => handleSaveAndCloseCollection(e, true, true)}
                                >
                                  {saveCloseLoading.saveAndClose ? (
                                    <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} />
                                  ) : null}
                                  Save and Close
                                </Button>
                                <Button
                                  aria-label="save"
                                  disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.saveOnly}
                                  onClick={(e) => handleSaveAndCloseCollection(e, true, false)}
                                >
                                  {saveCloseLoading.saveOnly ? (
                                    <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} />
                                  ) : null}
                                  Save
                                </Button>
                                <Button
                                  aria-label="close"
                                  disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.closeOnly}
                                  onClick={(e) => handleSaveAndCloseCollection(e, false, true)}
                                >
                                  {saveCloseLoading.closeOnly ? (
                                    <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} />
                                  ) : null}
                                  Close
                                </Button>
                              </>
                            ) : (
                              <>
                                <Tooltip
                                  aria-label="add"
                                  classes={{ tooltip: { maxWidth: 213 } }}
                                  disableFocusListener
                                  disableInteractive
                                  enterDelay={1000}
                                  title={
                                    !globalState.extensionConnection.isConnected ? (
                                      ""
                                    ) : collection.tabs && collection.tabs.length < 1 ? (
                                      "No tabs in this collection"
                                    ) : (
                                      <React.Fragment>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          Open tabs in current window{" "}
                                          <Typography
                                            style={{
                                              fontSize: 10,
                                              marginTop: 2,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                            variant="caption"
                                          >
                                            Hold
                                            <HotkeyChip dense keys={["SHIFT"]} />
                                            to open in new window
                                          </Typography>
                                        </div>
                                      </React.Fragment>
                                    )
                                  }
                                >
                                  <Button
                                    aria-label="addtowindow"
                                    disabled={(collection && collection.tabs && collection.tabs.length < 1) || !globalState.extensionConnection.isConnected}
                                    onClick={(e) => handleOpenTabs(e, "add")}
                                    startIcon={<Tab fontSize="inherit" />}
                                  >
                                    Open
                                  </Button>
                                </Tooltip>
                                <Tooltip
                                  aria-label="open"
                                  classes={{ tooltip: { maxWidth: 213 } }}
                                  disableFocusListener
                                  disableInteractive
                                  title={
                                    !globalState.extensionConnection.isConnected || dragActive
                                      ? ""
                                      : collection.tabs && collection.tabs.length < 1
                                        ? "No tabs in this collection"
                                        : "Close current tabs and open these"
                                  }
                                >
                                  <Button
                                    aria-label="Switch to"
                                    disabled={(collection && collection.tabs && collection.tabs.length < 1) || !globalState.extensionConnection.isConnected}
                                    onClick={(e) => handleOpenTabs(e, "replace")}
                                    startIcon={<Repeat fontSize="inherit" />}
                                  >
                                    Switch to
                                  </Button>
                                </Tooltip>
                              </>
                            )}

                            <Tooltip
                              title={
                                <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                                  <div>
                                    Auto-update is {collection && collection.autoUpdateTabs ? "ON" : "OFF"}
                                    .
                                    <br />
                                    <span style={{ color: "rgba(255,255,255, 0.5)" }}>
                                      {collection && collection.autoUpdateTabs ? "Click to Disable" : "Click to Enable"}.
                                    </span>
                                    <br />
                                  </div>
                                  <InfoIconButton href="https://www.partizion.io/guide/auto-updating-collections" size="small" target="_blank">
                                    <HelpOutlineRoundedIcon />
                                  </InfoIconButton>
                                </div>
                              }
                            >
                              <Button
                                disabled={!globalState.extensionConnection.isConnected}
                                onClick={(e) => handleToggleAutoUpdateTabs(e, collection)}
                                startIcon={<BoltRoundedIcon style={{ marginRight: -5 }} />}
                                variant={collection && collection.autoUpdateTabs ? "autoupdateenabled" : "outlined"}
                              />
                            </Tooltip>
                          </ButtonGroup>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showPreviewLineOnRight && (
            <div
              style={{
                height: "calc(100% - 16px)",
                marginTop: 4,
                backgroundColor: "#3D5AFE",
                width: 4,
                position: "absolute",
                top: 4,
                right: -2,
                opacity: 0.5,
              }}
            />
          )}
          {collectionMenuOpen ? (
            <CollectionMenu
              activeWorkspace={activeWorkspace}
              anchor={anchorEl}
              clickPosition={clickPosition}
              collection={collection}
              collectionCurrentlyOpen={collectionCurrentlyOpen}
              handleClick={handleClick}
              handleClickSelectWorkspace={handleClickSelectWorkspace}
              handleClose={handleClose}
              handleCloseMoveCollection={handleCloseMoveCollectionMenu}
              handleMoveCollection={handleMoveCollection}
              handleOpenWorkspaceMenu={handleOpenWorkspaceMenu}
              key={selectWorkspaceOpen}
              //for move workpspace
              moveCollectionAnchorEl={selectWorkspaceAnchorEl}
              moveWorkspaceOpen={selectWorkspaceOpen}
              openDelete={openDelete}
              setSelected={overrideSelected}
            />
          ) : null}
          {deleteAlertOpen ? (
            <DeleteCollectionDialogue
              collection={collection}
              handleClose={closeDelete}
              handleOpen={openDelete}
              open={deleteAlertOpen}
              workspace={activeWorkspace}
            />
          ) : null}
          {reorderAlert ? (
            <AlertDialog confirm={ConfirmMoveCollections} handleClose={handleCloseReorderAlert} open={reorderAlert} state={alertReorderState} />
          ) : null}
        </div>
      </>
    );
  }
};
export default React.memo(CollectionComponent);
