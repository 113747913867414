import React, { useState } from "react";
import useGlobal from "../../GlobalState/Store/Store.js";
import update from "immutability-helper";

import useCollectionById from "../../Hooks/useCollectionById.js";

import CollectionMenu from "../Menus/CollectionMenu.js";
import DeleteCollectionDialogue from "../Dialogs/DeleteCollectionDialog.js";

import { useNavigate } from "react-router-dom";

import axios from "axios";
import { mutate } from "swr";

import { useTheme, styled } from "@mui/material/styles";

import { Tooltip, Divider, Typography, IconButton, Button, ButtonGroup, CircularProgress } from "@mui/material";

import MoreVert from "@mui/icons-material/MoreVert";
import HotkeyChip from "../Hotkey/HotkeyChip.js";

import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import RepeatRoundedIcon from "@mui/icons-material/RepeatRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import BoltRoundedIcon from "@mui/icons-material/BoltRounded";
import CurrentlyOpenBadge from "./CurrentlyOpenBadge.js";
import { isCollectionCurrentlyOpen } from "../../Utils/isCollectionCurrentlyOpen.js";
import useEncodeCollectionURL from "../../Hooks/useEncodeCollectionURL.js";

const InfoIconButton = styled(IconButton)(({ theme }) => ({
  // backgroundColor: theme.palette.text.tertiary,
  color: theme.palette.text.secondary,
  padding: 3,
  minHeight: 0,
  fontSize: 12,
  fontWeight: 500,
  marginLeft: 24,

  "&:hover": {
    color: "rgba(255,255,255, 0.6)",
    // color: theme.palette.text.primary,
    backgroundColor: "rgba(255,255,255, 0.15)",
  },
  "&:active": {
    color: "rgba(255,255,255, 0.6)",
    backgroundColor: "rgba(255,255,255, 0.15)",
    // color: theme.palette.text.primary,
  },
}));

function CollectionNavbarButtons(props) {
  const [globalState, globalActions] = useGlobal();
  const theme = useTheme();
  const { collectionData, isLoadingCollection, mutateCollection } = useCollectionById(
    globalState.isAuth,
    // props.workspaceid ? props.workspaceid : null,
    props.collectionid ? props.collectionid : null
  );
  const collection = collectionData && collectionData.collection ? collectionData.collection : { tabs: [] };
  const disabled = collection && collection !== undefined && collection.tabs && collection.tabs.length > 0 ? false : true;

  //AUTO-UPDATE TABS --------
  let { collectionCurrentlyOpen, collectionInSync } = isCollectionCurrentlyOpen(collection?._id);
  // const collectionCurrentlyOpen = isCollectionCurrentlyOpen(collection?._id);

  const { getLinkToCollection } = useEncodeCollectionURL();
  const LinkToCollection = getLinkToCollection(collection?._collectionId, collection?.name);

  const toggleAutoUpdate = async (c) => {
    //this function updates the server / db
    let boolean = !c.autoUpdateTabs;
    let state = "disabled";
    if (boolean) {
      state = "enabled";
    }

    const data = {
      collection: c,
      state: state,
    };
    try {
      const updatedCollection = await axios.post("/collections/toggleautoupdatetabs", data);
      return updatedCollection;
    } catch (error) {
      return error;
      // Handle an error while updating the user here
    }
  };

  const handleToggleAutoUpdateTabs = (event, collection) => {
    //this is the optimisic UI function
    event.preventDefault();
    event.stopPropagation();

    const optimisticState = {
      ...collectionData,
      collection: {
        ...collection,
        autoUpdateTabs: !collection.autoUpdateTabs,
      },
    };

    mutateCollection(toggleAutoUpdate(collection), {
      optimisticData: optimisticState,
      populateCache: false,
    });

    //update the collection sync in the extension
    if (globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port.postMessage({
        type: "toggleAutoUpdateTabs",
        state: !collection.autoUpdateTabs,
        collection: collection,
      });
    }
  };
  //-----------

  let navigate = useNavigate();

  const handleClickSelectWorkspace = props.handleClickSelectWorkspace;
  const handleAddSnackbarToStack = props.handleAddSnackbarToStack;

  const handleOpenTabs = (event, type) => {
    if (globalState.extensionConnection.isConnected) {
      var shiftKey = event.shiftKey ? true : false;
      globalState.extensionConnection.port.postMessage({
        type: "openCollection",
        shiftKey: shiftKey,
        newtabs: collection.tabs,
        openType: type,
        windowId: globalState.extensionConnection.windowId,
        tabId: globalState.extensionConnection.tabId,
        collection: collection,
        workspace: props && props.workspaceid ? props.workspaceid : globalState.activeWorkspace,
      });
    }
  };

  //COllection menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const collectionMenuOpen = Boolean(anchorEl);
  const initialState = {
    mouseX: null,
    mouseY: null,
  };
  const [clickPosition, setClickPosition] = useState(initialState);
  const handleClick = (event, trigger) => {
    //console.log("Attempting to open collection menu")
    event.preventDefault();
    event.stopPropagation();
    //console.log({trigger})
    //console.log(event.currentTarget)
    if (trigger && trigger == "rightclick") {
      //console.log("setting click pos", event.clientX, event.clientY)
      setClickPosition({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event, keepSelected) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorEl(null);
    setClickPosition(initialState);
  };

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const openDelete = () => {
    setDeleteAlertOpen(true);
  };
  const closeDelete = () => {
    setDeleteAlertOpen(false);
  };

  //MOVING COLLECTION
  const [selectWorkspaceAnchorEl, setSelectWorkspaceAnchorEl] = React.useState(null); //the anchor for the workspace select menu
  let selectWorkspaceOpen = Boolean(selectWorkspaceAnchorEl);
  const handleOpenWorkspaceMenu = (event) => {
    setSelectWorkspaceAnchorEl(anchorEl);
    //handleClose(event, true)
  };

  const handleCloseMoveCollectionMenu = (event) => {
    //console.log("CLOSE the move collection menu")
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleClose(event, true);
    setSelectWorkspaceAnchorEl(null);

    //if(handleCloseDialog) handleCloseDialog()
  };

  const handleMoveCollection = (event, workspace) => {
    //console.log({event, workspace, index})
    const data = {
      collectionId: collection._id,
      workspaceId: workspace._id,
    };

    // update the local data immediately, but disable the revalidation
    mutate(
      [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
      async (collectionsData) => {
        // filter the list, and return it without the moved collection
        if (collectionsData && collectionsData.collections) {
          const filteredCollections = collectionsData.collections.filter((c) => c._id !== collection._id);
          return { ...collectionsData, collections: filteredCollections };
        } else {
          return collectionsData;
        }
      },
      false
    );

    mutate(
      [`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null],
      async (collectionData) => {
        const updatedCollection = update(collectionData, {
          $set: { ...collection, workspace: workspace._id },
        });
        return { ...collectionData, collection: updatedCollection };
      },
      false
    );

    // send a request to the API to update the source
    axios
      .post("/collections/move", data)
      .then(() => {
        // trigger a revalidation (refetch) to make sure our local data is correct
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        mutate(`/workspaces`);
        mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
      })
      .then(() => {
        handleClose(event);
        handleCloseMoveCollectionMenu(event);

        // globalActions.saveActiveWorkspace();
        // globalActions.saveActiveWorkspaceIndex(globalState.activeWorkspaceIndex - 1);
        navigate(LinkToCollection);
      })
      .catch((err) => {
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        console.log(err);
      });
  };

  const [saveCloseLoading, setSaveCloseLoading] = useState({
    saveAndClose: false,
    saveOnly: false,
    closeOnly: false,
  });

  const handleSaveAndCloseCollection = async (event, saveCollection = true, closeCollection = true) => {
    event.stopPropagation();
    event.preventDefault();

    // Determine which operation is being performed
    const operationType = saveCollection && closeCollection ? "saveAndClose" : saveCollection ? "saveOnly" : "closeOnly";

    // Set loading state for specific button
    setSaveCloseLoading((prev) => ({
      ...prev,
      [operationType]: true,
    }));

    if (globalState.extensionConnection.isConnected) {
      try {
        await globalState.extensionConnection.port.postMessage({
          type: "saveAndCloseCollection",
          windowId: collectionInSync.windowId,
          saveCollection: saveCollection,
          closeCollection: closeCollection,
        });

        // Add small delay before revalidation for better UX
        await new Promise((resolve) => setTimeout(resolve, 300));

        // Trigger revalidations
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
      } catch (error) {
        console.error("Error saving/closing collection:", error);
      } finally {
        // Reset loading state
        setSaveCloseLoading((prev) => ({
          ...prev,
          [operationType]: false,
        }));
      }
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
      <Tooltip
        disableFocusListener
        disableInteractive
        enterDelay={1000}
        title={globalState.extensionConnection.isConnected ? "" : "Connect to the Partizion extension to use this feature"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {collectionCurrentlyOpen && <CurrentlyOpenBadge collectionInSync={collectionInSync} />}
          <Tooltip
            title={
              <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                <div>
                  Auto-update is {collection && collection.autoUpdateTabs ? "ON" : "OFF"}
                  .
                  <br />
                  <span style={{ color: "rgba(255,255,255, 0.5)" }}>
                    Click to
                    {collection && collection.autoUpdateTabs ? " Disable" : " Enable"}.
                  </span>{" "}
                  <br />
                </div>
                <InfoIconButton href="https://www.partizion.io/guide/auto-updating-collections" size="small" target="_blank">
                  <HelpOutlineRoundedIcon />
                </InfoIconButton>
              </div>
            }
          >
            <Button
              disabled={!globalState.extensionConnection.isConnected}
              onClick={(e) => handleToggleAutoUpdateTabs(e, collection)}
              size="small"
              startIcon={<BoltRoundedIcon style={{ marginRight: -5 }} />}
              style={{ marginRight: 6 }}
              variant={collection && collection.autoUpdateTabs ? "autoupdateenabled" : "outlined"}
            >
              Auto-Update {collection && collection.autoUpdateTabs ? "ON" : "OFF"}
            </Button>
          </Tooltip>
          {!collectionCurrentlyOpen ? (
            <>
              <Tooltip
                aria-label="add"
                classes={{ tooltip: { maxWidth: 213 } }}
                disableFocusListener
                disableInteractive
                enterDelay={1000}
                title={
                  !globalState.extensionConnection.isConnected ? (
                    "Connect to the extension to open collections"
                  ) : collection && collection.tabs && collection.tabs.length < 1 ? (
                    "No tabs in this collection"
                  ) : (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        Open tabs in current window{" "}
                        <Typography
                          style={{
                            fontSize: 10,
                            marginTop: 2,
                            display: "flex",
                            alignItems: "center",
                          }}
                          variant="caption"
                        >
                          Hold
                          <HotkeyChip dense keys={["SHIFT"]} />
                          to open in new window
                        </Typography>
                      </div>
                    </React.Fragment>
                  )
                }
              >
                <span>
                  <Button
                    disableRipple
                    disabled={(collection && collection.tabs && collection.tabs.length < 1) || !globalState.extensionConnection.isConnected}
                    // endIcon={<HotkeyChip keys={["⌘", "O"]} />}
                    onClick={(e) => handleOpenTabs(e, "add")}
                    size="small"
                    startIcon={<LaunchRoundedIcon />}
                    style={{ marginRight: 6 }}
                    variant="outlined"
                  >
                    Open
                  </Button>
                </span>
              </Tooltip>
              <Tooltip
                aria-label="open"
                classes={{ tooltip: { maxWidth: 213 } }}
                disableFocusListener
                disableInteractive
                enterDelay={1000}
                title={
                  !globalState.extensionConnection.isConnected
                    ? "Connect to the extension to open collections"
                    : collection && collection.tabs && collection.tabs.length < 1
                      ? "No tabs in this collection"
                      : "Close current tabs and open these"
                }
              >
                <span>
                  <Button
                    disableRipple
                    disabled={(collection && collection.tabs && collection.tabs.length < 1) || !globalState.extensionConnection.isConnected}
                    // endIcon={<HotkeyChip keys={["⌘", "O"]} />}
                    onClick={(e) => handleOpenTabs(e, "replace")}
                    size="small"
                    startIcon={<RepeatRoundedIcon />}
                    style={{ marginRight: 6 }}
                    variant="outlined"
                  >
                    Switch to
                  </Button>
                </span>
              </Tooltip>
            </>
          ) : (
            <ButtonGroup color="neutral" size="small" style={{ marginRight: 6 }} variant="outlined">
              <Button
                aria-label="saveandclose"
                disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.saveAndClose}
                onClick={(e) => handleSaveAndCloseCollection(e, true, true)}
              >
                {saveCloseLoading.saveAndClose ? <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} /> : null}
                Save and Close
              </Button>
              <Button
                aria-label="save"
                disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.saveOnly}
                onClick={(e) => handleSaveAndCloseCollection(e, true, false)}
              >
                {saveCloseLoading.saveOnly ? <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} /> : null}
                Save
              </Button>
              <Button
                aria-label="close"
                disabled={!globalState.extensionConnection.isConnected || saveCloseLoading.closeOnly}
                onClick={(e) => handleSaveAndCloseCollection(e, false, true)}
              >
                {saveCloseLoading.closeOnly ? <CircularProgress size={16} style={{ marginRight: 8, color: theme.palette.text.secondary }} /> : null}
                Close
              </Button>
            </ButtonGroup>
          )}
        </div>
      </Tooltip>
      <Tooltip
        disableInteractive
        enterDelay={750}
        placement="bottom-end"
        title={<div style={{ display: "flex", flexDirection: "row" }}>Duplicate, Copy Links, and more</div>}
      >
        <IconButton aria-label="more" onClick={(e) => handleClick(e)} size="small" style={{ marginRight: 8 }} variant="outlined">
          <MoreVert fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Divider flexItem orientation="vertical" style={{ marginRight: 8 }} />
      {collectionMenuOpen ? (
        <CollectionMenu
          activeWorkspace={globalState.activeWorkspace}
          anchor={anchorEl}
          anchorOriginY="bottom"
          clickPosition={clickPosition}
          collection={collection}
          collectionCurrentlyOpen={collectionCurrentlyOpen}
          editNameDisabled
          handleClick={handleClick}
          handleClickSelectWorkspace={handleClickSelectWorkspace}
          handleClose={handleClose}
          handleCloseMoveCollection={handleCloseMoveCollectionMenu}
          handleMoveCollection={handleMoveCollection}
          handleOpenWorkspaceMenu={handleOpenWorkspaceMenu}
          inCollectionView
          moveCollectionAnchorEl={selectWorkspaceAnchorEl}
          moveWorkspaceOpen={selectWorkspaceOpen}
          openDelete={openDelete}
        />
      ) : null}
      {/* {
                  selectWorkspaceOpen ?
                      <MoveCollectionMenu
                          anchorEl={selectWorkspaceAnchorEl}
                          handleClose={handleCloseMoveCollectionMenu}
                          handleMoveCollection={handleMoveCollection}
                          inDialog
                          open={selectWorkspaceOpen}


                      />
                    :
                    null
                } */}
      {deleteAlertOpen ? (
        <DeleteCollectionDialogue
          collection={collection}
          handleClose={closeDelete}
          handleOpen={openDelete}
          open={deleteAlertOpen}
          workspace={globalState.activeWorkspace}
        />
      ) : null}
    </div>
  );
}

export default CollectionNavbarButtons;
