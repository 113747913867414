import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useTheme, styled } from "@mui/material/styles";

import { ListItemIcon, Typography, Divider, InputBase, ListItem, MenuItem, Popover } from "@mui/material";

import { DeleteOutlineRounded, SubdirectoryArrowRightRounded } from "@mui/icons-material";

import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";

// import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import useGlobal from "../../GlobalState/Store/Store.js";

import axios from "axios";

import { DefaultMenu } from "./DefaultMenu";

import useWorkspaces from "../../Hooks/useWorkspaces.js";
import { mutate } from "swr";
import update from "immutability-helper";
import MoveCollectionMenu from "./MoveCollectionMenu.js";
import { useSnackbar } from "notistack";
import PlainSnackBar from "../Notistack/PlainSnackBar.js";
import useEncodeCollectionURL from "../../Hooks/useEncodeCollectionURL.js";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: 24,
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.light}`,
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
    "&:focus": {
      border: `2px solid ${theme.palette.text.tertiary}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
  },
}));

function CollectionMenu(props) {
  const [globalState, globalActions] = useGlobal();
  let navigate = useNavigate();
  const theme = useTheme();

  const { workspacesData } = useWorkspaces(globalState.isAuth, { revalidateOnMount: false });
  const workspaces = workspacesData.workspaces || [];
  const isAbletoMove = workspacesData && workspacesData.workspaces && workspacesData.workspaces.length > 1 ? true : false;

  const { enqueueSnackbar } = useSnackbar();

  //const handleClose = props.handleClose

  const handleCloseMenu = props.handleClose;

  const handleClose = (event) => {
    if (props && props.handleCloseMoveCollection) props.handleCloseMoveCollection(event);
    handleCloseMenu(event);
  };

  const anchorEl = props.anchor;
  const collection = props.collection;
  const openDelete = props.openDelete;
  const setSelected = props.setSelected;
  const handleOpenWorkspaceMenu = props.handleOpenWorkspaceMenu;
  const collectionCurrentlyOpen = props.collectionCurrentlyOpen;

  const { getLinkToCollection } = useEncodeCollectionURL();
  const LinkToCollection = getLinkToCollection(collection?._collectionId, collection?.name);

  //async cleanups on unmounted comp
  let menuMounted = true;

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (menuMounted) {
      if (open) {
        if (inputRef && inputRef.current) inputRef.current.focus();

        setNewCollectionName(collection.name);
      } else {
        setNewCollectionName("");
      }
    }

    return () => {
      //cleanup
      menuMounted = false;
    };
  }, [open]);

  function OpenDeleteMenu(e) {
    if (menuMounted) {
      handleClose(e);
      openDelete();
    }
  }

  const handleDuplicate = (event) => {
    const data = {
      collection: collection,
    };
    handleClose(event);

    // update the local data immediately, but disable the revalidation
    mutate(
      [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
      async (collectionsData) => {
        // filter the list, and return it without the moved collection
        const now = new Date();
        const copiedCollection = { ...collection, name: "Copy of " + collection.name, _id: "duplicating...", createdOn: now, lastEdited: now };
        if (collectionsData && collectionsData.collections) {
          return { ...collectionsData, collections: [...collectionsData.collections, copiedCollection] };
        } else {
          return collectionsData;
        }
      },
      false
    );

    axios
      .post("/collections/duplicate", data)
      .then((res) => {
        if (menuMounted) {
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          //if(props.handleCloseDialog) props.handleCloseDialog()
        }

        if (props.inCollectionView) {
          let createdCollection = res.data.collection;
          //console.log(createdCollection)
          navigate(LinkToCollection);
        }
        //var createdCollection = res.data.collection
        //console.log(res)
      })
      .catch((err) => {
        if (menuMounted) {
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          console.log(err);
        }
      });
  };

  //COPY URL
  const copyURLsToClipBoard = async () => {
    //let urls = ""
    try {
      const urls = collection.tabs.map((t) => t.url).join("\n");
      // console.log(urls)
      await navigator.clipboard.writeText(urls);
      let message = collection.tabs.length > 1 ? `Copied ${collection.tabs.length} links to clipboard` : "Copied 1 link to clipboard";
      const success = () => {
        handleClose();
        enqueueSnackbar(message, {
          content: (key, message) => <PlainSnackBar id={key} message={message} />,
        });
      };
      return success();
      //setCopySuccess('Copied!');
    } catch (err) {
      return false;
      //setCopySuccess('Failed to copy!');
    }
  };

  //CHNAGING COLLECTION NAME
  const [newCollectionName, setNewCollectionName] = useState(collection.name);
  const handleUpdateCollectionName = () => {
    //console.log("You are attempting to change the collection " + name + " to " + newName)
    if (
      newCollectionName == collection.name ||
      newCollectionName == null ||
      newCollectionName == "" ||
      newCollectionName == " " ||
      (newCollectionName?.trim()?.length || 0) <= 0
    ) {
      //console.log("name wasn't different")
      handleClose();
    } else {
      //update the name in local state immediately
      mutate(
        [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
        async (collectionsData) => {
          const updatedCollectionIndex =
            collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id === collection._id) : -1;
          if (updatedCollectionIndex > -1) {
            const updatedCollections = update(collectionsData.collections, { [updatedCollectionIndex]: { $set: { ...collection, name: newCollectionName } } });
            return { ...collectionsData, collections: updatedCollections };
          } else {
            return collectionsData;
          }
        },
        false
      );

      mutate(
        [`/collections/getbyid`, collection && collection._collectionId != null ? collection._collectionId : null],
        async (collectionData) => {
          const updatedCollection = update(collectionData, { $set: { ...collection, name: newCollectionName } });
          return { ...collectionData, collection: updatedCollection };
        },
        false
      );

      axios
        .post("/collections/updatename", { collectionId: collection._id, newName: newCollectionName })
        .then(() => {
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          mutate([`/collections/getbyid`, collection && collection._collectionId != null ? collection._collectionId : null]);
        })
        .catch((err) => {
          console.log(err);
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
        });
    }

    handleClose();
  };
  const handleKeyPressCollectionName = (event) => {
    var charCode = event.which || event.charCode || event.keyCode;

    if (charCode == 13) {
      //console.log("Enter key pressed")
      handleUpdateCollectionName();
      //ChangeTabName(event.target.value)
      event.target.blur(); //callign blur will trigger an update
    } else if (charCode === 27 || charCode === 38 || charCode === 40) {
      // keep current escape, and arrow key behaviour
      event.preventDefault();
    } else {
      //prevent menu from focusing menu items
      event.stopPropagation();
    }
  };

  const handleMenuClosed = (event) => {
    menuMounted = false;
    var charCode = event.which || event.charCode || event.keyCode;
    if (charCode == 27) {
      //ESC Key
      handleClose();
      return;
    }

    if (newCollectionName && newCollectionName != null && newCollectionName != "" && newCollectionName != collection.name) {
      handleUpdateCollectionName();
    } else {
      handleClose();
    }
  };

  const inputRef = React.useRef();
  const popoverActions = React.useRef();

  if (popoverActions.current) {
    popoverActions.current.updatePosition();
  }

  // console.log(props.moveCollectionAnchorEl, props.inDialog, props.handleCloseMoveCollection)

  return (
    <DefaultMenu
      PaperProps={{
        style: {
          marginLeft: props.inDialog ? 12 : 0,
        },
      }}
      action={popoverActions}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: props.anchorOriginY ? props.anchorOriginY : "top", horizontal: "right" }}
      anchorPosition={
        props.clickPosition !== undefined
          ? props.clickPosition.mouseY !== null && props.clickPosition.mouseX !== null
            ? { top: props.clickPosition.mouseY, left: props.clickPosition.mouseX }
            : undefined
          : null
      }
      anchorReference={props.clickPosition !== undefined && props.clickPosition.mouseY !== null ? "anchorPosition" : "anchorEl"}
      disableScrollLock
      id="move-collection-menu"
      onClose={handleMenuClosed}
      open={open}
      transformOrigin={{ vertical: "top", horizontal: props.clickPosition !== undefined && props.clickPosition.mouseY !== null ? "left" : "right" }}
    >
      {props.moveWorkspaceOpen ? (
        <MoveCollectionMenu
          anchorEl={props.moveCollectionAnchorEl}
          handleClose={props.handleCloseMoveCollection}
          handleMoveCollection={props.handleMoveCollection}
          inDialog={props.inDialog}
        />
      ) : (
        <div>
          {!props.editNameDisabled && (
            <div>
              <ListItem
                style={{ marginBottom: -8, paddingBottom: 0 }}
                sx={{
                  outline: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }}
              >
                <Typography style={{ color: "#6E6E6E" }} variant="caption">
                  {" "}
                  Collection Name{" "}
                </Typography>
              </ListItem>
              <ListItem
                //onKeyDown={(e) => e.stopPropagation()}
                style={{ marginBottom: 0, width: 350 }}
                sx={{
                  outline: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }}
              >
                <BootstrapInput
                  autoFocus
                  fullWidth
                  id="collection-name-input"
                  label="name"
                  maxRows={3}
                  multiline
                  onChange={(e) => setNewCollectionName(e.target.value)}
                  onFocus={(e) => e.currentTarget.select()}
                  onKeyDown={handleKeyPressCollectionName}
                  ref={inputRef}
                  size="small"
                  //onBlur={(e) => handleUpdateCollectionName()}
                  value={newCollectionName}
                  variant="outlined"
                />
              </ListItem>

              <Divider style={{ marginBottom: 8 }} />
            </div>
          )}

          <MenuItem
            button
            disabled={collection.tabs && collection.tabs.length ? false : true}
            onClick={(e) => copyURLsToClipBoard(e)}
            style={{ minWidth: 250 }}
          >
            <ListItemIcon>
              <InsertLinkRoundedIcon fontSize="small" style={{ transform: "rotate(-45deg)" }} />
            </ListItemIcon>
            Copy all Links
          </MenuItem>
          <MenuItem button onClick={handleDuplicate}>
            <ListItemIcon>
              <FileCopyOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Duplicate Collection
          </MenuItem>
          <MenuItem button disabled={!isAbletoMove} onClick={handleOpenWorkspaceMenu}>
            <ListItemIcon>
              <SubdirectoryArrowRightRounded fontSize="small" />
            </ListItemIcon>
            Move to
          </MenuItem>

          {!collectionCurrentlyOpen && (
            <>
              <Divider />
              <MenuItem button onClick={OpenDeleteMenu} style={{ color: "#f44336" }}>
                <ListItemIcon>
                  <DeleteOutlineRounded fontSize="small" style={{ color: "#f44336" }} />
                </ListItemIcon>
                Delete Collection
              </MenuItem>
            </>
          )}
        </div>
      )}
    </DefaultMenu>
  );
}

export default CollectionMenu;

{
  /* <MoveCollectionMenu 
anchorEl={props.moveCollectionAnchorEl}              
handleClose={props.handleCloseMoveCollection}
handleMoveCollection={props.handleMoveCollection}
/> */
}
