import React from "react";
import useGlobalHook from "use-global-hook";

import * as actions from "../Actions/";

const initialState = {
  counter: 0,
  tabsMenuOpen: localStorage.getItem("tabsMenuOpen") || true,
  activeWorkspaceIndex: localStorage.getItem("activeWorkspaceIndex") || 0,
  activeWorkspace: {},
  activeView: {
    _id: null,
  },
  isAuth: false,
  allowPrivateRoute: true, //assume true at first, then the call to /user will validate (This is so routing works -> see PrivateRoute)
  user: {},
  userTrial: {},
  workspaces: [],
  selectedTabs: [],
  selectedWorkspace: {},
  collections: [],
  selectedCollection: {},
  isSaveSession: false,
  appLocation: "newtab",
  isDev: false,
  globalSnackbarState: {
    open: false,
  },
  deviceLimiter: {
    status: false,
    os: null,
    browser: null,
    lastLoggedIn: null,
  },
  isRefreshing: false,
  callUser: false,
  emojiPicker: {
    open: false,
    anchor: 0,
    bottomSpace: 0,
    workspace: {},
  },
  commands: [],
  upgradeRef: null,
  loginUserEmail: null,
  extensionConnection: {
    isConnected: false,
    isReconnecting: false,
    port: null,
    windowId: null,
    tabId: null,
  },
  browserWindows: [],
  browserTabs: [],
  browserStorage: {},
  browserCommands: [],
  extensionCurrentCollections: undefined,
};

const useGlobal = useGlobalHook(initialState, actions); //removed React

export default useGlobal;

//example workspaces

// workspaces: [
//     {
//       id: '0123456',
//       name: "Workspace 1",
//       icon: "star",
//       collections: [
//         {
//           collectionName: "Collection with a really long name",
//           id: 0,
//           friendlyUrl: 'collection-with-a-really-long-name',
//           lastEdited: new Date(),
//           tabs: [
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Github",
//               url: 'https://www.github.com',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Asana",
//               url: 'https://www.asana.com/',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://static.npmjs.com/b0f1a8318363185cc2ea6a40ac23eeb2.png'
//             },
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//           ],
//         },
//         {
//           collectionName: "Daily",
//           id: 1,
//           friendlyUrl: 'daily',
//           lastEdited: new Date(),
//           tabs: [
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Github",
//               url: 'https://www.github.com',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Asana",
//               url: 'https://www.asana.com/',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://static.npmjs.com/b0f1a8318363185cc2ea6a40ac23eeb2.png'
//             },
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//           ],
//         },
//         {
//           collectionName: "Small one",
//           id: 2,
//           friendlyUrl: 'small-one',
//           lastEdited: new Date(),
//           tabs: [
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//           ],
//         },
//         {
//           collectionName: "Another row",
//           id: 3,
//           friendlyUrl: 'another-one',
//           lastEdited: new Date(),
//           tabs: [
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//           ],
//         },
//       ]
//     },
//     {
//       id: '0123457',
//       name: "Workspace 2",
//       icon: "add_circle",
//       collections: [
//         {
//           collectionName: "Collection with a really long name",
//           id: 4,
//           friendlyUrl: 'collection-with-a-really-long-name',
//           lastEdited: new Date(),
//           tabs: [
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Github",
//               url: 'https://www.github.com',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Asana",
//               url: 'https://www.asana.com/',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://static.npmjs.com/b0f1a8318363185cc2ea6a40ac23eeb2.png'
//             },
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//           ],
//         }
//       ]
//     },
//     {
//       id: '0123458',
//       name: "School ",
//       icon: "add_circle",
//       collections: [
//         {
//           collectionName: "No urls in collection",
//           id: 0,
//           friendlyUrl: 'no-urls-in-collection',
//           lastEdited: new Date(),
//           tabs: [
//           ],
//         }
//       ]
//     },
//     {
//       id: '0123458',
//       name: "Empty workspace",
//       icon: "add_circle",
//       collections: [
//
//       ]
//     },
//     {
//       id: '0123457',
//       name: "Workspace 2",
//       icon: "add_circle",
//       collections: [
//         {
//           collectionName: "Collection with a really long name",
//           id: 0,
//           friendlyUrl: 'collection-with-a-really-long-name',
//           lastEdited: new Date(),
//           tabs: [
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Github",
//               url: 'https://www.github.com',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Asana",
//               url: 'https://www.asana.com/',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://static.npmjs.com/b0f1a8318363185cc2ea6a40ac23eeb2.png'
//             },
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//           ],
//         }
//       ]
//     },
//     {
//       id: '0123457',
//       name: "Workspace 3",
//       icon: "add_circle",
//       collections: [
//         {
//           collectionName: "Collection with a really long name",
//           id: 0,
//           friendlyUrl: 'collection-with-a-really-long-name',
//           lastEdited: new Date(),
//           tabs: [
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Github",
//               url: 'https://www.github.com',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Asana",
//               url: 'https://www.asana.com/',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://static.npmjs.com/b0f1a8318363185cc2ea6a40ac23eeb2.png'
//             },
//             {
//               title: "Gmail",
//               url: 'https://www.mail.google.com/inbox/john',
//               favIconUrl: 'https://github.githubassets.com/favicon.ico'
//             },
//           ],
//         }
//       ]
//     },
//   ]

// [
//  {
//    _id: "5e1fbc1eeadd19001741056c",
//    name: "School",
//    userId: "5e1faa0faca98c0017f40a55",
//    createdOn: "2020-01-16T01:27:58.350Z"
//  },
//  {
//    _id: "5e1fbdb4eadd19001741056d",
//    name: "Work",
//    userId: "5e1faa0faca98c0017f40a55",
//    createdOn: "2020-01-16T01:34:44.322Z"
//  },
// ]
