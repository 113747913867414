import useGlobal from "../GlobalState/Store/Store";

export const isCollectionCurrentlyOpen = (collectionId) => {
  const [globalState] = useGlobal();
  //   console.log(globalState, collectionId);
  const { extensionCurrentCollections } = globalState;

  const index =
    extensionCurrentCollections && extensionCurrentCollections.length >= 1
      ? extensionCurrentCollections.findIndex((c) => c._id === collectionId && !c.readyToClear && !!c.windowId && c.windowId !== "clear")
      : -1;

  if (extensionCurrentCollections && extensionCurrentCollections.length > 0 && index > -1) {
    let collectionInSync = extensionCurrentCollections[index];
    let collectionCurrentlyOpen = true;
    // return true;
    return {
      collectionCurrentlyOpen,
      collectionInSync,
    };

    //   // it's in the sync, now we check if that winodw is actually open
    //   let collectionInSync = extensionCurrentCollections[index];
    // const windowCurrentlyOpenIndex = browserWindows.findIndex((w) => w.id === collectionInSync.windowId);

    // if (windowCurrentlyOpenIndex > -1) {
    //   console.log(`${collectionInSync?.name} is OPEN in window: ${browserWindows[windowCurrentlyOpenIndex]?.id}`);

    //   let collectionCurrentlyOpen = true;
    //   // return true;
    //   return {
    //     collectionCurrentlyOpen,
    //     collectionInSync,
    //   };
    // } else {
    //   let name = collectionInSync?.name;
    //   console.log(`${name} is IN THE SYNC, but the window (windowId: ${collectionInSync?.windowId}) is NOT OPEN.`);

    //   // let collectionCurrentlyOpen = false;
    //   // collectionInSync = null;
    //   let collectionCurrentlyOpen = true;
    //   return {
    //     collectionCurrentlyOpen,
    //     collectionInSync,
    //   };
    // }
  } else {
    // console.log(collectionId + " not open");
    // return false;
    let collectionCurrentlyOpen = false;
    let collectionInSync = null;
    return {
      collectionCurrentlyOpen,
      collectionInSync,
    };
  }
};
