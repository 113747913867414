import React, { useState, lazy, Suspense } from "react";
//import CssBaseline from '@mui/material/CssBaseline';

import useGlobal from "./GlobalState/Store/Store";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

//MAIN
import MainContainer from "./Dashboard/MainContainer.js";
import Login from "./Authentication/Login.js";
import LoginRedirect from "./Authentication/LoginRedirect.js";
import Signup from "./Authentication/Signup.js";
import PasswordReset from "./Authentication/PasswordReset.js";

import { useCookies } from "react-cookie";

import { SnackbarProvider } from "notistack";
import CodeLoading from "./CodeLoading";
import Checkout from "./Pages/Stripe-Checkout/Checkout.js";

const GettingStarted = lazy(() => import("./GettingStarted/GettingStarted.js"));
const DesignSystem = lazy(() => import("./DesignSystem"));

const SlashNew = lazy(() => import("./Pages/SlashNew/SlashNew.js"));
const TrialEndPage = lazy(() => import("./Pages/TrialEndPage.js"));
const CancelledPage = lazy(() => import("./Pages/CancelledPage.js"));
const UnInstallFeedback = lazy(() => import("./Pages/UnInstallFeedback.js"));
const EmailPreferences = lazy(() => import("./Pages/EmailPreferences.js"));

const UpgradeSuccess = lazy(() => import("./Pages/UpgradeSuccess.js"));

function RequireAuth({ children, redirectTo }) {
  const [globalState, globalActions] = useGlobal();

  //allowing a private route is now assumed to be true and then validated/invalidated by the call to /user
  //globalState.allowPrivateRoute is used
  //isAuth remains the same, and is also validated with the call to /user. It can be used in the same respect as it always has: as a test for if the user is authenticated
  //console.log(globalState.allowPrivateRoute)

  return globalState.allowPrivateRoute ? children : <Navigate replace to="/login" />;
}

function RedirectLogin({ children, redirectTo }) {
  const [globalState, globalActions] = useGlobal();

  return globalState.isAuth ? children : <Navigate replace state={{ referrer: location.pathname }} to="/login-redirect" />;
}

function App(props) {
  const [globalState, globalActions] = useGlobal();
  const [cookies, setCookie, removeCookie] = useCookies(["partizion_token", "partizion_refreshToken"]);
  const [intialActiveWorkspace, setInitialActiveWorkspace] = useState(globalState.activeWorkspace);
  const [initialTabsMenuOpen, setInitialTabsMenuOpen] = useState(globalState.tabsMenuOpen);

  const unpaid = globalState.user.stripeSubscription && globalState.user.stripeSubscription.status === "unpaid";
  const stripeActiveSubscription = globalState.user.stripeSubscription && globalState.user.stripeSubscription.status === "active";
  //"incomplete" -> if a payment fails the user will have a stripe subscription object

  //console.log(globalState.activeWorkspace)
  const trialEndRedirect =
    globalState.userTrial.trialEnded && !globalState.userTrial.subscription && !globalState.userTrial.betaUser && !stripeActiveSubscription && !unpaid
      ? true
      : false;
  const cancelledSubscriptionRedirect =
    (globalState.user.stripeSubscription && globalState.user.stripeSubscription.status === "canceled") ||
    (globalState.user.stripeSubscription && globalState.user.stripeSubscription.status === "cancelled") ||
    (globalState.user.stripeSubscription && globalState.user.stripeSubscription.status === "inactive")
      ? true
      : false;
  //see main container for the redirect to cancelled page

  return (
    <Suspense fallback={<CodeLoading />}>
      <Router>
        <SnackbarProvider
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          autoHideDuration={4000}
          // components={{
          //   plain: PlainSnackBar
          // }}
          disableWindowBlurListener
          maxSnack={1}
        >
          <Routes>
            <Route
              element={
                <RedirectLogin>
                  <SlashNew />
                </RedirectLogin>
              }
              path="/new"
            />
            <Route element={globalState.isAuth ? <Navigate replace to="/" /> : <GettingStarted />} path="/getting-started" />
            <Route element={trialEndRedirect ? <TrialEndPage /> : <Navigate replace to="/" />} path="/trialend" />

            <Route
              element={
                <RedirectLogin>
                  <Checkout />
                </RedirectLogin>
              }
              path="/pricing"
            />

            <Route element={<CancelledPage />} path="/cancelled" />
            <Route element={<UpgradeSuccess />} path="/thank-you" />
            <Route element={<UnInstallFeedback />} path="/ui/feedback" />
            <Route element={<EmailPreferences />} path="/email/preferences" />
            <Route element={<Login />} path="/login/*" />
            <Route element={<LoginRedirect />} path="/login-redirect" />
            <Route element={globalState.isAuth && cookies.partizion_token ? <Navigate replace to="/" /> : <PasswordReset />} path="/forgotpassword" />
            <Route element={globalState.isAuth ? <Navigate replace to="/" /> : <Signup />} path="/signup" />

            <Route element={<DesignSystem />} path="/design-system-123" />

            <Route
              element={
                trialEndRedirect ? (
                  <Navigate replace to="/trialend" />
                ) : cancelledSubscriptionRedirect ? (
                  <Navigate replace to="/cancelled" />
                ) : (
                  <RequireAuth redirectTo="/login">
                    <MainContainer
                      connectionSnackbarOpen={props.connectionSnackbarOpen}
                      handleCloseConnectSnackbar={props.handleCloseConnectSnackbar}
                      initialTabsMenuOpen={initialTabsMenuOpen}
                      intialActiveWorkspace={intialActiveWorkspace}
                      snackbarState={props.snackbarState}
                    />
                  </RequireAuth>
                )
              }
              path="/*"
            />
            <Route
              element={
                trialEndRedirect ? (
                  <Navigate replace to="/trialend" />
                ) : cancelledSubscriptionRedirect ? (
                  <Navigate replace to="/cancelled" />
                ) : (
                  <RequireAuth redirectTo="/login">
                    <MainContainer
                      connectionSnackbarOpen={props.connectionSnackbarOpen}
                      handleCloseConnectSnackbar={props.handleCloseConnectSnackbar}
                      initialTabsMenuOpen={initialTabsMenuOpen}
                      intialActiveWorkspace={intialActiveWorkspace}
                      snackbarState={props.snackbarState}
                    />
                  </RequireAuth>
                )
              }
              path=":workspaceid"
            />
            <Route
              element={
                trialEndRedirect ? (
                  <Navigate replace to="/trialend" />
                ) : cancelledSubscriptionRedirect ? (
                  <Navigate replace to="/cancelled" />
                ) : (
                  <RequireAuth redirectTo="/login">
                    <MainContainer
                      connectionSnackbarOpen={props.connectionSnackbarOpen}
                      handleCloseConnectSnackbar={props.handleCloseConnectSnackbar}
                      initialTabsMenuOpen={initialTabsMenuOpen}
                      intialActiveWorkspace={intialActiveWorkspace}
                      snackbarState={props.snackbarState}
                    />
                  </RequireAuth>
                )
              }
              path="collection/:collectionid"
            />
          </Routes>
        </SnackbarProvider>
      </Router>
    </Suspense>
  );
}

//{globalState.isAuth ? <Redirect to="/" /> : <Login />}

export default App;

//OLD ROUTING STRATEGY -----

// <Route exact path='/'>
//   <Redirect to={`/w/`} />
// </Route>
// <PrivateRoute path="/w">
//   {trialEndRedirect ? <Redirect to="/trialend" /> : <MainContainer intialActiveWorkspace={intialActiveWorkspace} initialTabsMenuOpen={initialTabsMenuOpen} snackbarState={props.snackbarState} connectionSnackbarOpen={props.connectionSnackbarOpen} handleCloseConnectSnackbar={props.handleCloseConnectSnackbar}/>}
// </PrivateRoute>

// <Route path="/login">
//   {globalState.isAuth ? <Redirect to="/" /> : <Login />}
// </Route>

//-----END OLD ROUTING STRATEGY

// <Route path="/onboarding">
//   <Onboarding />
// </Route>

// <RedirectLoginRoute path="/login-extension">
//   <LoginForExtension />
// </RedirectLoginRoute>

// <Route path="/">
//   <Redirect to="/pricing" />
// </Route>

// <Route path="/auth/google">
//   {globalState.isAuth ? <Redirect to="/" /> : <GoogleRedirect />}
// </Route>

//{!globalState.isAuth ? <Redirect to="/login" /> : <UpgradeSuccess />}

// <Route path="/login">
//   <Login />
// </Route>
